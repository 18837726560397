import { FirebaseStorage, IVanues } from "../lib/firebase/storage";
import { create } from "zustand";

interface UserStore {
    filters: [];
    setFilters: (filters: any) => void;
    vanues: IVanues[];
    filterVanues: (city: string, filters: any) => void;
    getInitialVanues: (city: string) => void;
    isLoading: boolean;
}
export const useVanuesStore = create<UserStore>((set) => ({
    filters: [],
    setFilters: (filters) => set({ filters }),
    vanues: [],
    isLoading: false,
    filterVanues: async (city: string, filters: any) => {
        set({ isLoading: true });
        set({ filters });
        const vanues = await FirebaseStorage.getVanues(city, filters);
        set({ vanues, isLoading: false });
    },
    getInitialVanues: async (city: string) => {
        set({ isLoading: true });
        const response = await FirebaseStorage.getVanues(city, []);
        set({ vanues: response, isLoading: false });
    },
}));
