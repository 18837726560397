//@ts-nocheck
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useAuth } from "../providers/Authentication";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { FirebaseStorage } from "../lib/firebase/storage";
import { useFormik } from "formik";
import TextInput from "../components/TextInput/TextInput";
import slugify from "slugify";
import { CitySelect, CountrySelect, StateSelect, GetCountries } from "react-country-state-city";
import { FileUploader } from "react-drag-drop-files";
import "react-country-state-city/dist/react-country-state-city.css";
import ProfileHeader from "./Profile/header";
import CountryNumber from "../components/CountryNumber";
import { startCase } from "lodash";
import { AiOutlineCloseCircle } from "react-icons/ai";
const validationSchema = Yup.object().shape({
    displayName: Yup.string().required("Display Name is required"),
    phoneNumber: Yup.string(),
    email: Yup.string().email("Invalid email"),
    website: Yup.string().url("Invalid website URL"),
    mondayOpeningHours: Yup.string(),
    mondayClosingHours: Yup.string(),
    tuesdayOpeningHours: Yup.string(),
    tuesdayClosingHours: Yup.string(),
    wednesdayOpeningHours: Yup.string(),
    wednesdayClosingHours: Yup.string(),
    thursdayOpeningHours: Yup.string(),
    thursdayClosingHours: Yup.string(),
    fridayOpeningHours: Yup.string(),
    fridayClosingHours: Yup.string(),
    saturdayOpeningHours: Yup.string(),
    saturdayClosingHours: Yup.string(),
    sundayOpeningHours: Yup.string(),
    sundayClosingHours: Yup.string(),
    instagram: Yup.string().url("Invalid Instagram URL"),
    facebook: Yup.string().url("Invalid Facebook URL"),
    pinterest: Yup.string().url("Invalid Pinterest URL"),
    telegram: Yup.string().url("Invalid Telegram URL"),
    twitter: Yup.string().url("Invalid Twitter URL"),
    discord: Yup.string().url("Invalid Discord URL"),
    youtube: Yup.string().url("Invalid YouTube URL"),
    tiktok: Yup.string().url("Invalid TikTok URL"),
    linkedIn: Yup.string().url("Invalid LinkedIn URL"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    address: Yup.string(),
    description: Yup.string().required("Description is required"),
    capacity: Yup.number().positive("Capacity must be a positive number").default(0),
    pool: Yup.boolean(),
    outdoor: Yup.boolean(),
    rooftop: Yup.boolean(),
    smokingArea: Yup.boolean(),
    food: Yup.boolean(),
    fullRestuarant: Yup.boolean(),
    fullBar: Yup.boolean(),
    danceFloor: Yup.boolean(),
    liveMusic: Yup.boolean(),
    liveDJ: Yup.boolean(),
    nightClub: Yup.boolean(),
    dayClub: Yup.boolean(),
    concertVanue: Yup.boolean(),
    restuarant: Yup.boolean(),
    price: Yup.string(),
    limitation: Yup.string(),
    dressCode: Yup.string(),
});

const fileTypes = ["JPG", "PNG", "JPEG"];
export default function EditVeneu() {
    const { slug } = useParams();
    const [country, setCountry] = useState();
    const [images, setImages] = useState<string[] | []>([]);
    const [loading, setLoading] = useState(false);
    const [countryid, setCountryid] = useState(0);
    const [stateid, setstateid] = useState(0);
    const [file, setFile] = useState(null);

    const handleFileChange = (file: any) => {
        setFile(file);
    };

    const { user } = useAuth();
    const nav = useNavigate();
    const venues = useQuery({
        queryKey: "vanuesQuery",
        queryFn: () => FirebaseStorage.getVanueById(slug as string),
        enabled: !!slug,
    });

    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );

    useEffect(() => {
        if (!data?.roles?.includes("SiteAdmin")) {
            nav("/profile");
        }
    }, [data, nav]);

    let initialValues = {
        displayName: "",
        phoneNumber: "",
        email: "",
        website: "",
        mondayOpeningHours: "",
        mondayClosingHours: "",
        tuesdayOpeningHours: "",
        tuesdayClosingHours: "",
        wednesdayOpeningHours: "",
        wednesdayClosingHours: "",
        thursdayOpeningHours: "",
        thursdayClosingHours: "",
        fridayOpeningHours: "",
        fridayClosingHours: "",
        saturdayOpeningHours: "",
        saturdayClosingHours: "",
        sundayOpeningHours: "",
        sundayClosingHours: "",
        instagram: "",
        facebook: "",
        pinterest: "",
        telegram: "",
        twitter: "",
        discord: "",
        youtube: "",
        tiktok: "",
        linkedIn: "",
        country: "",
        state: "",
        city: "",
        address: "",
        description: "",
        capacity: "",
        pool: false,
        outdoor: false,
        rooftop: false,
        smokingArea: false,
        food: false,
        fullRestuarant: false,
        fullBar: false,
        danceFloor: false,
        liveMusic: false,
        liveDJ: false,
        nightClub: false,
        dayClub: false,
        concertVanue: false,
        price: "",
        limitation: "",
        dressCode: "",
    };

    const { setValues, handleChange, values, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values, formikHelper) => {
            let params = {
                ...values,
                files: file,
                images: images,
            };

            setLoading(true);
            try {
                await FirebaseStorage.updateVenue(slug, params);
                setLoading(false);
            } catch (error) {
                console.log({ error });
                setLoading(false);
            }
        },
    });

    useEffect(() => {
        if (venues.data) {
            const vanue = venues.data;
            const setAddressValues = () => {
                const vanue = venues.data;
                GetCountries().then((resp) => {
                    resp.map((ct) => {
                        if (ct.name === vanue.country) {
                            setCountry(ct);
                        }
                        return ct;
                    });
                });
            };
            setAddressValues();
            setImages(vanue.images);
            setValues({
                ...values,
                displayName: vanue.displayName || "",
                description: vanue.description || "",
                address: vanue.address || "",
                capacity: vanue.capacity || "",
                email: vanue.email || "",
                website: vanue.website || "",
                discord: vanue.discord || "",
                facebook: vanue.facebook || "",
                limitation: vanue.limitation || "",
                instagram: vanue.instagram || "",
                pinterest: vanue.pinterest || "",
                telegram: vanue.telegram || "",
                twitter: vanue.twitter || "",
                youtube: vanue.youtube || "",
                tiktok: vanue.tiktok || "",
                linkedIn: vanue.linkedIn || "",
                mondayOpeningHours: vanue.mondayOpeningHours || "",
                mondayClosingHours: vanue.mondayClosingHours || "",
                tuesdayOpeningHours: vanue.tuesdayOpeningHours || "",
                tuesdayClosingHours: vanue.tuesdayClosingHours || "",
                wednesdayOpeningHours: vanue.wednesdayOpeningHours || "",
                wednesdayClosingHours: vanue.wednesdayClosingHours || "",
                thursdayOpeningHours: vanue.thursdayOpeningHours || "",
                thursdayClosingHours: vanue.thursdayClosingHours || "",
                fridayOpeningHours: vanue.fridayOpeningHours || "",
                fridayClosingHours: vanue.fridayClosingHours || "",
                saturdayOpeningHours: vanue.saturdayOpeningHours || "",
                saturdayClosingHours: vanue.saturdayClosingHours || "",
                sundayOpeningHours: vanue.sundayOpeningHours || "",
                sundayClosingHours: vanue.sundayClosingHours || "",
                concertVanue: vanue.concertVenue || "",
                danceFloor: vanue.danceFloor || "",
                smokingArea: vanue.smokingArea || "",
                state: vanue.state || "",
                country: vanue.country || "",
                city: vanue.city || "",
                liveDJ: vanue.liveDJ || "",
                dayClub: vanue.dayClub || "",
                rooftop: vanue.rooftop || "",
                dressCode: vanue.dressCode || "",
                food: vanue.food || "",
                fullBar: vanue.fullBar || "",
                fullRestuarant: vanue.fullRestaurant || "",
                liveMusic: vanue.liveMusic || "",
                nightClub: vanue.nightClub || "",
                outdoor: vanue.outdoor || "",
                phoneNumber: vanue.phoneNumber || "",
                pool: vanue.pool || "",
                price: vanue.price || "",
            });
        }
    }, [venues.data, values, setValues]);
    if (venues.isLoading) {
        return <div>loading</div>;
    }
    return (
        <div className="bg-black">
            <div className="w-full lg:max-w-screen-xl lg:mx-auto pb-20">
                <ProfileHeader />
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col lg:flex-row gap-5 pt-10 mx-5 lg:ml-5">
                    <div className="lg:w-6/12">
                        <div>
                            <label
                                htmlFor="displayName"
                                className="mt-2 text-white text-lg block mb-2">
                                Display Name*
                            </label>
                            <TextInput
                                id="displayName"
                                type="text"
                                placeholder="Ex Eigh Night"
                                className={`border ${touched.displayName && errors.displayName ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                    touched.displayName && errors.displayName ? "active:border-red-400" : "active:border-white/40"
                                } ${touched.displayName && errors.displayName ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                value={values.displayName}
                                name="displayName"
                                onChange={handleChange}
                            />
                            {touched.displayName && errors.displayName && <p className="text-red-400 text-sm mt-1">{errors.displayName}</p>}
                        </div>
                        <div>
                            <label
                                htmlFor="firstName"
                                className="mt-2 text-white text-lg block mb-2">
                                Email
                            </label>
                            <TextInput
                                type="text"
                                placeholder="Ex Example@.com"
                                className={`border ${touched.email && errors.email ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                    touched.email && errors.email ? "active:border-red-400" : "active:border-white/40"
                                } ${touched.email && errors.email ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                value={values.email}
                                name="email"
                                onChange={handleChange}
                            />
                            {touched.email && errors.email && <p className="text-red-400 text-sm mt-1">{errors.email}</p>}
                        </div>
                        <div className="flex gap-4">
                            <div className="mt-2 w-full">
                                <label
                                    htmlFor="country"
                                    className="text-white text-lg block mb-2">
                                    Phone
                                </label>
                                <div
                                    className={`border p-[2px] ${
                                        touched.phoneNumber && errors.phoneNumber ? "border-red-400" : "border-white/30"
                                    } rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none`}>
                                    {/* <CountryNumber /> */}
                                    <CountryNumber
                                        handleChange={(value) => setValues({ ...values, phoneNumber: value })}
                                        phoneNumber={values.phoneNumber}
                                    />
                                </div>
                                {touched.phoneNumber && errors.phoneNumber && <p className="text-red-400 text-sm mt-1">{errors.phoneNumber}</p>}
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white text-lg block mb-2">
                                    Web site*
                                </label>
                                <TextInput
                                    type="text"
                                    placeholder="Ex www.Example@.com"
                                    className={`border ${errors.website ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        errors.website ? "active:border-red-400" : "active:border-white/40"
                                    } ${errors.website ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.website}
                                    name="website"
                                    onChange={handleChange}
                                />
                                {errors.website && <p className="text-red-400 text-sm mt-1">{errors.website}</p>}
                            </div>
                        </div>
                        <div className="flex gap-4">
                            <div className="mt-2 w-full">
                                <label
                                    htmlFor="country"
                                    className="text-white text-lg block mb-2">
                                    Country
                                </label>
                                <CountrySelect
                                    defaultValue={country}
                                    onChange={(e) => {
                                        setCountryid(e.id);
                                        setValues({ ...values, country: e.name });
                                    }}
                                    inputClassName="w-full border rounded p-2 bg-black text-white"
                                    placeHolder="Select Country"
                                />
                                {touched.country && errors.country && <p className="text-red-400 text-sm mt-1">{errors.country}</p>}
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white text-lg block mb-2">
                                    State
                                </label>
                                <StateSelect
                                    countryid={countryid}
                                    onChange={(e) => {
                                        setstateid(e.id);
                                        setValues({ ...values, state: e.name });
                                    }}
                                    inputClassName="w-full border rounded p-2 bg-black text-white"
                                    placeHolder={values.state}
                                />
                                {touched.state && errors.state && <p className="text-red-400 text-sm mt-1">{errors.state}</p>}
                            </div>
                            <div className="w-full">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white text-lg block mb-2">
                                    City
                                </label>
                                <CitySelect
                                    stateid={stateid}
                                    countryid={countryid}
                                    onChange={(e) => {
                                        let city = slugify(e.name, {
                                            lower: true,
                                        });
                                        setValues({ ...values, city: city });
                                    }}
                                    inputClassName="w-full border-none rounded p-2 bg-black text-white"
                                    placeHolder={startCase(values.city)}
                                />
                                {touched.city && errors.city && <p className="text-red-400 text-sm mt-1">{errors.city}</p>}
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="firstName"
                                className="mt-2 text-white text-lg block mb-2">
                                Address*
                            </label>
                            <TextInput
                                type="text"
                                placeholder="Ex St:"
                                className={`border ${touched.address && errors.address ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                    touched.address && errors.address ? "active:border-red-400" : "active:border-white/40"
                                } ${touched.address && errors.address ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                value={values.address}
                                name="address"
                                onChange={handleChange}
                            />
                            {touched.address && errors.address && <p className="text-red-400 text-sm mt-1">{errors.address}</p>}
                        </div>
                        <div className="mt-3 ">
                            <label
                                htmlFor=""
                                className="text-white text-lg">
                                Venue Type
                            </label>
                            <div className="grid grid-flow-row grid-cols-4">
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.nightClub}
                                        name="nightClub"
                                        checked={values.nightClub}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Night Club</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.dayClub}
                                        name="dayClub"
                                        checked={values.dayClub}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Day Club</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.concertVanue}
                                        name="concertVanue"
                                        onChange={handleChange}
                                        checked={values.concertVanue}
                                    />
                                    <span className="ml-2">Concert</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.fullRestuarant}
                                        name="fullRestuarant"
                                        checked={values.fullRestuarant}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2"> Restuarant</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.fullBar}
                                        name="fullBar"
                                        checked={values.fullBar}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Full Bar</span>
                                </div>
                            </div>
                            <label
                                htmlFor=""
                                className="text-white text-lg mt-4 flex">
                                Features
                            </label>
                            <div className="grid grid-flow-row grid-cols-4">
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.pool}
                                        name="pool"
                                        checked={values.pool}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Pool</span>
                                </div>

                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.outdoor}
                                        name="outdoor"
                                        checked={values.outdoor}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Outdoor Patio</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.rooftop}
                                        name="rooftop"
                                        checked={values.rooftop}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Rooftop</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.smokingArea}
                                        name="smokingArea"
                                        onChange={handleChange}
                                        checked={values.smokingArea}
                                    />
                                    <span className="ml-2">Smoking area</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.food}
                                        name="food"
                                        onChange={handleChange}
                                        checked={values.food}
                                    />
                                    <span className="ml-2">Food</span>
                                </div>

                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.danceFloor}
                                        name="danceFloor"
                                        checked={values.danceFloor}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Dance floor</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.liveMusic}
                                        name="liveMusic"
                                        checked={values.liveMusic}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Live Music</span>
                                </div>
                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.liveDJ}
                                        name="liveDJ"
                                        checked={values.liveDJ}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Live DJ</span>
                                </div>

                                <div className="text-white">
                                    <input
                                        type="checkbox"
                                        value={values.fullBar}
                                        name="fullBar"
                                        checked={values.fullBar}
                                        onChange={handleChange}
                                    />
                                    <span className="ml-2">Bar</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex mt-2 gap-4 flex-wrap">
                            <p className="w-full text-white font-bold mt-2">Timing</p>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Monday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, mondayOpeningHours: e.target.value });
                                    }}
                                    value={values.mondayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, mondayClosingHours: e.target.value });
                                    }}
                                    value={values.mondayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Tuesday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, tuesdayOpeningHours: e.target.value });
                                    }}
                                    value={values.tuesdayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, tuesdayClosingHours: e.target.value });
                                    }}
                                    value={values.tuesdayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Wednesday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, wednesdayOpeningHours: e.target.value });
                                    }}
                                    value={values.wednesdayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, wednesdayClosingHours: e.target.value });
                                    }}
                                    value={values.wednesdayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Thursday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, thursdayOpeningHours: e.target.value });
                                    }}
                                    value={values.thursdayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, thursdayClosingHours: e.target.value });
                                    }}
                                    value={values.thursdayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Friday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, fridayOpeningHours: e.target.value });
                                    }}
                                    value={values.fridayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, fridayClosingHours: e.target.value });
                                    }}
                                    value={values.fridayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Saturday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, saturdayOpeningHours: e.target.value });
                                    }}
                                    value={values.saturdayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, saturdayClosingHours: e.target.value });
                                    }}
                                    value={values.saturdayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                            <div className="w-3/12">
                                <label
                                    htmlFor="website"
                                    className="mt-2 text-white block mb-2">
                                    Sunday Hours
                                </label>
                                <p className="text-white">Open time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, sundayOpeningHours: e.target.value });
                                    }}
                                    value={values.sundayOpeningHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                                <p className="text-white">Close time</p>
                                <input
                                    type="time"
                                    onChange={(e) => {
                                        setValues({ ...values, sundayClosingHours: e.target.value });
                                    }}
                                    value={values.sundayClosingHours}
                                    className="w-full bg-black text-white border border-white/20"
                                />
                            </div>
                        </div>
                    </div>
                    {/* /// right side /// */}
                    <div className="w-full lg:w-6/12 text-white flex flex-col gap-5 lg:gap-[15px]">
                        {/* description textarea */}
                        <div className="">
                            <label
                                htmlFor="description"
                                className="mt-2 text-white text-lg block mb-2">
                                Description
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                cols={30}
                                rows={5}
                                className={`border ${touched.description && errors.description ? "border-red-400" : "border-white/30"} p-3 rounded w-full bg-transparent ${
                                    touched.description && errors.description ? "active:border-red-400" : "active:border-white/40"
                                } ${touched.description && errors.description ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                value={values.description}
                                onChange={handleChange}
                            />
                            {touched.description && errors.description && <p className="text-red-400 text-sm mt-1">{errors.description}</p>}
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 border border-white/30 text-center text-2xl">
                            <FileUploader
                                handleChange={handleFileChange}
                                name="file"
                                types={fileTypes}
                                multiple={true}
                            />
                        </div>{" "}
                        <div className="flex gap-2">
                            {images?.map((image) => {
                                return (
                                    <div
                                        key={image}
                                        className="flex relative">
                                        <img
                                            src={image}
                                            alt="item"
                                            className="w-40 h-40"
                                        />
                                        <AiOutlineCloseCircle
                                            className="absolute top-0 right-0 text-white text-2xl cursor-pointer"
                                            onClick={() => {
                                                setImages(images.filter((img) => img !== image));
                                            }}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="email"
                                className="text-white text-lg">
                                Limitations
                            </label>
                            <input
                                type="text"
                                className={`text-white border ${touched.limitation && errors.limitation ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                    touched.limitation && errors.limitation ? "active:border-red-400" : "active:border-white/40"
                                } ${touched.limitation && errors.limitation ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                value={values.limitation}
                                name="limitation"
                                onChange={handleChange}
                            />
                            {touched.limitation && errors.limitation && <p className="text-red-400 text-sm mt-1">{errors.limitation}</p>}
                        </div>
                        <div className="flex gap-5 mb-2">
                            <div className="w-full">
                                <label
                                    htmlFor="Twiiter"
                                    className="text-white text-lg block mb-2">
                                    Capacity
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.capacity && errors.capacity ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.capacity && errors.capacity ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.capacity && errors.capacity ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.capacity}
                                    name="capacity"
                                    onChange={handleChange}
                                />
                                {touched.capacity && errors.capacity && <p className="text-red-400 text-sm mt-1">{errors.capacity}</p>}
                            </div>
                            <div className=" w-full">
                                <label
                                    htmlFor="Telegram"
                                    className="text-white text-lg block mb-2">
                                    Dress code
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.dressCode && errors.dressCode ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.dressCode && errors.dressCode ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.dressCode && errors.dressCode ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.dressCode}
                                    name="dressCode"
                                    onChange={handleChange}
                                />
                                {touched.dressCode && errors.dressCode && <p className="text-red-400 text-sm mt-1">{errors.dressCode}</p>}
                            </div>
                        </div>
                        <div className="flex gap-5 mt-5">
                            <div className="w-full">
                                <label
                                    htmlFor="Twiiter"
                                    className="text-white text-lg block mb-2">
                                    Twiiter
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.twitter && errors.twitter ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.twitter && errors.twitter ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.twitter && errors.twitter ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.twitter}
                                    name="twitter"
                                    onChange={handleChange}
                                />
                                {touched.twitter && errors.twitter && <p className="text-red-400 text-sm mt-1">{errors.twitter}</p>}
                            </div>
                            <div className=" w-full">
                                <label
                                    htmlFor="Telegram"
                                    className="text-white text-lg block mb-2">
                                    Discord
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.discord && errors.discord ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.discord && errors.discord ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.discord && errors.discord ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.discord}
                                    name="discord"
                                    onChange={handleChange}
                                />
                                {touched.discord && errors.discord && <p className="text-red-400 text-sm mt-1">{errors.discord}</p>}
                            </div>
                        </div>
                        <div className="flex gap-5 mb-2 -mt-1">
                            <div className="w-full">
                                <label
                                    htmlFor="Twiiter"
                                    className="text-white text-lg block mb-2">
                                    Youtube
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.youtube && errors.youtube ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.youtube && errors.youtube ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.youtube && errors.youtube ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.youtube}
                                    name="youtube"
                                    onChange={handleChange}
                                />
                                {touched.youtube && errors.youtube && <p className="text-red-400 text-sm mt-1">{errors.youtube}</p>}
                            </div>
                            <div className=" w-full">
                                <label
                                    htmlFor="Telegram"
                                    className="text-white text-lg block mb-2">
                                    Tiktok
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.tiktok && errors.tiktok ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.tiktok && errors.tiktok ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.tiktok && errors.tiktok ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.tiktok}
                                    name="tiktok"
                                    onChange={handleChange}
                                />
                                {touched.tiktok && errors.tiktok && <p className="text-red-400 text-sm mt-1">{errors.tiktok}</p>}
                            </div>
                        </div>
                        <div className="flex gap-5 mb-2">
                            <div className="w-full">
                                <label
                                    htmlFor="Pinterest"
                                    className="text-white text-lg block mb-2">
                                    Pinterest
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.pinterest && errors.pinterest ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.pinterest && errors.pinterest ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.pinterest && errors.pinterest ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.pinterest}
                                    name="pinterest"
                                    onChange={handleChange}
                                />
                                {touched.pinterest && errors.pinterest && <p className="text-red-400 text-sm mt-1">{errors.pinterest}</p>}
                            </div>
                            <div className=" w-full">
                                <label
                                    htmlFor="Telegram"
                                    className="text-white text-lg block mb-2">
                                    Telegram
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.telegram && errors.telegram ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.telegram && errors.telegram ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.telegram && errors.telegram ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.telegram}
                                    name="telegram"
                                    onChange={handleChange}
                                />
                                {touched.telegram && errors.telegram && <p className="text-red-400 text-sm mt-1">{errors.telegram}</p>}
                            </div>
                        </div>
                        <div className="flex gap-5 mb-2">
                            <div className="mt-3 w-full">
                                <label
                                    htmlFor="DOB"
                                    className="text-white text-lg block mb-2">
                                    Instagram
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.instagram && errors.instagram ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.instagram && errors.instagram ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.instagram && errors.instagram ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.instagram}
                                    name="instagram"
                                    onChange={handleChange}
                                />
                                {touched.instagram && errors.instagram && <p className="text-red-400 text-sm mt-1">{errors.instagram}</p>}
                            </div>
                            <div className="mt-3 w-full">
                                <label
                                    htmlFor="Facebook"
                                    className="text-white text-lg block mb-2">
                                    Facebook
                                </label>
                                <input
                                    type="text"
                                    className={`text-white border ${touched.facebook && errors.facebook ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.facebook && errors.facebook ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.facebook && errors.facebook ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.facebook}
                                    name="facebook"
                                    onChange={handleChange}
                                />
                                {touched.facebook && errors.facebook && <p className="text-red-400 text-sm mt-1">{errors.facebook}</p>}
                            </div>
                        </div>
                        <div className="flex items-end justify-end my-5 mx-5">
                            <button
                                disabled={loading}
                                type="submit"
                                className="w-full md:w-auto px-10 py-5 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none transition-colors">
                                Update
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
