import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Profile from "./pages/Profile";
import AuthenticationProvider from "./providers/Authentication";
import { ProtectedRoute } from "./providers/ProtectedRoute";
import Navbar from "./components/Navbar/Navbar";
import Terms from "./components/Terms/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Resources from "./components/Resources/Resources";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Events from "./pages/Events";
import Venues from "./pages/Venues";
import VenuesDetails from "./pages/VenuesDetails";
import EventsDetail from "./pages/EventsDetails";
import Artists from "./pages/Artists";
import ArtistsDetails from "./pages/ArtistsDetails";
import ArtistForm from "./pages/ArtistForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VenueForm from "./pages/VenueForm";
import EventCreatingForm from "./pages/EventCreatingForm";
import RequestArtistForm from "./pages/RequestAritstForm";
import RequestVanueForm from "./pages/RequestVanueForm";
import Requests from "./pages/Requests";
import EditEvent from "./pages/EditEvent";
import { Helmet } from "react-helmet";
import { UserManagement } from "./pages/Profile/UserManagement";
import { EditArtist } from "./pages/EditArtist";
import EditVeneu from "./pages/EditVenue";

function App() {
    return (
        <AuthenticationProvider>
            <Helmet>
                <title>Nightlifer</title>
            </Helmet>
            <Navbar />
            <Routes>
                <Route
                    path="/"
                    element={<Home />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                />
                <Route
                    path="/terms"
                    element={<Terms />}
                />
                <Route
                    path="/privacy_policy"
                    element={<PrivacyPolicy />}
                />
                <Route
                    path="/contact-us"
                    element={<Resources />}
                />
                <Route
                    path="/reset_password"
                    element={<ResetPassword />}
                />
                <Route
                    path="/:id/events"
                    element={<Events />}
                />
                <Route
                    path="/:slug/events/:slug"
                    element={<EventsDetail />}
                />
                <Route
                    path="/artists"
                    element={<Artists />}
                />
                <Route
                    path="/artists/:slug"
                    element={<ArtistsDetails />}
                />
                <Route
                    path="/:slug/venues"
                    element={<Venues />}
                />
                <Route
                    path="/:slug/venues/:slug"
                    element={<VenuesDetails />}
                />
                <Route
                    path="/profile/artist"
                    element={
                        <ProtectedRoute>
                            <ArtistForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/artist-request"
                    element={
                        <ProtectedRoute>
                            <RequestArtistForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/vanue-request"
                    element={
                        <ProtectedRoute>
                            <RequestVanueForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/vanue"
                    element={
                        <ProtectedRoute>
                            <VenueForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/event"
                    element={
                        <ProtectedRoute>
                            <EventCreatingForm />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/edit-event/:slug"
                    element={
                        <ProtectedRoute>
                            <EditEvent />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/edit-artist/:slug"
                    element={
                        <ProtectedRoute>
                            <EditArtist />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/edit-venue/:slug"
                    element={
                        <ProtectedRoute>
                            <EditVeneu />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <ProtectedRoute>
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/admin/requests"
                    element={
                        <ProtectedRoute>
                            <Requests />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/profile/admin/users"
                    element={
                        <ProtectedRoute>
                            <UserManagement />
                        </ProtectedRoute>
                    }
                />
            </Routes>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                theme="dark"
            />
        </AuthenticationProvider>
    );
}

export default App;
