import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";

const links = [
    { href: "/terms", text: "Terms" },
    { href: "/privacy_policy", text: "Privacy Policy" },
    { href: "/contact-us", text: "Contact Us" },
];

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="hidden lg:flex items-center justify-between w-full pt-2 pb-1 bg-black border-t border-white/20 fixed bottom-0 z-50 px-5">
            <div className="flex items-center gap-5">
                <p className="text-white/50 text-sm">Copyright © {currentYear} Nightlife Systems</p>
                <div className="flex items-center gap-2">
                    {links.map((link, index) => (
                        <Link
                            key={index}
                            to={link.href}
                            className="flex items-center justify-center text-white gap-2 cursor-pointer">
                            <span className="-mt-[5px] text-white/50 text-sm">.</span>
                            <span className="text-white/60 text-sm hover:text-white">{link.text}</span>
                        </Link>
                    ))}
                </div>
            </div>
            <div className="flex items-center gap-1 text-white/70">
                <Link to="https://www.facebook.com/nightlifesystems">
                    <FaFacebook />
                </Link>
                <Link
                    to="https://www.instagram.com/nightlife.systems"
                    target="_blank">
                    <FaInstagram />
                </Link>
                {/* <Link
                    to="#"
                    aria-label="Twitter">
                    <AiOutlineTwitter />
                </Link> */}
            </div>
            {/* Pass onClose prop to ContactUsModal */}
            {/* {isContactModalOpen && <ContactUsModal onClose={closeContactModal} />} */}
        </div>
    );
};

export default Footer;
