import { initializeApp } from "firebase/app";
import { collection, doc, getDoc, setDoc, getFirestore, updateDoc } from "firebase/firestore/lite";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    signInWithCustomToken,
    getAuth,
    updateProfile,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    getRedirectResult,
} from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from "firebase/storage";
export const firebaseConfig = {
    apiKey: "AIzaSyAQ0SjgkLp5Vva17ycge4segk3HSZJbZJ4",
    authDomain: "nightlife-84013.firebaseapp.com",
    projectId: "nightlife-84013",
    storageBucket: "nightlife-84013.appspot.com",
    messagingSenderId: "526570578099",
    appId: "1:526570578099:web:b61c8e4e2a6eab777550c6",
    measurementId: "G-VMNRG1V6T1",
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage();
export const auth = getAuth();
export const provider = new GoogleAuthProvider();
export const providerFacebook = new FacebookAuthProvider();

export {
    signInWithEmailAndPassword,
    collection,
    doc,
    getDoc,
    setDoc,
    createUserWithEmailAndPassword,
    signInWithCustomToken,
    updateProfile,
    signInWithPopup,
    getRedirectResult,
    GoogleAuthProvider,
    FacebookAuthProvider,
    updateDoc,
    ref,
    uploadBytes,
    getDownloadURL,
    uploadBytesResumable,
};
export default app;
