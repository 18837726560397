import { useQuery } from "react-query";
import { DetailsImage, EntryOption } from "../components/comevent/components";
import { FirebaseStorage, IEvents } from "../lib/firebase/storage";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import EventDetailsSkeleton from "../components/EventDetailsSkeleton/EventDetailsSkeleton";

export default function EventsDetail() {
    const { slug } = useParams();

    const { data, isLoading, refetch } = useQuery({
        queryFn: () => FirebaseStorage.getEventById(slug as string),
        queryKey: "eventdetails",
    });

    useEffect(() => {
        refetch();
    }, [slug, refetch]);

    return (
        <div className="w-full h-full bg-[#0A0D16]">
            <div className="container mx-auto flex flex-col lg:gap-5 gap-0 lg:pb-0 pb-20">
                {isLoading ? (
                    <EventDetailsSkeleton />
                ) : (
                    <>
                        <DetailsImage event={data as IEvents} />
                        <EntryOption event={data as IEvents} />
                    </>
                )}
            </div>
        </div>
    );
}
