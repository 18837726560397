import { useQuery } from "react-query";
import { FirebaseStorage } from "../lib/firebase/storage";
import { useAuth } from "../providers/Authentication";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { truncate } from "lodash";

export default function Requests() {
    const { user } = useAuth();
    const nav = useNavigate();

    const { data, isLoading, refetch } = useQuery({
        queryKey: "requests",
        queryFn: FirebaseStorage.getVanueRequests,
    });
    const ArtistRequests = useQuery({
        queryKey: "artists",
        queryFn: FirebaseStorage.getArtistRequests,
    });

    const handleArtistRequest = async (data: any) => {
        await FirebaseStorage.updateArtistRequest(data).then((res) => {
            ArtistRequests.refetch();
        });
    };
    const handleVanueRequest = async (data: any) => {
        await FirebaseStorage.updateVanueRequest(data).then((res) => {
            refetch();
        });
    };

    useEffect(() => {
        if (user.uid !== "55Dp5TcVBdf911NLJT07BvnBoHD2") {
            nav("/profile");
        }
    }, [user, nav]);

    return (
        <div className="bg-black text-white px-10 min-h-screen">
            <h1 className="pt-10 text-xl">Vanues Request</h1>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th
                                scope="col"
                                className=" py-3">
                                name
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Website
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Facebook
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Instagram
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Google Map
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading &&
                            data?.map((item) => (
                                <tr
                                    key={item.id}
                                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className=" py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.name}
                                    </th>
                                    <td className=" py-4">
                                        <Link
                                            to={item.website}
                                            target="_blank">
                                            {item.website}
                                        </Link>
                                    </td>
                                    <td className=" py-4">
                                        <Link
                                            to={item.facebook}
                                            target="_blank">
                                            {item.facebook}
                                        </Link>
                                    </td>
                                    <td className=" py-4">
                                        <Link
                                            to={item.instagram}
                                            target="_blank">
                                            {item.instagram}
                                        </Link>
                                    </td>
                                    <td className=" py-4">
                                        <Link
                                            to={item.googleMap}
                                            target="_blank">
                                            {truncate(item.googleMap, {
                                                length: 40,
                                                omission: "...",
                                            })}
                                        </Link>
                                    </td>
                                    <td className=" py-4 flex gap-3">
                                        <button
                                            className="bg-primary text-white p-2 rounded-md"
                                            onClick={() => {
                                                handleVanueRequest({ ...item, isVanue: true });
                                            }}>
                                            Accept
                                        </button>
                                        <button
                                            className="bg-red-600 text-white p-2 rounded-md"
                                            onClick={() => {
                                                handleVanueRequest({ ...item, isVanue: false });
                                            }}>
                                            Reject
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            <h1 className="mt-5 text-xl">Artists Request</h1>

            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th
                                scope="col"
                                className=" py-3">
                                name
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Facebook
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Instagram
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Wikipedia
                            </th>
                            <th
                                scope="col"
                                className=" py-3">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!ArtistRequests.isLoading &&
                            ArtistRequests.data?.map((item) => (
                                <tr className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                    <th
                                        scope="row"
                                        className=" py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                        {item.name}
                                    </th>
                                    <td className=" py-4">
                                        <Link to={item.facebook}>{item?.facebook} </Link>
                                    </td>
                                    <td className=" py-4">
                                        <Link to={item.instagram}>{item?.instagram}</Link>
                                    </td>
                                    <td className=" py-4">
                                        <Link to={item.wikipedia}>
                                            {truncate(item?.wikipedia, {
                                                length: 50,
                                                omission: "...",
                                            })}
                                        </Link>
                                    </td>
                                    <td className=" py-4 flex gap-3">
                                        <button
                                            className="bg-primary text-white p-2 rounded-md"
                                            onClick={() => {
                                                handleArtistRequest({ ...item, isArtist: true });
                                            }}>
                                            Accept
                                        </button>
                                        <button
                                            className="bg-red-600 text-white p-2 rounded-md"
                                            onClick={() => {
                                                handleArtistRequest({ ...item, isArtist: false });
                                            }}>
                                            Reject
                                        </button>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
