import { Calendar, MobileCard } from "../../comevent/components";
import { Link, useLocation } from "react-router-dom";
import SkeletonEvent from "../../SkeletonEvent";
import { IEvents } from "../../../lib/firebase/storage";
import moment from "moment";
import SelectInput from "../../SelectInput";

type Props = {
    events: IEvents[];
    loading: boolean;
};

export default function EventListCard({ events, loading }: Props) {
    const location = useLocation();

    return (
        <div className="w-full min-h-screen">
            <div className="w-full flex flex-col gap-6 lg:p-5 xl:p-5 2xl:p-14 p-5 bg-[#0A0D16]">
                <div className="w-full 2xl:w-11/12 mx-auto xl:pl-5 lg:pl-10 px-0">
                    <div className="w-full flex flex-col">
                        <p className="text-3xl font-light text-center lg:text-start mb-2">
                            <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Events</span>
                        </p>
                        <div className="lg:hidden flex items-center gap-2 ">
                            <div className="text-white mt-6 w-6/12">
                                <SelectInput screen="HOME" />
                            </div>
                            <Calendar />
                        </div>
                    </div>
                    {loading && <SkeletonEvent />}
                    {!loading && events?.length === 0 && <div className="text-white">Event Not found</div>}
                    {Array(events) &&
                        events.map((event, index) => (
                            <div
                                key={index}
                                className={`group flex-col gap-1 flex  gap-4"}`}>
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-bold text-[#8D919B]">{moment(event.timestamp).format("dddd, MMMM Do YYYY")}</p>
                                    <div className="hidden xl:block">
                                        <div
                                            style={{
                                                backgroundImage: `url(${event.images[0]})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                            className="lg:h-[215px] h-auto relative overflow-hidden rounded-lg transition shadow-eventsDetailPageVip">
                                            <Link
                                                to={`${location.pathname}/${event.id}`}
                                                className="flex items-center justify-between">
                                                <div className="flex flex-col gap-6">
                                                    <div className="flex gap-3 ">
                                                        <div className="">
                                                            <img
                                                                src={event.artists[0]?.photoURL}
                                                                alt="event"
                                                                className="relative object-cover w-[150px] 2xl:w-[150px] h-[150px]  2xl:h-[150px]"
                                                            />
                                                        </div>
                                                        <div className="flex flex-col gap-2 text-white max-w-[283px]">
                                                            <p className="text-lg font-bold">{event.displayName}</p>
                                                            <p className="text-sm font-light">{event.vanueData?.displayName}</p>
                                                        </div>
                                                    </div>
                                                    {/* /// add button */}
                                                    <div className="pl-2 flex gap-1 flex-wrap lg:flex-nowrap items-center min-w-[14.1rem]">
                                                        <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                                            Guest List
                                                        </button>
                                                        <button
                                                            className={`$ w-[56.38px] bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center`}>
                                                            <span>Tickets</span>
                                                        </button>
                                                        <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                                            <span>VIP Tables</span>
                                                        </button>
                                                        <p className="text-xl font-medium lg:font-bold text-[#ffffff]">{moment(event.date).format("MMM DD YYYY hh:mm a")}</p>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="xl:hidden">
                                        <MobileCard
                                            event={event}
                                            index={index}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
}
