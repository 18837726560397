import BackgroundImg from "../assets/bgImg.jpeg";
import { FaApple, FaFacebook, FaGoogle, FaInstagram } from "react-icons/fa";
import { AuthenticationService } from "../lib/firebase/auth";
import { Suspense, useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useAuth } from "../providers/Authentication";

export default function LoginComponent() {
    return (
        <div className="relative">
            <Suspense fallback={<div>Loading</div>}>
                <LoginCom />
            </Suspense>
        </div>
    );
}
const LoginCom = () => {
    const { user, setUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const code = searchParams.get("code");
    const username = searchParams.get("username") as string;

    const handleFacbookLogin = async () => {
        await AuthenticationService.signInWithFacebook(navigate, setUser);
    };
    const handleGoogleLogin = async () => {
        await AuthenticationService.signInWithGoogle(navigate, setUser);
    };

    const handleInstagram = async () => {
        if (loading) return;
        try {
            setLoading(true);
            const response = await fetch(`https://app-ztazf6bpbq-uc.a.run.app/api/redirect`, {
                method: "GET",
            });
            const data = await response.json();
            setLoading(false);
            window.open(data, "_blank");
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    useEffect(() => {
        const login = async (code: string) => {
            try {
                await AuthenticationService.signInWithToken(code, username, navigate, setUser);
            } catch (error) {
                console.log({ error });
            }
        };
        if (code) {
            login(code);
        }
    }, [code, username, navigate, setUser]);

    useEffect(() => {
        if (user) {
            navigate("/profile");
        }
    }, [user, navigate]);

    if (user) {
        return <div>Redirecting</div>;
    }

    return (
        <div className="relative">
            <div
                style={{ backgroundImage: `url(${BackgroundImg})` }}
                className="bg-cover bg-center bg-no-repeat brightness-50 z-0 h-screen"
            />
            <div className="absolute top-[20%] md:top-[20%] left-0 right-0 text-white flex flex-col items-center justify-center">
                <p className="text-4xl font-normal md:text-5xl lg:text-6xl uppercase">Nightlife</p>
                <h3 className="text-xl font-thin mt-3 md:mt-5 lg:mt-7">Money</h3>
                <h3 className="text-xl font-thin">
                    Party <del className="opacity-80 font-normal">Harder</del> <span className="italic">Smart</span>
                </h3>
                <div className="mt-10 w-full px-5 md:w-auto md:px-0">
                    <div className="flex flex-col gap-2 justify-center">
                        <button
                            type="button"
                            onClick={handleInstagram}
                            className="p-6 relative flex items-center justify-center gap-1 bg-[#2c2e3ec6] text-white font-medium w-full h-[50px] rounded-md group overflow-hidden">
                            <span className="container mx-auto absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#ffffff1a]"></span>
                            <span className="z-50">
                                <FaInstagram className="text-[#3885F4] w-5 h-5 -mt-1" />
                            </span>
                            <span className="z-0">{loading ? "Loading..." : "Continue with Instagram"}</span>
                        </button>
                        <button
                            type="button"
                            onClick={handleGoogleLogin}
                            className="relative flex items-center justify-center gap-1 bg-[#2c2e3ec6] text-white font-medium w-full h-[50px] rounded-md group overflow-hidden">
                            <span className="container mx-auto absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#ffffff1a]"></span>
                            <span className="z-50">
                                <FaGoogle className="text-[#3885F4] w-5 h-5 -mt-1" />
                            </span>
                            <span className="z-0">Continue with Google</span>
                        </button>

                        <button
                            type="button"
                            className="relative flex items-center justify-center gap-1 bg-[#2c2e3ec6] text-white font-medium w-full h-[50px] rounded-md group overflow-hidden">
                            <span className="container mx-auto absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#ffffff1a]"></span>
                            <span className="z-50">
                                <FaApple className="w-5 h-5 -mt-1" />
                            </span>
                            <span className="z-0">Continue with Apple</span>
                        </button>
                        <button
                            type="button"
                            onClick={handleFacbookLogin}
                            className="relative flex items-center justify-center gap-1 bg-[#2c2e3ec6] text-white font-medium w-full h-[50px] rounded-md group overflow-hidden">
                            <span className="container mx-auto absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#ffffff1a]"></span>
                            <span className="z-50">
                                <FaFacebook className="text-[#3885F4] w-5 h-5 -mt-1" />
                            </span>
                            <span className="z-0">Continue with Facebook</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
