import { Link, useLocation } from "react-router-dom";
import { IEvents } from "../../../lib/firebase/storage";
import { IoIosArrowForward } from "react-icons/io";

export default function MobileCard({ event, index }: { event: IEvents; index: number }) {
    const { pathname } = useLocation();
    return (
        <Link
            to={`${pathname}/${event.id}`}
            className="w-full"
            key={index}>
            <div
                style={{ backgroundImage: `url(${event.images[0]})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}
                className="xl:h-[150px] h-auto relative overflow-hidden rounded-lg transition shadow-customClass">
                <div className="flex w-full mx-auto relative items-center gap-3">
                    <div className="absolute">
                        <img
                            src={event.artists[0]?.photoURL}
                            alt="event"
                            className="rounded-l-lg object-fit w-[110px] lg:w-[130px] xl:w-[160px]
                        h-[200px]  lg:h-[150px] xl:h-[150px]"
                        />
                    </div>
                    <div className="flex flex-col xl:pl-[167px] lg:pl-[130px] pl-[111px]">
                        <div className="flex flex-col gap-2 text-white p-2  w-full">
                            <p className="lg:text-base text-sm font-normal">{event.displayName}</p>
                            <p className="text-xs font-light">{event.vanueData.displayName}</p>
                        </div>
                        <div className="flex p-2 flex-wrap lg:flex-nowrap">{/* <p className="text-xs w-40 font-medium lg:font-bold text-[#8D919B]">{event.displayName}</p> */}</div>
                        <div className="flex gap-2 p-2 items-center px-2">
                            <div className="flex gap-1 items-center min-w-[14.1rem]">
                                <button className="bg-[#B5C3E5] rounded text-xs text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">Guest List</button>
                                <button className={`bg-[#B5C3E5] w-[56.38px] rounded text-xs text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center`}>
                                    <span>Tickets</span>
                                </button>
                                <button className="bg-[#B5C3E5] rounded text-xs text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                    <span>VIP Tables</span>
                                </button>
                            </div>
                            <p className="hidden xl:flex text-sm text-white">
                                <IoIosArrowForward className="w-[24px] h-[24px]" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}
