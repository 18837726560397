import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation } from "react-router-dom";

const musicCategories = [
  {
    id: 1,
    name: "EDM",
  },
  {
    id: 2,
    name: "Top 40",
  },
  {
    id: 3,
    name: "Pop",
  },
  {
    id: 4,
    name: "Rock",
  },
  {
    id: 5,
    name: "R&B",
  },
  {
    id: 6,
    name: "Latin",
  },
  {
    id: 7,
    name: "Reggaeton",
  },
  {
    id: 8,
    name: "House",
  },
  {
    id: 9,
    name: "Country",
  },
  {
    id: 10,
    name: "Trap",
  },
  {
    id: 11,
    name: "Open Format",
  },
  {
    id: 12,
    name: "Trance",
  },
  {
    id: 13,
    name: "Dubstep",
  },
  {
    id: 14,
    name: "Drum & Bass",
  },
  {
    id: 15,
    name: "Techno",
  },
  {
    id: 16,
    name: "Jazz/Blues",
  },
  {
    id: 17,
    name: "Rap/Hip-Hop",
  },
  {
    id: 18,
    name: "Hard Rock/Metal",
  },
  {
    id: 19,
    name: "Classical",
  },
  {
    id: 20,
    name: "Reggae",
  },
  {
    id: 21,
    name: "Funk/Disco",
  },
  {
    id: 22,
    name: "Downtempo",
  },
];
export default function MusicGenre() {
  const [isMusicOpen, setIsMusicOpen] = useState(false);
  const location = useLocation();

  const toggleMusicOptions = () => {
    setIsMusicOpen(!isMusicOpen);
  };

  if (location.pathname === "/venues") {
    return null;
  }

  return (
    <div className="w-full relative p-2">
      <div
        onClick={toggleMusicOptions}
        className="w-full lg:w-[207px] text-white flex items-center justify-between"
      >
        <span className="text-sm font-bold">Music Genre</span>
        <span className="mr-2">
          {isMusicOpen ? (
            <IoIosArrowUp className="w-[16px] h-[16px]" />
          ) : (
            <IoIosArrowDown className="w-[16px] h-[16px]" />
          )}
        </span>
      </div>
      {isMusicOpen && (
        <div className="relative w-full mt-5 overflow-hidden remove-scroll h-full">
          <div className="flex flex-col gap-4">
            {musicCategories.map((item) => (
              <div key={item.id} className="flex flex-col gap-2 ">
                <div
                  key={item.id}
                  className="flex justify-between items-center px-1"
                >
                  <span className="text-sm font-medium text-white hover:text-[#F9F9FA]">
                    {item.name}
                  </span>
                  <div
                    className={`group bg-transparent outline-none text-[#8D919B]`}
                  >
                    <input
                      type="checkbox"
                      className="appearance-none h-4 w-4 border border-[#A5A5A5] rounded-[3px] checked:bg-[#1463FF] checked:border-[#F9F9FA] focus:outline-none"
                    />
                  </div>
                </div>
                <span className="border border-[#222431] border-b-[0px] w-[100%]"></span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
