import { useEffect } from "react";
import SliderImage from "../../../../assets/venues.jpg";
import { IVanues } from "../../../../lib/firebase/storage";
type Props = {
    data?: IVanues;
};
export default function VenuesDetailSlider({ data }: Props) {
    useEffect(() => {
        const defaultCarousel = document.getElementById("default-carousel");

        if (defaultCarousel === null) {
            console.error("Default carousel element not found.");
            return; // Exit early if the element is not found
        }

        const carouselItems = defaultCarousel.querySelectorAll("[data-carousel-item]");
        const prevButton = defaultCarousel.querySelector("[data-carousel-prev]");
        const nextButton = defaultCarousel.querySelector("[data-carousel-next]");
        const indicators = defaultCarousel.querySelectorAll("[data-carousel-slide-to]");

        let currentIndex = 0;

        const showSlide = (index: number) => {
            carouselItems.forEach((item) => item.classList.add("hidden"));
            carouselItems[index].classList.remove("hidden");

            indicators.forEach((indicator) => indicator.setAttribute("aria-current", "false"));
            indicators[index].setAttribute("aria-current", "true");
        };

        const goToSlide = (index: number) => {
            if (index < 0) {
                currentIndex = carouselItems.length - 1;
            } else if (index >= carouselItems.length) {
                currentIndex = 0;
            } else {
                currentIndex = index;
            }
            showSlide(currentIndex);
        };

        const prevButtonClickHandler = () => goToSlide(currentIndex - 1);
        // const nextButtonClickHandler = () => goToSlide(currentIndex + 1);
        const indicatorClickHandler =
            (index = 0) =>
            () =>
                goToSlide(index);

        prevButton!.addEventListener("click", prevButtonClickHandler);
        if (prevButton !== null) {
            prevButton.addEventListener("click", prevButtonClickHandler);
        } else {
            console.error("Previous button not found.");
        }
        nextButton!.addEventListener("click", prevButtonClickHandler);
        if (prevButton !== null) {
            prevButton.addEventListener("click", prevButtonClickHandler);
        } else {
            console.error("Previous button not found.");
        }
        indicators.forEach((indicator, index) => {
            indicator.addEventListener("click", indicatorClickHandler(index));
        });
        showSlide(currentIndex);
    }, []);
    // const sliderSettings = {
    //     dots: false,
    //     infinite: true,
    //     speed: 500,
    //     vertical: true,
    //     // verticalSwiping: false,
    //     autoplay: true, // Enable autoplay
    //     autoplaySpeed: 3000,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    // };
    return (
        <div className="w-full">
            <div className="container mx-auto">
                <div className="flex flex-col gap-3">
                    <div className="text-2xl font-normal leading-none">
                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Gallery</span>
                    </div>
                    <div className="hidden relative text-white lg:block w-full md:w-[410px] h-[250px] rounded overflow-hidden">
                        <div className="max-w-2xl mx-auto">
                            <div
                                id="default-carousel"
                                className="relative"
                                data-carousel="static">
                                {/* Carousel wrapper */}
                                <div className="overflow-hidden relative h-56 rounded-lg sm:h-64 xl:h-80 2xl:h-96">
                                    {/* Item 1 */}
                                    <div
                                        className="hidden duration-700 ease-in-out"
                                        data-carousel-item>
                                        <span className="absolute top-1/2 left-1/2 text-2xl font-semibold text-white -translate-x-1/2 -translate-y-1/2 sm:text-3xl dark:text-gray-800">
                                            First Slide
                                        </span>
                                        <img
                                            src={data?.images[0]}
                                            className="block rounded-lg absolute top-[30%] left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                                            alt="..."
                                        />
                                    </div>
                                    {/* Item 2 */}
                                    <div
                                        className="hidden duration-700 ease-in-out"
                                        data-carousel-item>
                                        <img
                                            src={SliderImage}
                                            className="block rounded-lg absolute top-[30%] left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                                            alt="..."
                                        />
                                    </div>
                                    {/* Item 3 */}
                                    <div
                                        className="hidden "
                                        data-carousel-item>
                                        <img
                                            src={SliderImage}
                                            className="block rounded-lg absolute top-[30%] left-1/2 w-full -translate-x-1/2 -translate-y-1/2"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                {/* Slider indicators */}
                                <div className="flex absolute left-1/2 z-30 space-x-3 -translate-x-1/2">
                                    <button
                                        type="button"
                                        className="w-3 h-3 rounded-full"
                                        aria-current="false"
                                        aria-label="Slide 1"
                                        data-carousel-slide-to="0"></button>
                                    <button
                                        type="button"
                                        className="w-3 h-3 rounded-full"
                                        aria-current="false"
                                        aria-label="Slide 2"
                                        data-carousel-slide-to="1"></button>
                                    <button
                                        type="button"
                                        className="w-3 h-3 rounded-full"
                                        aria-current="false"
                                        aria-label="Slide 3"
                                        data-carousel-slide-to="2"></button>
                                </div>
                                {/* Slider controls */}
                                <button
                                    type="button"
                                    className="flex absolute -top-20 left-o z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
                                    data-carousel-prev>
                                    <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                        <svg
                                            className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M15 19l-7-7 7-7"></path>
                                        </svg>
                                        <span className="hidden">Previous</span>
                                    </span>
                                </button>
                                <button
                                    type="button"
                                    className="flex absolute -top-20 right-0 z-30 justify-center items-center px-4 h-full cursor-pointer group focus:outline-none"
                                    data-carousel-next>
                                    <span className="inline-flex justify-center items-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                                        <svg
                                            className="w-5 h-5 text-white sm:w-6 sm:h-6 dark:text-gray-800"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 5l7 7-7 7"></path>
                                        </svg>
                                        <span className="hidden">Next</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
