import _isEmpty from "lodash/isEmpty";
import { CiHeart } from "react-icons/ci";
import { RxUpload } from "react-icons/rx";
import { Artist, FirebaseStorage } from "../../../../lib/firebase/storage";
import { Link, useNavigate } from "react-router-dom";
import { FaFacebook, FaLinkedin, FaDiscord, FaTiktok, FaInstagram, FaTelegram, FaGlobe } from "react-icons/fa";
import { useAuth } from "../../../../providers/Authentication";
import { useQuery } from "react-query";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
type Props = {
    data?: Artist;
};
export default function DetailsImageArtists({ data }: Props) {
    const navigate = useNavigate();
    const { user } = useAuth();
    const { data: DBUser } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    const isAdmin = user?.uid === data?.adminId || DBUser?.roles?.includes("SiteAdmin");

    const handleDelete = async () => {
        await FirebaseStorage.deleteArtistById(data?.id as string);
        navigate("/artists");
    };
    return (
        <div className="w-full flex gap-7 lg:p-10 p-0 justify-center flex-wrap lg:flex-nowrap">
            <div className="w-full lg:w-6/12 ">
                <img
                    src={data?.photoURL}
                    alt="event"
                    className="w-full relative h-[20rem] lg:rounded-lg object-cover"
                />
            </div>
            <div className="w-full lg:w-6/12 lg:mt-0  flex flex-col gap-3 text-white">
                <div className="flex flex-col gap-2">
                    <div className="flex lg:justify-between justify-end p-3">
                        <p className="font-light leading-tight text-3xl">{data?.displayName}</p>
                        <div className="w-full lg:w-2/12 flex gap-2 justify-end">
                            <div className="w-full lg:w-1/2 flex justify-end">
                                <button className=" relative rounded-lg h-[44px] text-white bg-white/5 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                    <p className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></p>
                                    <p className="text-sm font-normal ">
                                        <CiHeart className="w-[20px] h-[20px]" />
                                    </p>
                                </button>
                            </div>
                            <div className="w-full lg:w-1/2 justify-end relative">
                                <button className=" relative rounded-lg h-[44px] text-white bg-white/5 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                    <p className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></p>
                                    <p className="text-sm font-normal">
                                        <RxUpload className="w-[20px] h-[20px]" />
                                    </p>
                                </button>
                                {isAdmin && (
                                    <div className="flex justify-center">
                                        <Link to={`/profile/edit-artist/${data?.id as string}`}>
                                            <FiEdit2 className="absolute right-40 top-2 cursor-pointer" />
                                        </Link>
                                        <span
                                            onClick={handleDelete}
                                            className="absolute right-32 top-2 cursor-pointer">
                                            <MdDelete size={18} />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <p className="lg:hidden border-b border-[#4F5159] w-screen"></p>
                    <div className="p-3 flex gap-2 items-center pt-2 flex-wrap lg:flex-nowrap">
                        {_isEmpty(data?.genre)
                            ? null
                            : data?.genre.map((genre) => (
                                  <button
                                      key={genre}
                                      className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                      <span>{genre}</span>
                                  </button>
                              ))}
                    </div>
                    <div className="p-3 flex gap-2 pt-2 flex-wrap lg:flex-nowrap flex-col">
                        {/* <Link
                            to={data?.email as string}
                            className="text-slate-400 flex items-center gap-2"
                            target="_blank">
                            <FaEnvelope /> <span>{data?.email}</span>
                        </Link>
                        <Link
                            to={data?.phone as string}
                            className="text-slate-400 flex items-center gap-2"
                            target="_blank">
                            <FaPhone /> <span> {data?.phone}</span>
                        </Link> */}
                        <div className="flex gap-2 mt-4">
                            {data?.facebook && (
                                <Link
                                    to={data?.facebook as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaFacebook size={20} />
                                </Link>
                            )}
                            {data?.linkedin && (
                                <Link
                                    to={data?.linkedin as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaLinkedin size={20} />
                                </Link>
                            )}
                            {data?.discord && (
                                <Link
                                    to={data?.discord as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaDiscord size={20} />
                                </Link>
                            )}
                            {data?.tiktok && (
                                <Link
                                    to={data?.tiktok as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaTiktok size={20} />
                                </Link>
                            )}
                            {data?.instagram && (
                                <Link
                                    to={data?.instagram as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaInstagram size={20} />
                                </Link>
                            )}
                            {data?.telegram && (
                                <Link
                                    to={data?.telegram as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaTelegram size={20} />
                                </Link>
                            )}
                            {data?.website && (
                                <Link
                                    to={data?.website as string}
                                    className="text-slate-400"
                                    target="_blank">
                                    <FaGlobe size={20} />
                                </Link>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
