import { FirebaseStorage } from "./storage";
import "./firebaseConfig";
import {
    FacebookAuthProvider,
    GoogleAuthProvider,
    auth,
    createUserWithEmailAndPassword,
    getRedirectResult,
    provider,
    providerFacebook,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateProfile,
    signInWithCustomToken,
} from "./firebaseConfig";

export class AuthenticationService {
    static async signUpEmailAndPassword(email: string, password: string) {
        const user = await createUserWithEmailAndPassword(auth, email, password);
        if (user) {
            return user;
        }
        return null;
    }
    static async signInEmailAndPassword(email: string, password: string) {
        const user = await signInWithEmailAndPassword(auth, email, password);
        if (user) {
            return user;
        }
        return null;
    }
    static async signInWithGoogle(route: any, setUser: (user: any) => void) {
        try {
            const response = await signInWithPopup(auth, provider);
            const credential = GoogleAuthProvider.credentialFromResult(response);
            if (credential) {
                const token = credential.accessToken;
                const user = response.user;
                console.log({ credential, token, user });

                await FirebaseStorage.createUser({
                    uid: user.uid,
                    displayName: user.displayName as string,
                    email: user.email as string,
                    photoURL: user.photoURL as string,
                    emailVerified: user.emailVerified,
                    phoneNumber: user.phoneNumber,
                });
                setUser(user);
                route("/profile");
            }
        } catch (error: any) {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // const email = error.customData.email;
            // const credential = GoogleAuthProvider.credentialFromError(error);
        }
    }
    static async signInWithFacebook(route: any, setUser: (user: any) => void) {
        try {
            const result = await signInWithPopup(auth, providerFacebook);
            const user = result.user;
            const credential = FacebookAuthProvider.credentialFromResult(result);
            if (credential) {
                // const accessToken = credential.accessToken;
                await FirebaseStorage.createUser({
                    uid: user.uid,
                    displayName: user.displayName as string,
                    email: user.email as string,
                    photoURL: user.photoURL as string,
                    emailVerified: user.emailVerified,
                    phoneNumber: user.phoneNumber,
                });
                setUser(user);
                route("/profile");
            }
        } catch (error: any) {
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // const email = error.customData.email;
            // const credential = FacebookAuthProvider.credentialFromError(error);
        }
    }
    static async signInWithToken(code: string, username: string, route: any, setUser: (user: any) => void) {
        const customToken = await signInWithCustomToken(auth, code);
        if (!auth.currentUser) {
            return;
        }
        await updateProfile(auth.currentUser, {
            displayName: username,
        });
        await FirebaseStorage.createUser({
            uid: customToken.user.uid,
            displayName: username,
            email: "",
            photoURL: "",
            emailVerified: true,
            phoneNumber: "",
            isInstagram: true,
        });
        setUser(customToken.user);
        route("/profile");
    }
    static async getResult() {
        try {
            const result = await getRedirectResult(auth);
            if (!result) return null;
            const credential = FacebookAuthProvider.credentialFromResult(result);
            return { credential, result };
        } catch (error) {
            console.log({ error });
        }
    }
}
