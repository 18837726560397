//@ts-nocheck
import { useQuery } from "react-query";
import { FirebaseStorage } from "../../lib/firebase/storage";
import { MdClose } from "react-icons/md";
import { useAuth } from "../../providers/Authentication";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export const UserManagement = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const { data: dbUser } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user?.uid }
    );

    const { data, error, isLoading, refetch } = useQuery({
        queryKey: "users",
        queryFn: FirebaseStorage.getAllUsers,
    });
    useEffect(() => {
        if (!dbUser?.roles?.includes("SiteAdmin")) {
            navigate("/profile");
        }
    }, [dbUser, navigate]);

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error</div>;

    const handleRoles = async (user, role, action) => {
        if (action === "remove") {
            let roles = [];
            if (user?.roles?.length) {
                roles = user.roles.filter((u) => u !== role);
            }
            try {
                await FirebaseStorage.assignRoles(user.id, roles);
                refetch();
            } catch (error) {
                console.log({ error });
            }
        } else {
            let roles = [role];
            if (user?.roles?.length) {
                roles = [...user.roles, role];
            }
            try {
                await FirebaseStorage.assignRoles(user.id, roles);
                refetch();
            } catch (error) {
                console.log({ error });
            }
        }
    };
    const handleDelete = async (user) => {
        try {
            const response = await fetch(`https://app-ztazf6bpbq-uc.a.run.app/api/user?id=${user.id}`, {
                method: "GET",
            });
            await response.json();
            refetch();
        } catch (error) {
            alert("Error");
        }
    };
    return (
        <div className="bg-black min-h-screen">
            <div className="relative overflow-x-auto">
                {/* <button className="flex text-white ml-auto bg-primary p-2 mr-2 mt-2">Add User</button> */}
                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                    <thead className="text-xs text-white uppercase dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th
                                scope="col"
                                className="px-6 py-3">
                                Name
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3">
                                Role
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3">
                                Status
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3">
                                Options
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((user) => (
                            <tr className="border-b dark:bg-gray-800 dark:border-gray-700 text-white">
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium whitespace-nowrap dark:text-white">
                                    {user?.displayName}
                                </th>
                                <td className="px-6 py-4 flex gap-2">
                                    {!user?.roles?.length ? (
                                        <span>User</span>
                                    ) : (
                                        user?.roles?.map((u) => (
                                            <span className={`${u === "SiteAdmin" ? "bg-primary" : u === "UserAdmin" ? "bg-yellow-500" : "bg-green-500"} p-2 flex items-center gap-2 `}>
                                                {u}
                                                <span
                                                    onClick={() => handleRoles(user, u, "remove")}
                                                    className="border cursor-pointer">
                                                    <MdClose />
                                                </span>
                                            </span>
                                        ))
                                    )}
                                </td>
                                <td className="px-6 py-4">Active</td>
                                <td className="px-6 py-4 gap-2 flex">
                                    {user?.roles?.includes("SiteAdmin") ? null : (
                                        <button
                                            className="bg-primary p-2"
                                            onClick={() => handleRoles(user, "SiteAdmin")}>
                                            Site Admin
                                        </button>
                                    )}
                                    {user?.roles?.includes("UserAdmin") ? null : (
                                        <button
                                            className="bg-yellow-500 p-2"
                                            onClick={() => handleRoles(user, "UserAdmin")}>
                                            User Admin
                                        </button>
                                    )}
                                    {user?.roles?.includes("EventArtistsVenue") ? null : (
                                        <button
                                            className="bg-green-500 p-2"
                                            onClick={() => handleRoles(user, "EventArtistsVenue")}>
                                            Event Artists Vanue Admin
                                        </button>
                                    )}
                                    {dbUser?.roles?.includes("SiteAdmin") && (
                                        <button
                                            className="bg-red-500 p-2"
                                            onClick={() => handleDelete(user)}>
                                            Delete
                                        </button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
