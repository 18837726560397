import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";
import musicCategories from "../../../data/VanueTypes.json";
import { useVanuesStore } from "../../../state/vanueStore";
import { useEventStore } from "../../../state/eventStore";

export default function VanueType() {
  const { slug } = useParams();
  const { filters, filterVanues } = useVanuesStore();
  const { filters: eventFiler, filterEvents } = useEventStore();
  const [isMusicOpen, setIsMusicOpen] = useState(false);
  const { pathname } = useLocation();
  const isEvent = pathname.split("/")[2] === "events";
  const city = pathname.split("/")[1];
  const toggleMusicOptions = () => {
    setIsMusicOpen(!isMusicOpen);
  };
  const handleVanueType = (item: any, e: any) => {
    if (isEvent) {
      filterEvents(city, {
        ...eventFiler,
        [item.value]: e.target.checked,
      });
    } else {
      filterVanues(slug as string, {
        ...filters,
        [item.value]: e.target.checked,
      });
    }
  };
  return (
    <div className="w-full relative p-2">
      <div
        onClick={toggleMusicOptions}
        className="w-full lg:w-[207px] text-white flex items-center justify-between"
      >
        <span className="text-sm font-bold">Venue Type</span>
        <span className="mr-2">
          {isMusicOpen ? (
            <IoIosArrowUp className="w-[16px] h-[16px]" />
          ) : (
            <IoIosArrowDown className="w-[16px] h-[16px]" />
          )}
        </span>
      </div>
      {isMusicOpen && (
        <div className="relative w-full mt-5 remove-scroll h-full">
          <div className="flex flex-col gap-5">
            {musicCategories.map((item) => (
              <div key={item.id} className="flex flex-col gap-2">
                <div
                  key={item.id}
                  className="flex justify-between items-center px-1"
                >
                  <span className="text-sm font-medium text-white hover:text-[#F9F9FA]">
                    {item.name}
                  </span>
                  <div
                    className={`group bg-transparent outline-none text-[#8D919B]`}
                  >
                    <input
                      type="checkbox"
                      className="appearance-none h-4 w-4 border border-[#A5A5A5] rounded-[4px] checked:bg-[#1463FF] checked:border-[#F9F9FA] focus:outline-none"
                      onChange={(e) => handleVanueType(item, e)}
                    />
                  </div>
                </div>
                <span className="border border-[#222431] border-b-[0px] w-[100%]"></span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
