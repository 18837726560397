export default function TextInput({ name, id, type, placeholder, className, value, onChange, disabled }: any) {
    return (
        <input
            id={id}
            type={type}
            placeholder={placeholder}
            className={`text-white ${className}`}
            value={value}
            onChange={onChange}
            name={name}
            disabled={disabled}
        />
    );
}
