import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import slugify from "slugify";
import cities from "../../../data/cities.json";
import Select from "react-select";

export default function LasVegas() {
    const nav = useNavigate();
    const [newCitiesArray, setNewCitiesArray] = useState<{ label: string; value: string }[]>([]);
    const location = useLocation();
    const currentPath = location.pathname.split("/")[2];

    const handleRoute = (item: string) => {
        let city = item || "las-vegas";
        const newCity = slugify(city as string, {
            lower: true,
        });
        nav(`/${newCity}/${currentPath}`);
    };

    useEffect(() => {
        let tempCitiesArray: { label: string; value: string }[] = [];
        Object.entries(cities).forEach((item) => {
            item[1].forEach((city) => {
                tempCitiesArray.push({
                    label: city,
                    value: city,
                });
            });
        });
        setNewCitiesArray(tempCitiesArray);
    }, []);

    return (
        <div className="w-full">
            <div className="flex flex-col gap-1 relative">
                <span className="text-sm font-bold">Where?</span>
                <Select
                    options={newCitiesArray}
                    placeholder="Las Vegas"
                    className="w-full lg:w-[207px]"
                    onChange={(e) => {
                        handleRoute(e?.label as string);
                    }}
                    defaultValue={newCitiesArray[0]}
                    styles={{
                        control(base, props) {
                            return {
                                ...base,
                                border: "1px solid #454750",
                                backgroundColor: "#1C1D22",
                                color: "white",
                                borderRadius: "8px",
                                padding: "2px",
                            };
                        },
                        input(base, props) {
                            return {
                                ...base,
                                color: "white",
                            };
                        },
                        placeholder(base, props) {
                            return {
                                ...base,
                                color: "white",
                            };
                        },
                        menuList(base, props) {
                            return {
                                ...base,
                                backgroundColor: "#2B2D3C",
                                color: "white",
                            };
                        },
                        option(base, props) {
                            return {
                                ...base,
                                backgroundColor: "#2B2D3C",
                            };
                        },
                    }}
                />
            </div>
        </div>
    );
}
