import { useEffect } from "react";
import { Sidebar, VenuesCard } from "../components/comevent/components";
import { IVanues } from "../lib/firebase/storage";
import { useLocation, useParams } from "react-router-dom";
import SkeletonVenues from "../components/SkeletonVenues/SkeletonVenues";
import { useVanuesStore } from "../state/vanueStore";
import { split } from "lodash";

export default function Venues() {
    const { vanues, isLoading, getInitialVanues } = useVanuesStore();
    const { slug } = useParams();
    const { pathname } = useLocation();
    const city = split(pathname, "/")[1];

    useEffect(() => {
        getInitialVanues(slug as string);
    }, [slug, city, getInitialVanues]);

    return (
        <div className="w-full bg-[#0A0D16] flex gap-5 min-h-screen">
            <div className="w-full lg:w-2/12 hidden lg:block">
                <Sidebar isVanue={true} />
            </div>
            {isLoading ? (
                <div className="w-full lg:w-9/12">
                    <SkeletonVenues count={6} />
                </div>
            ) : (
                <div className="w-full lg:w-9/12">
                    <VenuesCard data={vanues as IVanues[]} />
                </div>
            )}
        </div>
    );
}
