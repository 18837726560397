import { useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { Calendar, MusicGenre, VanueType, EventType, VenueFeatures, MobileScreen } from "../../comevent/components";
import SelectInput from "../../SelectInput";

type Props = {
    isVanue?: boolean;
};
export default function Sidebar({ isVanue }: Props) {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="w-full ">
            <div className="conatiner mx-auto relative">
                <div className="container w-full lg:w-[253px] flex flex-col mx-auto bg-[#0A0D16]">
                    <aside
                        className=" hidden lg:flex flex-col shrink-0 w-full lg:w-[253px] bg-[#0A0D16] 
             transition-all duration-300 ease-in-out border-r border-[#454750]
            mt-[53px] fixed z-10 inset-y-0 left-0 h-screen overflow-y-scroll no-scrollbar">
                        <div className="hidden lg:block relative p-5 my-5 text-white bg-[#0A0D16] border-[#454750] h-full remove-scroll">
                            <div className="flex flex-col gap-6 pt-7 w-full font-medium">
                                <SelectInput screen="EVENT" />

                                {!isVanue && <Calendar />}

                                {/* /// Entry Options start /// */}

                                {/* {!isVanue && (
                                    <div className="flex flex-col gap-1">
                                        <span className="text-sm font-bold">Entry Options</span>
                                        <div className="flex flex-col gap-2">
                                            <button className="border relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[40px] text-white bg-[#1C1D22] p-3 flex items-center gap-2 group overflow-hidden">
                                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                                <span className="text-sm font-normal">Guest List Open</span>
                                            </button>
                                            <button className="border relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[40px] text-white bg-[#1C1D22] p-3 flex items-center gap-2 group overflow-hidden">
                                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                                <span className="text-sm font-normal">Tickets Availble</span>
                                            </button>
                                            <button className="border relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[40px] text-white bg-[#1C1D22] p-3 flex items-center gap-2 group overflow-hidden">
                                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>

                                                <span className="text-sm font-normal">VIP Tables Availble</span>
                                            </button>
                                        </div>
                                    </div>
                                )} */}
                                {/* /// Entry Options end /// */}

                                {/* {!isVanue && (
                                    <div className="flex flex-col gap-3">
                                        <span className="text-sm font-bold">Options</span>
                                        <div className="flex flex-col gap-1">
                                            <button className="border relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[45px] text-white p-3 flex items-center gap-2 group overflow-hidden">
                                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[45px] bg-[#DCDDE1]/20 z-0"></span>

                                                <span className="text-sm font-normal">Featured Venues</span>
                                            </button>
                                            <button className="border mt-1 relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[45px] text-white p-3 flex items-center gap-2 group overflow-hidden">
                                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[45px] bg-[#DCDDE1]/20 z-0"></span>
                                                <span className="text-sm font-normal">News Venues</span>
                                            </button>
                                        </div>
                                    </div>
                                )} */}

                                {/* /// Entry Options end /// */}
                                {/* /// Featured Events start /// */}
                                {/* {location.pathname !== "/venues" && (
                                    <div className="">
                                        <button className="border relative border-[#454750] hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] h-auto lg:h-[40px] text-white bg-[#1C1D22] p-3 flex items-center gap-2 group overflow-hidden">
                                            <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                            <span className="text-sm font-normal">Featured Events</span>
                                        </button>
                                    </div>
                                )} */}

                                <div className="flex flex-col gap-7 pt-2 ">
                                    <MusicGenre /> <VanueType />
                                    {!isVanue && <EventType />} <VenueFeatures />
                                </div>
                            </div>
                        </div>
                    </aside>
                </div>
                <div className="lg:hidden fixed right-5 z-50 bg-white bottom-20">
                    <button
                        onClick={toggleOpen}
                        className="text-[#D2D2D5] rounded-full text-sm border border-[#757787] p-2.5">
                        <MdOutlineFilterList className="w-[25px] h-[25px]" />
                    </button>
                </div>
                <div className="lg:hidden h-full absolute top-0 left-0">{isOpen && <MobileScreen setIsOpen={setIsOpen} />}</div>
            </div>
        </div>
    );
}
