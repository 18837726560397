import { useQuery } from "react-query";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../providers/Authentication";
import { FirebaseStorage } from "../lib/firebase/storage";
import ProfileHeader from "./Profile/header";
import TextInput from "../components/TextInput/TextInput";

const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    instagram: yup.string().required("Instagram is required"),
    facebook: yup.string().required("Facebook is required"),
    wikipedia: yup.string(),
});

export default function RequestArtistForm() {
    const { user, setUser } = useAuth();
    const { data, isLoading, refetch } = useQuery(
        "artistRequest",
        async () => {
            return await FirebaseStorage.getArtistRequest(user.uid);
        },
        { enabled: !!user.uid }
    );
    let initialValues = {
        name: "",
        facebook: "",
        instagram: "",
        wikipedia: "",
    };
    const { handleChange, values, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit(values, formikHelper) {
            let params = { ...values, uid: user.uid };
            FirebaseStorage.artistRequest(params).then((res) => {
                formikHelper.resetForm();
                refetch();
            });
        },
    });

    return (
        <div className="bg-black h-screen">
            <div className="max-w-screen-xl mx-auto">
                <ProfileHeader />
            </div>
            <div className="max-w-screen-xl mx-auto text-white">
                {isLoading ? (
                    <span className="text-center flex mt-10 text-md font-bold">Loading</span>
                ) : data?.length ? (
                    <span className="text-center flex mt-10 text-xl font-bold">Request sent and pending admin will respond you soon</span>
                ) : (
                    <div className=" dark:bg-gray-700 shadow rounded-lg p-6">
                        <h1 className="text-white text-xl font-bold text-center mb-10">Request for artists</h1>
                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="">
                                    <label
                                        htmlFor="firstName"
                                        className="text-white text-lg block mb-2">
                                        Artists Name
                                    </label>
                                    <TextInput
                                        id="firstName"
                                        type="text"
                                        placeholder="First Name"
                                        className={`border ${touched.name && errors.name ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            touched.name && errors.name ? "active:border-red-400" : "active:border-white/40"
                                        } ${touched.name && errors.name ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                        value={values.name}
                                        name="name"
                                        onChange={handleChange}
                                    />
                                    {touched.name && errors.name && <p className="text-red-400 text-sm mt-1">{errors.name}</p>}
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="lastName"
                                        className="text-white text-lg block mb-2">
                                        Facebook URL
                                    </label>
                                    <TextInput
                                        id="lastName"
                                        type="text"
                                        placeholder="Akcora"
                                        className={`border ${touched.facebook && errors.facebook ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            touched.facebook && errors.facebook ? "active:border-red-400" : "active:border-white/40"
                                        } ${touched.facebook && errors.facebook ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                        value={values.facebook}
                                        name="facebook"
                                        onChange={handleChange}
                                    />
                                    {touched.facebook && errors.facebook && <p className="text-red-400 text-sm mt-1">{errors.facebook}</p>}
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                                <div className="mb-4">
                                    <label
                                        htmlFor="instagramURL"
                                        className="text-white text-lg block mb-2">
                                        Instagram URL
                                    </label>
                                    <TextInput
                                        id="InstagramURL"
                                        type="text"
                                        placeholder="Instagram"
                                        className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                                        name="instagram"
                                        onChange={handleChange}
                                        value={values.instagram}
                                    />
                                </div>
                                {/* facebook url */}
                                <div className="mb-4">
                                    <label
                                        htmlFor="fbURL"
                                        className="text-white text-lg block mb-2">
                                        Wikipedia
                                    </label>
                                    <TextInput
                                        id="fbURL"
                                        type="text"
                                        placeholder="wikipedia"
                                        className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                                        name="wikipedia"
                                        onChange={handleChange}
                                        value={values.wikipedia}
                                    />
                                </div>
                            </div>

                            <div className="flex items-end justify-end">
                                <button
                                    type="submit"
                                    className="w-full md:w-auto px-5 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none transition-colors">
                                    Request Submit
                                </button>
                            </div>
                            {/* logout btn for mobile */}
                            <div className="md:hidden mt-2 flex items-end justify-end">
                                <button
                                    type="button"
                                    id="theme-toggle"
                                    onClick={() => setUser(null)}
                                    className="w-full md:w-auto px-5 py-2 rounded-lg border border-red-500 text-red-500">
                                    Log Out
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
}
