import React, { useCallback, useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BsCalendar2 } from "react-icons/bs";

export default function Calender({ handleDate }: any) {
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarValue, setCalendarValue] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectCalendarDate, setSelectCalendarDate] = useState<string>("Today");
    const calendarRef = useRef<HTMLDivElement>(null);

    const handleDivClick = (event: any) => {
        const isNavigationButtonClick =
            (event.target as HTMLElement).classList.contains("react-calendar__navigation__prev-button") || (event.target as HTMLElement).classList.contains("react-calendar__navigation__next-button");

        if (!isNavigationButtonClick) {
            setShowCalendar(!showCalendar);
        }
    };

    const isPastDayInJanuary = (date: Date) => {
        const today = new Date();
        const isJanuary = date.getMonth() === 0; // January is month 0
        const isPastDay = date < today;

        return isJanuary && isPastDay;
    };

    // Update tileDisabled to disable past days in January
    const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
        if (view === "month" && isPastDayInJanuary(date)) {
            return true;
        }
        return false;
    };
    const handleCalendarChange = (value: any) => {
        setCalendarValue(value);
    };

    const handleDateClick = (value: Date) => {
        handleDate(value);
        setSelectedDate(value);
        const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
        }).format(value);

        setSelectCalendarDate(formattedDate);
        setShowCalendar(false);
    };

    const handleClickOutside = useCallback((event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (calendarRef.current && !calendarRef.current.contains(target) && !target.classList.contains("calendar-trigger")) {
            setShowCalendar(false);
        }
    }, []);
    const handleResetDate = () => {
        setSelectedDate(null);
        setSelectCalendarDate("Today");
        setCalendarValue(new Date());
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [handleClickOutside]);

    useEffect(() => {
        return () => {
            setSelectedDate(null);
        };
    }, []);

    return (
        <div
            onClick={handleDivClick}
            className="relative flex items-center bg-[#2B2D3C] border border-white/30 py-[10.9px] px-4 rounded-md cursor-pointer w-44 md:w-72 hover:border-white/50 duration-300 z-40">
            <div className="flex items-center gap-2 justify-center container mx-auto">
                <span>
                    <BsCalendar2 />
                </span>
                <span>{selectCalendarDate}</span>
            </div>
            {showCalendar && (
                <div
                    className="absolute top-10 right-0 md:left-0 bg-black shadow-md border border-white/20 rounded-md w-72 md:w-[320px]"
                    ref={calendarRef}>
                    <Calendar
                        className="react-calendar"
                        calendarType="US"
                        next2Label={null}
                        prev2Label={null}
                        value={calendarValue}
                        onChange={handleCalendarChange}
                        onClickDay={handleDateClick}
                        tileDisabled={tileDisabled}
                    />
                    {selectedDate && (
                        <button
                            onClick={handleResetDate}
                            className="my-3 ml-3 relative flex items-center gap-1 bg-tranparent text-white font-medium px-3 py-3 rounded-md group overflow-hidden">
                            <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-white/10 z-0"></span>
                            <span className="z-50">Clear Date</span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}
