import { IoMdClose } from "react-icons/io";
import { MusicGenre, VenueFeatures, VanueType, EventType, LasVegas, CalendarSlider } from "../../comevent/components";

export default function MobileScreen({ setIsOpen }: any) {
    const toggleClose = () => {
        setIsOpen(false);
    };
    return (
        <div className="w-[100vw] h-full bg-[#1C2040]">
            <div className="container relative p-5 text-white">
                <div className="z-0">
                    <div className="flex flex-col gap-4">
                        <div className="flex justify-between">
                            <div className="flex justify-center items-center">
                                <p className="text-center text-xl">Options</p>
                            </div>
                            <div className="flex justify-end">
                                <button
                                    onClick={toggleClose}
                                    className="cursor-pointer">
                                    <IoMdClose className="w-[30px] h-[30px]" />
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-center gap-2">
                            <LasVegas />
                            <CalendarSlider />
                        </div>
                        <div className="flex flex-col gap-4">
                            <p>Entry Options</p>
                            <div className="flex flex-col gap-2 ">
                                <div className="flex justify-between items-center ">
                                    <span className="text-base font-normal text-[#8D919B] hover:text-[#F9F9FA]">Guest List Open</span>
                                    <div className={`group bg-transparent outline-none text-[#8D919B]`}>
                                        <input
                                            type="checkbox"
                                            className="appearance-none h-6 w-6 border border-[#8D919B] rounded-md checked:bg-[#1463FF] checked:border-[#F9F9FA] focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <span className="border border-[#454750] border-b-[0px] w-[100%]"></span>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <div className="flex justify-between items-center ">
                                    <span className="text-base font-normal text-[#8D919B] hover:text-[#F9F9FA]">Tickets Availble</span>
                                    <div className={`group bg-transparent outline-none text-[#8D919B]`}>
                                        <input
                                            type="checkbox"
                                            className="appearance-none h-6 w-6 border border-[#8D919B] rounded-md checked:bg-[#1463FF] checked:border-[#F9F9FA] focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <span className="border border-[#454750] border-b-[0px] w-[100%]"></span>
                            </div>
                            <div className="flex flex-col gap-2 ">
                                <div className="flex justify-between items-center ">
                                    <span className="text-base font-normal text-[#8D919B] hover:text-[#F9F9FA]">VIP Tables Availble</span>
                                    <div className={`group bg-transparent outline-none text-[#8D919B]`}>
                                        <input
                                            type="checkbox"
                                            className="appearance-none h-6 w-6 border border-[#8D919B] rounded-md checked:bg-[#1463FF] checked:border-[#F9F9FA] focus:outline-none"
                                        />
                                    </div>
                                </div>
                                <span className="border border-[#454750] border-b-[0px] w-[100%]"></span>
                            </div>
                            <div className="flex flex-col gap-10 pt-5">
                                <MusicGenre />
                                <VanueType />
                                <VenueFeatures />
                                <EventType />
                            </div>
                        </div>
                    </div>
                    <div className="fixed bottom-0 left-0 w-full flex items-center justify-center p-2">
                        <div className="w-6/12 flex items-center rounded-lg bg-[#1C2040] p-3">Clear All</div>
                        <div className="w-6/12 flex items-center justify-center rounded-lg bg-[#1463FF] p-3">Apply</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
