import React, { useState } from "react";
import { IoChevronDownOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function PaginationDropdownList() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState(1);

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Function to close the dropdown when clicking outside of it
  const handleClickOutside = (event: any) => {
    if (
      !event.target.closest("#dropdown-button") &&
      !event.target.closest("#dropdown-menu")
    ) {
      setIsDropdownOpen(false);
    }
  };

  // Function to handle page selection
  const handleSelection = (page: any) => {
    setSelectedPage(page);
    setIsDropdownOpen(false);
  };

  // Attach click outside listener when component mounts
  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block text-left">
      <div className="flex items-center gap-2">
        <span className="text-white/70 text-[16px]">Pages</span>
        <div
          id="dropdown-button"
          className="w-20 md:w-20 inline-flex items-center justify-center py-2 text-sm font-medium text-white bg-black border border-white/40 hover:border-white/50 duration-300 ease-in-out cursor-pointer rounded-md shadow-sm"
          onClick={toggleDropdown}
        >
          <div className="flex items-center gap-3">
            <span className="text-white/70 text-[16px]">{selectedPage}</span>
            <span className="text-white/70 text-[16px]">|</span>
            <span>
              <IoChevronDownOutline />
            </span>
          </div>
        </div>
        <span className="text-white/70 text-[16px] w-16 md:w-24">of 10</span>
      </div>
      {/* Dropdown menu */}
      {isDropdownOpen && (
        <div
          id="dropdown-menu"
          className="origin-top-right absolute right-[105px] mt-1 rounded-md shadow-lg bg-black z-50 border border-white/50 "
        >
          <div
            className="py-2 pl-2 w-[75px] h-96 overflow-y-scroll"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="dropdown-button"
          >
            {[...Array(10)].map((_, index) => (
              <Link
                key={index}
                to="#"
                onClick={() => handleSelection(index + 1)}
                className={`block py-2 pl-4 text-sm text-white hover:bg-white/20 cursor-pointer ${
                  selectedPage === index + 1 &&
                  "border border-blue-500 rounded-md hover:bg-none cursor-default"
                }`}
                role="menuitem"
              >
                {index + 1}
              </Link>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
