import React from "react";

export default function ArtistsDetailSkeleton() {
    return (
        <div className="bg-black h-screen px-5 lg:px-14 lg:pt-10 p-7">
            <div className="max-w-screen-2xl mx-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex flex-col lg:flex-row">
                <div className="flex items-center justify-center lg:!w-[90%] h-[320px] bg-gray-300 rounded-lg dark:bg-gray-700">
                    <svg
                        className="w-10 h-10 text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
                <div className="w-full pt-6">
                    <div className="flex items-center gap-x-48 lg:justify-between">
                        <div className="h-8 bg-gray-200 dark:bg-gray-700 w-full lg:w-44" />
                        <div className="flex items-center gap-2">
                            <div className="h-8 rounded-lg bg-gray-200 dark:bg-gray-700 w-10" />
                            <div className="h-8 rounded-lg bg-gray-200 dark:bg-gray-700 w-10" />
                        </div>
                    </div>

                    <div className="h-8 bg-gray-200 dark:bg-gray-700 max-w-[100px] mb-8 mt-5" />

                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[200px] mb-3" />
                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[200px] mb-3" />
                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[200px]" />
                </div>
                <span className="sr-only">Loading...</span>
            </div>

            {/* <div className="hidden lg:flex items-center gap-x-[28rem] max-w-screen-2xl mx-auto mt-10 animate-pulse">
        <div className="h-8 bg-gray-200 dark:bg-gray-700 w-full lg:w-72" />
        <div className="flex items-center gap-2">
          <div className="h-8 bg-gray-200 dark:bg-gray-700 w-60" />
          <div className="h-8 bg-gray-200 dark:bg-gray-700 w-32" />
        </div>
      </div> */}
            {/* for upcoming events text */}
            {/* <div className="h-4 bg-gray-300 w-44 mt-5 animate-pulse ml-[155px]" /> */}
            <div className="rounded-lg animate-pulse w-full mt-[120px] max-w-screen-2xl mx-auto">
                <div className="h-6 bg-gray-300 rounded w-[250px]" />
                <div className="h-4 bg-gray-300 rounded w-[200px] mt-5" />

                <div className="flex items-center space-x-4 py-6">
                    <div className="rounded-lg bg-gray-300 h-12 w-12 lg:w-32 lg:h-32" />
                    <div className="flex justify-between">
                        <div className="h-4 bg-gray-300 rounded w-3/4" />
                        <div className="h-4 bg-gray-300 rounded w-3/4" />
                        <div className="h-4 bg-gray-300 rounded w-3/4" />
                    </div>
                    <div className="flex-1 space-y-4 py-1">
                        <div className="flex justify-between gap-3">
                            <div className="h-6 bg-gray-300 rounded w-[20%]" />
                            <div className="h-6 bg-gray-300 rounded w-[20%]" />
                            <div className="h-6 bg-gray-300 rounded w-[20%]" />
                        </div>

                        <div className="h-4 bg-gray-300 rounded w-[10%]" />
                        {/* <div className="space-y-2">
              <div className="h-4 bg-gray-300 rounded" />
              <div className="h-4 bg-gray-300 rounded w-5/6" />
            </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
