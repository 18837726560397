import { HiOutlineCalendar } from "react-icons/hi2";
import { CiHeart } from "react-icons/ci";
import { RxUpload } from "react-icons/rx";
import { FirebaseStorage, IEvents } from "../../../../lib/firebase/storage";
import { startCase } from "lodash";
import moment from "moment";
import { useAuth } from "../../../../providers/Authentication";
import { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { MdDelete } from "react-icons/md";
const button = [
    {
        id: 1,
        title: "Outdoor Patio",
    },
    {
        id: 2,
        title: "Serves Food",
    },
    {
        id: 3,
        title: "Lounge",
    },
    {
        id: 4,
        title: "Concert Venue",
    },
];
type Props = {
    event: IEvents;
};
export default function DetailsImage({ event }: Props) {
    const [titleEdit, setTitleEdit] = useState(false);
    const navigate = useNavigate();
    const city = localStorage.getItem("city") || "las-vegas";
    const { user } = useAuth();
    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    const isAdmin = user?.uid === event.vanueData.adminId || data?.roles?.includes("SiteAdmin");

    const handleDelete = async () => {
        await FirebaseStorage.deleteEventById(event.id);
        navigate(`/${city}/events`);
    };

    return (
        <div className="w-full">
            <div className="container mx-auto">
                <div className="w-full flex lg:gap-7 gap-0 lg:p-10 p-0 justify-center flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-6/12 relative">
                        <div className="hidden lg:block">
                            <img
                                src={event.images[0]}
                                alt="event"
                                className="w-full relative h-[20rem] rounded-lg object-cover"
                            />
                        </div>
                        {/* /// mobile screen /// */}
                        <div className="lg:hidden w-full fixed top-0 left-0">
                            <img
                                src={event.images[0]}
                                alt="event"
                                className="w-full relative h-[21rem] object-cover"
                            />
                        </div>
                        <div className="lg:hidden w-full h-[100%] bg-[#0A0D16] fixed top-[22rem] left-0"></div>
                    </div>
                    <div className="w-full lg:w-6/12 lg:mt-0 sm:mt-[16%] mt-[24%] flex flex-col lg:gap-3 gap-1 text-white z-20">
                        <div className="flex flex-col lg:gap-2 gap-1 p-3 relative">
                            <p className="font-light leading-tight text-3xl">{event.displayName}</p>
                            {isAdmin && (
                                <div className="flex justify-center">
                                    <Link to={`/profile/edit-event/${event.id}`}>
                                        <FiEdit2
                                            className="absolute right-10 top-5 cursor-pointer"
                                            onClick={() => setTitleEdit(!titleEdit)}
                                        />
                                    </Link>
                                    <span
                                        onClick={handleDelete}
                                        className="absolute right-3 top-5 cursor-pointer">
                                        <MdDelete size={18} />
                                    </span>
                                </div>
                            )}
                            <p className="lg:text-[#8D919B] text-white font-light ">{startCase(event.vanueData.city)}</p>
                            <div className="hidden lg:flex gap-1 items-center text-white">
                                <p>
                                    <HiOutlineCalendar className=" w-[20px] h-[20px]" />
                                </p>
                                <p className="text-small">{moment(event.timestamp).format("dddd, MMMM Do YYYY h:mm A")}</p>
                            </div>
                            <div className="hidden lg:flex gap-2 items-center pt-2 flex-wrap lg:flex-nowrap z-20">
                                {button.map((btn) => (
                                    <button
                                        key={btn.id}
                                        className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded text-sm text-center leading-tight py-1 px-1.5 flex justify-center items-center">
                                        <span>{btn.title}</span>
                                    </button>
                                ))}
                            </div>
                            <div className="flex gap-2 items-center w-full pt-2">
                                <div className="w-full lg:w-10/12">
                                    {/* <button className=" relative rounded-lg w-full h-auto lg:h-[40px] text-white lg:bg-[#1C1D22] bg-[#29333E]/60 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                        <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                        <span className="text-sm font-normal">{`I'm Going`}</span>
                                    </button> */}
                                </div>
                                <div className="w-full lg:w-2/12 flex gap-2">
                                    <div className="w-full lg:w-1/2">
                                        <button className="w-full relative rounded-lg h-auto lg:h-[40px] text-white lg:bg-[#1C1D22] bg-[#29333E]/60 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                            <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                            <span className="text-sm font-normal">
                                                <CiHeart className="w-[20px] h-[20px] " />
                                            </span>
                                        </button>
                                    </div>
                                    <div className="w-full lg:w-1/2">
                                        <button className="w-full relative rounded-lg h-auto lg:h-[40px] text-white lg:bg-[#1C1D22] bg-[#29333E]/60 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                            <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></span>
                                            <span className="text-sm font-normal">
                                                <RxUpload className="w-[20px] h-[20px]" />
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /// mobile screen /// */}
                        <p className="lg:hidden border-b border-[#4F5159] w-screen"></p>
                        <div className="lg:hidden flex flex-col gap-1 p-3">
                            <div className=" flex gap-1 items-center text-white">
                                <p>
                                    <HiOutlineCalendar className=" w-[20px] h-[20px]" />
                                </p>
                                <p className="text-small">{moment(event.timestamp).format("dddd, MMMM Do YYYY h:mm A")}</p>
                            </div>
                            <div className="flex gap-2 items-center pt-2 flex-wrap lg:flex-nowrap z-20">
                                {button.map((btn) => (
                                    <button
                                        key={btn.id}
                                        className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded text-sm text-center leading-tight py-1 px-1.5 flex justify-center items-center">
                                        <span>{btn.title}</span>
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
