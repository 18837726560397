import { CiHeart } from "react-icons/ci";
import { RxUpload } from "react-icons/rx";
import { FirebaseStorage, IVanues } from "../../../../lib/firebase/storage";
import _startCase from "lodash/startCase";
import { Link, useNavigate } from "react-router-dom";
import { FaDiscord, FaEnvelope, FaFacebook, FaGlobe, FaLocationArrow, FaInstagram, FaLinkedin, FaPhone, FaTelegram, FaTiktok } from "react-icons/fa";
import { useAuth } from "../../../../providers/Authentication";
import { useQuery } from "react-query";
import { FiEdit2 } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import slugify from "slugify";
type Props = {
    data?: IVanues;
};
export default function VenuesImageDetail({ data }: Props) {
    const navigate = useNavigate();
    const city = localStorage.getItem("city") || "las-vegas";
    const { user } = useAuth();
    const { data: DBUser } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    const isAdmin = user?.uid === data?.adminId || DBUser?.roles?.includes("SiteAdmin");

    const handleDelete = async () => {
        const newCity = slugify(city as string, { lower: true });
        await FirebaseStorage.deleteVanueById(data?.id as string);
        navigate(`/${newCity}/venues`);
    };
    return (
        <div className="w-full container">
            <div className="w-full flex gap-7 lg:p-10 p-0 justify-center flex-wrap lg:flex-nowrap">
                <div className="w-full lg:w-6/12 hidden lg:block">
                    <img
                        src={data?.images[0]}
                        alt="event"
                        className="w-full relative h-[20rem] rounded-lg object-cover"
                    />
                </div>
                <div className="w-full lg:w-6/12 lg:mt-0 mt-[22%] flex flex-col gap-1 text-white">
                    <div className="flex flex-col">
                        <div className="flex lg:justify-between justify-end p-3">
                            <p className="font-light leading-tight text-3xl">{data?.displayName}</p>
                            <div className="w-full lg:w-2/12 flex gap-2 justify-end relative">
                                <div className="w-full lg:w-1/2 flex justify-end">
                                    <button className=" relative rounded-lg h-auto lg:h-[40px] text-white bg-white/5 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                        <p className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></p>
                                        <p className="text-sm font-normal">
                                            <CiHeart className="w-[20px] h-[20px]" />
                                        </p>
                                    </button>
                                </div>
                                <div className="w-full lg:w-1/2 justify-end">
                                    <button className=" relative rounded-lg h-auto lg:h-[40px] text-white bg-white/5 p-3 flex items-center justify-center gap-2 group overflow-hidden">
                                        <p className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 lg:h-[40px] bg-[#DCDDE1]/20 z-0"></p>
                                        <p className="text-sm font-normal">
                                            <RxUpload className="w-[20px] h-[20px]" />
                                        </p>
                                    </button>
                                </div>
                                {isAdmin && (
                                    <div className="flex justify-center">
                                        <Link to={`/profile/edit-venue/${data?.id as string}`}>
                                            <FiEdit2 className="absolute right-[4.5rem] top-14 cursor-pointer" />
                                        </Link>
                                        <span
                                            onClick={handleDelete}
                                            className="absolute right-6 top-14 cursor-pointer">
                                            <MdDelete size={18} />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <p className="text-[#8D919B] font-light text-lg p-3">{`${data?.displayName} ∙ ${_startCase(data?.city)}`}</p>
                        <p className="lg:hidden border-b border-[#4F5159] w-screen"></p>
                        <div className="p-3 flex flex-col gap-2 pt-2 flex-wrap lg:flex-nowrap">
                            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
                                {data?.concertVenue && (
                                    <button className="border border-[#43454D] bg-transparent text-white rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>concertVenue</span>
                                    </button>
                                )}
                                {data?.danceFloor && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>danceFloor</span>
                                    </button>
                                )}
                                {data?.dayClub && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>dayClub</span>
                                    </button>
                                )}
                                {data?.food && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>food</span>
                                    </button>
                                )}
                                {data?.fullBar && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>fullBar</span>
                                    </button>
                                )}

                                {data?.fullRestaurant && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>fullRestaurant</span>
                                    </button>
                                )}
                            </div>
                            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
                                {data?.liveDJ && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>liveDJ</span>
                                    </button>
                                )}
                                {data?.liveMusic && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>liveMusic</span>
                                    </button>
                                )}
                                {data?.nightClub && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>nightClub</span>
                                    </button>
                                )}
                                {data?.outdoor && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>outdoor</span>
                                    </button>
                                )}
                                {data?.pool && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>pool</span>
                                    </button>
                                )}
                            </div>
                            <div className="flex gap-2 flex-wrap lg:flex-nowrap">
                                {data?.rooftop && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>rooftop</span>
                                    </button>
                                )}
                                {data?.smokingArea && (
                                    <button className="border border-[#43454D] bg-transparent text-[#EBEBEB] rounded-lg text-sm text-center leading-tight py-2 px-2.5 flex justify-center items-center uppercase">
                                        <span>smokingArea</span>
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="p-3 flex gap-2 pt-2 flex-wrap lg:flex-nowrap flex-col">
                            <Link
                                target="blank"
                                to={`https://www.google.com/maps/dir//${data?.address}`}>
                                <p className="text-slate-400 flex items-center gap-2">
                                    <FaLocationArrow /> {data?.address}
                                </p>
                            </Link>
                            <Link
                                to={data?.phoneNumber as string}
                                className="text-slate-400 flex items-center gap-2"
                                target="_blank">
                                <FaPhone /> <span> {data?.phoneNumber}</span>
                            </Link>
                            <div className="flex gap-2 mt-4">
                                <Link
                                    to={data?.email as string}
                                    className="text-slate-400 flex items-center gap-2"
                                    target="_blank">
                                    <FaEnvelope />
                                </Link>
                                {data?.facebook && (
                                    <Link
                                        to={data?.facebook as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaFacebook size={20} />
                                    </Link>
                                )}
                                {data?.linkedIn && (
                                    <Link
                                        to={data?.linkedIn as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaLinkedin size={20} />
                                    </Link>
                                )}
                                {data?.discord && (
                                    <Link
                                        to={data?.discord as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaDiscord size={20} />
                                    </Link>
                                )}
                                {data?.tiktok && (
                                    <Link
                                        to={data?.tiktok as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaTiktok size={20} />
                                    </Link>
                                )}
                                {data?.instagram && (
                                    <Link
                                        to={data?.instagram as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaInstagram size={20} />
                                    </Link>
                                )}
                                {data?.telegram && (
                                    <Link
                                        to={data?.telegram as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaTelegram size={20} />
                                    </Link>
                                )}
                                {data?.website && (
                                    <Link
                                        to={data?.website as string}
                                        className="text-slate-400"
                                        target="_blank">
                                        <FaGlobe size={20} />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
