import { useLocation } from "react-router-dom";
import { EventsIcon } from "../../assets/icons";
import { VenuesIcon } from "../../assets/icons";
import { UserIcon } from "../../assets/icons";
import { Link } from "react-router-dom";
import { FaCompactDisc } from "react-icons/fa";
import slugify from "slugify";

export default function PhoneTabs() {
    const location = useLocation();
    const pathname = location.pathname;
    const city = slugify(localStorage.getItem("city") || "las-vegas", { lower: true });

    // Array of tabs
    const tabsData = [
        {
            icon: ({ color }: { color: string }) => <EventsIcon fill={color} />,
            label: "Events",
            link: `/${city}/events`,
        },
        {
            icon: ({ color }: { color: string }) => <VenuesIcon fill={color} />,
            label: "Venues",
            link: `/${city}/venues`,
        },
        {
            icon: ({ color }: { color: string }) => (
                <FaCompactDisc
                    fill={color}
                    size={24}
                />
            ),
            label: "Artists",
            link: "/artists",
        },
        {
            icon: ({ color }: { color: string }) => <UserIcon fill={color} />,
            label: "Log In",
            link: "/login",
        },
    ];

    return (
        <div className="lg:hidden bg-[#0A0D16] fixed bottom-0 w-full z-50">
            {/* Mobile Button Tabs Section Start */}
            <div className="flex items-center justify-around">
                {/* Map over the array to render each tab */}
                {tabsData.map((tab, index) => (
                    <Link
                        key={index}
                        to={tab.link}
                        className="flex items-center flex-col py-2">
                        {tab.icon({ color: pathname === tab.link ? "#1363FF" : "#BDCAE8" })}

                        <span className="mt-1 text-white">{tab.label}</span>
                    </Link>
                ))}
            </div>
            {/* Mobile Button Tabs Section End */}
        </div>
    );
}
