export const musicGenres = [
    { value: "pop", label: "Pop" },
    { value: "rock", label: "Rock" },
    { value: "hiphop", label: "Hip-hop/Rap" },
    { value: "jazz", label: "Jazz" },
    { value: "blues", label: "Blues" },
    { value: "country", label: "Country" },
    { value: "electronic", label: "Electronic" },
    { value: "rnb", label: "R&B (Rhythm and Blues)" },
    { value: "reggae", label: "Reggae" },
    { value: "classical", label: "Classical" },
    { value: "folk", label: "Folk" },
    { value: "indie", label: "Indie" },
    { value: "metal", label: "Metal" },
    { value: "punk", label: "Punk" },
    { value: "funk", label: "Funk" },
    { value: "soul", label: "Soul" },
    { value: "gospel", label: "Gospel" },
    { value: "edm", label: "EDM (Electronic Dance Music)" },
    { value: "techno", label: "Techno" },
    { value: "house", label: "House" },
    { value: "dubstep", label: "Dubstep" },
    { value: "trap", label: "Trap" },
    { value: "ska", label: "Ska" },
    { value: "worldmusic", label: "World Music" },
    { value: "experimental", label: "Experimental" },
    { value: "ambient", label: "Ambient" },
    { value: "reggaeton", label: "Reggaeton" },
    { value: "latin", label: "Latin" },
    { value: "kpop", label: "K-pop" },
    { value: "jpop", label: "J-pop" },
    { value: "alternative", label: "Alternative" },
    { value: "grunge", label: "Grunge" },
    { value: "hardrock", label: "Hard Rock" },
    { value: "psychedelic", label: "Psychedelic" },
    { value: "industrial", label: "Industrial" },
    { value: "disco", label: "Disco" },
    { value: "dancehall", label: "Dancehall" },
    { value: "bluegrass", label: "Bluegrass" },
    { value: "americana", label: "Americana" },
    { value: "classicalcrossover", label: "Classical Crossover" },
    { value: "baroque", label: "Baroque" },
    { value: "celtic", label: "Celtic" },
    { value: "mariachi", label: "Mariachi" },
    { value: "salsa", label: "Salsa" },
    { value: "bossanova", label: "Bossa Nova" },
    { value: "flamenco", label: "Flamenco" },
    { value: "afrobeat", label: "Afrobeat" },
    { value: "garagerock", label: "Garage Rock" },
    { value: "shoegaze", label: "Shoegaze" },
    { value: "postrock", label: "Post-Rock" },
];
