import { truncate } from "lodash";
import moment from "moment";
import { IoIosArrowForward } from "react-icons/io";

export default function MobileCard({ event }: { event: any }) {
    return (
        <div className="w-full">
            <div 
            
            style={{
              backgroundImage: `url(${event.images[0]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
            className="xl:h-[150px] h-auto relative overflow-hidden rounded-lg shadow-customClass">
                <div className="w-full flex relative items-center gap-3">
                    <div className="absolute">
                        <img
                            src={event.images[0]}
                            alt="venue"
                            className="rounded-l-lg object-cover w-[110px] lg:w-[130px] xl:w-[160px]
                        h-[200px]  lg:h-[150px] xl:h-[150px]"
                        />
                    </div>
                    <div className="flex flex-col xl:pl-[167px] lg:pl-[130px] pl-[111px]">
                        <div className="flex flex-col gap-2 text-white p-2  w-full">
                            <p className="lg:text-base text-sm font-normal">{event.displayName}</p>
                            <p className="text-xs font-light">
                                {truncate(event.description, {
                                    length: 50,
                                    omission: "...",
                                })}
                            </p>
                        </div>
                        <div className="flex p-2 flex-wrap lg:flex-nowrap">
                            <p className="text-xs w-40 font-medium lg:font-bold text-[#8D919B]">{moment(event.date).format("MMM DD YYYY hh:mm a")}</p>
                        </div>
                        <div className="flex gap-2 p-2 items-center px-2">
                            <div className=" flex gap-1 flex-wrap lg:flex-nowrap items-center min-w-[14.1rem]">
                                <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">Guest List</button>
                                <button className={`$ w-[56.38px] bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center`}>
                                    <span>Tickets</span>
                                </button>
                                <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                    <span>VIP Tables</span>
                                </button>
                            </div>
                            <p className="hidden xl:flex text-sm text-white">
                                <IoIosArrowForward className="w-[24px] h-[24px]" />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
