import { FirebaseStorage, IEvents } from "../lib/firebase/storage";
import { create } from "zustand";

interface UserStore {
    filters: [];
    setFilters: (filters: any) => void;
    events: IEvents[];
    filterEvents: (city: string, filters: any) => void;
    getInitialEvents: (city: string, timestam: number) => void;
    isLoading: boolean;
}
export const useEventStore = create<UserStore>((set) => ({
    filters: [],
    setFilters: (filters) => set({ filters }),
    events: [],
    isLoading: false,
    filterEvents: async (city: string, filters: any) => {
        set({ isLoading: true });
        set({ filters });
        let timestamp = new Date().getTime();
        const events = await FirebaseStorage.getEvents(city, timestamp, filters);
        set({ events, isLoading: false });
    },
    getInitialEvents: async (city: string, timestamp: number) => {
        set({ isLoading: true });
        const response = await FirebaseStorage.getEvents(city, timestamp, []);
        set({ events: response, isLoading: false });
    },
}));
