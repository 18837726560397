import { useQuery } from "react-query";
import EventsInputsFields from "../components/ArtistForm";
import { useAuth } from "../providers/Authentication";
import { FirebaseStorage } from "../lib/firebase/storage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function ArtistForm() {
    const { user } = useAuth();
    const nav = useNavigate();
    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    useEffect(() => {
        if (!data?.roles?.includes("SiteAdmin")) {
            nav("/profile");
        }
    }, [data, nav]);
    return (
        <div className=" bg-[rgb(10,13,22)]">
            <EventsInputsFields />
        </div>
    );
}
