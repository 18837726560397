import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { Artist, FirebaseStorage } from "../lib/firebase/storage";
import DetailsImageArtists from "../components/comevent/venuesDetail/DetailsImageArtists";
import UpcomingEventsArtists from "../components/comevent/venuesDetail/UpcomingEventsArtists";
import ArtistsDetailSkeleton from "../components/ArtistsDetailSkeleton";
import { useEffect } from "react";

export default function VenuesDetails() {
    const params = useParams<{ slug: string }>();
    const { data, isLoading, isError, refetch } = useQuery({ queryKey: "artist", queryFn: () => FirebaseStorage.getArtist(params.slug as string), enabled: !!params.slug });
    useEffect(() => {
        refetch();
    }, [params.slug, refetch]);
    if (isLoading)
        return (
            <div>
                <ArtistsDetailSkeleton />
            </div>
        );
    if (isError) return <div>Error...</div>;
    return (
        <div className="w-full min-h-screen relative">
            <div
                className="hidden w-full lg:flex brightness-50 fixed top-0 left-0 z-[1] opacity-25 min-h-[27rem] bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${data?.photoURL})` }}
            />
            <div
                className="lg:hidden w-full flex brightness-50 fixed top-0 left-0 z-[1] opacity-25 min-h-[18rem] bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${data?.photoURL})` }}
            />
            <div className="w-full h-[100%] bg-[#0A0D16] fixed top-0 left-0"></div>
            <div className="w-full flex flex-col absolute z-10 right-0">
                <DetailsImageArtists data={data as Artist} />
                <UpcomingEventsArtists data={data as Artist} />
            </div>
        </div>
    );
}
