import { FaRegMessage } from "react-icons/fa6";
import { IoMailOutline } from "react-icons/io5";

export default function Resources() {
    return (
        <div className="bg-[#020418] min-h-screen">
            <p className="text-3xl mb-3 pt-10 text-center">
                <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Contact Us</span>
            </p>
            <div className="flex items-center flex-col justify-center gap-5">
                <div className="flex flex-col md:flex-row items-center gap-5">
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <div className="modal-background bg-opacity-80 absolute w-full h-full" />
                        <div className="bg-[#131624] text-white mt-2 rounded-lg p-20 px-32 relative">
                            <p className="text-center text-7xl text-white font-bold">Nightlife Systems</p>
                            <div className="flex flex-col gap-y-2 mt-5">
                                <div className="flex items-center gap-1">
                                    <FaRegMessage />
                                    <a href="tel:+19162222444">+1(916)222-2444</a>
                                </div>
                                <div className="flex items-center gap-1">
                                    <IoMailOutline />
                                    <a href="mailto:NightLife@NightLifeSystem.com">NightLife@NightLifeSystem.com</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
