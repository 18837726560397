import { useQuery } from "react-query";
import Pagination from "../components/Pagination/Pagination";
import PaginationDropdownList from "../components/PaginationDropdownList/PaginationDropdownList";
import { Link } from "react-router-dom";
import { FirebaseStorage } from "../lib/firebase/storage";
import ArtistsLoading from "../loading/Artists";
export default function ArtistsPage() {
    const { data, isLoading, isError } = useQuery("artists", async () => {
        return await FirebaseStorage.getArtists(1);
    });

    if (isLoading) return <ArtistsLoading />;
    if (isError) return <div>Error...</div>;

    return (
        <div className="bg-[#0A0D16] min-h-screen">
            <div className="max-w-screen-xl mx-auto py-10">
                <div className="px-4 lg:px-0 flex items-center justify-between text-3xl text-center lg:text-left lg:ml-10">
                    <span className="hidden lg:inline-block style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Artists</span>
                    <PaginationDropdownList />
                </div>
                <div className="flex flex-wrap justify-center gap-3 mt-10">
                    {Array.isArray(data) &&
                        data.map((artist) => (
                            <Link
                                key={artist.id}
                                to={`/artists/${artist.id}`}
                                className="shadow-customClass rounded-full shadow-lg">
                                <div className="relative w-40 h-40 lg:w-52 lg:h-52 xl:w-48 xl:h-48">
                                    <img
                                        src={artist.photoURL}
                                        alt={artist.photoURL}
                                        className="rounded-full opacity-50 object-cover w-40 h-40 lg:w-52 lg:h-52 xl:w-48 xl:h-48"
                                    />
                                    <div className="absolute top-0 left-0 h-full w-full flex justify-center items-center">
                                        <span className="text-white font-normal text-xs lg:text-xl">{artist.displayName}</span>
                                    </div>
                                </div>
                            </Link>
                        ))}
                </div>
                <Pagination />
            </div>
        </div>
    );
}
