import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import "./lib/firebase/auth";
import Footer from "./components/Footer/Footer";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./lib/queryClient";
import PhoneTabs from "./components/PhoneTabs/PhoneTabs";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/">
        <App />
        <Footer />
        <PhoneTabs />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);
