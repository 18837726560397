import TextInput from "../components/TextInput/TextInput";
import * as yup from "yup";
import { useFormik } from "formik";
import ProfileHeader from "../pages/Profile/header";
import Select from "react-select";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { FirebaseStorage } from "../lib/firebase/storage";
import { useAuth } from "../providers/Authentication";
import { FileUploader } from "react-drag-drop-files";
import "react-country-state-city/dist/react-country-state-city.css";
import "@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { toast } from "react-toastify";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useNavigate } from "react-router-dom";

const fileTypes = ["JPG", "PNG", "JPEG"];

const validationSchema = yup.object().shape({
    artistdj: yup.array().of(yup.string()).required("Artist/DJ is required"),
    startingDates: yup.array().of(yup.date()).required("Starting Dates is required"),
    displayName: yup.string().required("Event Name is required"),
    eventFB: yup.string().url("Invalid URL").default("https://www.facebook.com/"),
    eventInsta: yup.string().url("Invalid URL").default("https://www.instagram.com"),
    guestList: yup.string().url("Invalid URL").default("https://www.google.com/"),
    tickets: yup.string().url("Invalid URL").default("https://www.google.com/"),
    vipTable: yup.string().url("Invalid URL").default("https://www.google.com/"),
    price: yup.number().positive(),
    vanue: yup.string().required("Vanue is required"),
    description: yup.string().required("Description is required"),
});

export default function EventCreatingForm() {
    const { user } = useAuth();
    const navigate = useNavigate();
    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    const [dates] = useState(
        [new Date()].map((number: any) =>
            new DateObject().set({
                day: number,
                hour: number,
                minute: number,
                second: number,
            })
        )
    );
    const [file, setFile] = useState<File[] | null>(null);
    const handleFileChange = (file: File[]) => {
        setFile(file);
    };
    const artists = useQuery({
        queryKey: "adminArtists",
        queryFn: () => FirebaseStorage.getArtistsByAdminId(user.uid),
    });
    const vanues = useQuery({
        queryKey: "adminVanues",
        queryFn: () => FirebaseStorage.getVanuesByAdminId(user.uid),
    });
    let initialValues = {
        artistdj: "",
        displayName: "",
        eventFB: "",
        eventInsta: "",
        guestList: "",
        tickets: "",
        vipTable: "",
        price: "",
        vanue: "",
        startingDates: [new Date(), new Date()].map((number: any) =>
            new DateObject().set({
                day: number,
                hour: number,
                minute: number,
                second: number,
            })
        ),
        description: "",
    };
    const { setValues, handleChange, values, handleSubmit, errors, touched } = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit(values, formikHelper) {
            if (!file) {
                toast.error("Please select an image");
                return;
            }
            FirebaseStorage.createEvent({ ...values, files: file }, formikHelper.resetForm);
        },
    });

    useEffect(() => {
        if (!data?.roles?.includes("SiteAdmin")) {
            navigate("/profile");
        }
    }, [data, navigate]);
    return (
        <div className="bg-[#0A0D16]">
            <div className="w-full lg:max-w-screen-xl lg:mx-auto pb-20">
                <ProfileHeader />
                <form
                    onSubmit={handleSubmit}
                    className="flex flex-col lg:flex-row gap-5 pt-10 mx-5 lg:ml-5">
                    <div className="lg:w-6/12">
                        <div className="flex md:items-center w-full gap-2 mb-5">
                            <TextInput
                                id=""
                                type="checkbox"
                                placeholder="http://"
                                className="w-5 h-5"
                            />
                            <label
                                htmlFor=""
                                className="md:mt-2 text-white text-md lg:text-lg block mb-2">
                                {`Artis/Dj is the event (Copies Artist info, Can't edit disabled)`}
                            </label>
                        </div>
                        <div className="grid grid-cols-2 items-center gap-2">
                            <div>
                                <label
                                    htmlFor=""
                                    className="text-white text-lg">
                                    {`Artist/DJ`}
                                </label>
                                {artists.isLoading ? (
                                    <span>Loading...</span>
                                ) : (
                                    <Select
                                        options={artists.data?.map((artist) => ({
                                            value: artist.id,
                                            label: artist.displayName,
                                        }))}
                                        isMulti
                                        placeholder="Select Artist/DJ"
                                        className="w-full"
                                        onChange={(e) => {
                                            //@ts-ignore
                                            setValues({ ...values, artistdj: e.map((artist) => artist.value) });
                                        }}
                                        styles={{
                                            control(base, props) {
                                                return {
                                                    ...base,
                                                    border: "1px solid #FFFFFF4D",
                                                    backgroundColor: "#000000",
                                                    color: "white",
                                                    borderRadius: "6px",
                                                    padding: "2px",
                                                    "&:hover": {
                                                        border: "1px solid #FFFFFF4D",
                                                    },
                                                };
                                            },
                                            input(base, props) {
                                                return {
                                                    ...base,
                                                    color: "white",
                                                };
                                            },
                                            placeholder(base, props) {
                                                return {
                                                    ...base,
                                                    color: "white",
                                                };
                                            },
                                            menuList(base, props) {
                                                return {
                                                    ...base,
                                                    backgroundColor: "#2B2D3C",
                                                    color: "white",
                                                };
                                            },
                                        }}
                                    />
                                )}
                            </div>
                            {/* venue select input */}
                            <div>
                                <label
                                    htmlFor=""
                                    className=" text-white text-lg block">
                                    Venue
                                </label>
                                {vanues.isLoading ? (
                                    <span>Loading...</span>
                                ) : (
                                    <Select
                                        options={vanues.data?.map((venue) => ({
                                            value: venue.id,
                                            label: venue.displayName,
                                        }))}
                                        placeholder="Select vanue"
                                        className="w-full"
                                        onChange={(e) => {
                                            //@ts-ignore
                                            setValues({ ...values, vanue: e.value });
                                        }}
                                        styles={{
                                            control(base, props) {
                                                return {
                                                    ...base,
                                                    border: "1px solid #FFFFFF4D",
                                                    backgroundColor: "#000000",
                                                    color: "white",
                                                    borderRadius: "6px",
                                                    padding: "2px",
                                                    "&:hover": {
                                                        border: "1px solid #FFFFFF4D",
                                                    },
                                                };
                                            },
                                            input(base, props) {
                                                return {
                                                    ...base,
                                                    color: "white",
                                                };
                                            },
                                            placeholder(base, props) {
                                                return {
                                                    ...base,
                                                    color: "white",
                                                };
                                            },
                                            menuList(base, props) {
                                                return {
                                                    ...base,
                                                    backgroundColor: "#2B2D3C",
                                                    color: "white",
                                                };
                                            },
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-1 items-center gap-2">
                            <div className="mt-2 mb-4">
                                <label
                                    htmlFor="eventDate"
                                    className="text-white text-lg block mb-2">
                                    Event Dates
                                </label>
                                <DatePicker
                                    multiple
                                    value={dates}
                                    onChange={(selectedDates: any) => {
                                        // setDates(selectedDates);
                                        //@ts-ignore
                                        setValues({ ...values, startingDates: selectedDates });
                                    }}
                                    minDate={new Date()}
                                    format="MM/DD/YYYY HH:mm:ss"
                                    plugins={[
                                        <TimePicker
                                            position="bottom"
                                            hStep={2}
                                            mStep={3}
                                        />,
                                        <DatePanel markFocused />,
                                    ]}
                                />
                                {/* <DateTimeRangePicker
                                    onChange={(e) => {
                                        //@ts-ignore
                                        setValues({ ...values, startingDates: e });
                                    }}
                                    value={values.startingDates as any}
                                    className={"text-white w-full"}
                                    minDate={new Date()}
                                    calendarClassName={"text-white"}
                                /> */}

                                {touched.startingDates && errors.startingDates && <p className="text-red-400 text-sm mt-1">Event Date is required.</p>}
                            </div>
                        </div>
                        {/* event name and age limitations select inputs */}
                        <div className="grid grid-cols-2 items-center gap-2">
                            {/* event name input */}
                            <div>
                                <label
                                    htmlFor="eventName"
                                    className="mt-1 text-white text-lg block mb-2">
                                    Event name
                                </label>
                                <TextInput
                                    id="eventName"
                                    type="text"
                                    placeholder="Event Name"
                                    className={`border py-[9px] ${touched.displayName && errors.displayName ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.displayName && errors.displayName ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.displayName && errors.displayName ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.displayName}
                                    name="displayName"
                                    onChange={handleChange}
                                />
                                {touched.displayName && errors.displayName && <p className="text-red-400 text-sm mt-1">{errors.displayName}</p>}
                            </div>
                            <div className="">
                                <label
                                    htmlFor="price"
                                    className=" text-white text-lg block mb-2">
                                    Price
                                </label>
                                <div className="flex items-center gap-6">
                                    <TextInput
                                        id="price"
                                        type="number"
                                        placeholder="EX 10"
                                        className={`border ${touched.price && errors.price ? "border-red-400" : "border-white/30"} p-[9px] rounded w-full bg-transparent ${
                                            touched.price && errors.price ? "active:border-red-400" : "active:border-white/40"
                                        } ${touched.price && errors.price ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                        value={values.price}
                                        name="price"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="mt-3">
                            <label
                                htmlFor="eventFB"
                                className="mt-2 text-white text-lg block mb-2">
                                Event FB
                            </label>
                            <div className="flex items-center gap-2">
                                <TextInput
                                    id="eventFB"
                                    type="text"
                                    placeholder="http://"
                                    className={`border ${touched.eventFB && errors.eventFB ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.eventFB && errors.eventFB ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.eventFB && errors.eventFB ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.eventFB}
                                    name="eventFB"
                                    onChange={handleChange}
                                />
                                <button
                                    type="button"
                                    className="text-white text-md w-36 py-[9px] rounded-md border border-white/30">
                                    Copy details
                                </button>
                            </div>
                            {touched.eventFB && errors.eventFB && <p className="text-red-400 text-sm mt-1">{errors.eventFB}</p>}
                        </div>
                        {/* event insta input */}
                        <div className="mt-3">
                            <label
                                htmlFor="eventInsta"
                                className="mt-2 text-white text-lg block mb-2">
                                Event Insta
                            </label>
                            <div className="flex items-center gap-2">
                                <TextInput
                                    id="eventInsta"
                                    type="text"
                                    placeholder="http://"
                                    className={`border ${touched.eventInsta && errors.eventInsta ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.eventInsta && errors.eventInsta ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.eventInsta && errors.eventInsta ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.eventInsta}
                                    name="eventInsta"
                                    onChange={handleChange}
                                />

                                <button
                                    type="button"
                                    className="text-white text-md w-36 py-[9px] rounded-md border border-white/30">
                                    Copy details
                                </button>
                            </div>
                            {touched.eventInsta && errors.eventInsta && <p className="text-red-400 text-sm mt-1">{errors.eventInsta}</p>}
                        </div>
                        {/* event guest input */}
                        <div className="mt-3">
                            <label
                                htmlFor="firstName"
                                className="mt-2 text-white text-lg block mb-2">
                                Guest List
                            </label>
                            <div className="flex items-center gap-6">
                                <TextInput
                                    id="guestList"
                                    type="text"
                                    placeholder="http://"
                                    disabled
                                    className={`border ${touched.guestList && errors.guestList ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.guestList && errors.guestList ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.guestList && errors.guestList ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.guestList}
                                    name="guestList"
                                    onChange={handleChange}
                                />
                                <div className="flex items-center flex-row-reverse gap-2">
                                    <label
                                        htmlFor="guestList"
                                        className="mt-2 w-14 text-white text-lg block mb-2">
                                        Built in
                                    </label>
                                    <TextInput
                                        id="guestList"
                                        type="checkbox"
                                        placeholder="http://"
                                        className="w-5 h-5"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* tickets input */}
                        <div className="mt-3">
                            <label
                                htmlFor="tickets"
                                className="mt-2 text-white text-lg block mb-2">
                                Tickets
                            </label>
                            <div className="flex items-center gap-6">
                                <TextInput
                                    id="tickets"
                                    type="text"
                                    placeholder="http://"
                                    disabled
                                    className={`border ${touched.tickets && errors.tickets ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        touched.tickets && errors.tickets ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.tickets && errors.tickets ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.tickets}
                                    name="tickets"
                                    onChange={handleChange}
                                />
                                <div className="flex items-center flex-row-reverse gap-2">
                                    <label
                                        htmlFor="tickets"
                                        className="mt-2 w-14 text-white text-lg block mb-2">
                                        Built in
                                    </label>
                                    <TextInput
                                        disabled
                                        id="tickets"
                                        type="checkbox"
                                        placeholder="http://"
                                        className="w-5 h-5"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* vip table input */}
                        <div className="mt-3">
                            <label
                                htmlFor="vipTable"
                                className="mt-2 text-white text-lg block mb-2">
                                VIP Table
                            </label>
                            <div className="flex items-center gap-6">
                                <TextInput
                                    id="vipTable"
                                    type="text"
                                    placeholder="http://"
                                    disabled
                                    className={`border ${errors.vipTable ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                        errors.vipTable ? "active:border-red-400" : "active:border-white/40"
                                    } ${errors.vipTable ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    value={values.vipTable}
                                    name="vipTable"
                                    onChange={handleChange}
                                />
                                <div className="flex items-center flex-row-reverse gap-2">
                                    <label
                                        htmlFor="vipTable"
                                        className="mt-2 w-14 text-white text-lg block mb-2">
                                        Built in
                                    </label>
                                    <TextInput
                                        id="vipTable"
                                        type="checkbox"
                                        placeholder="http://"
                                        className="w-5 h-5"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* dress code input and price input */}
                        <div className="grid grid-cols-2 items-center gap-2"></div>
                    </div>
                    {/* /// right side /// */}
                    <div className="w-full lg:w-6/12 text-white flex flex-col gap-5">
                        <div className="flex flex-col gap-2 ">
                            <label
                                htmlFor="description"
                                className="mt-2 text-lg">
                                Description
                            </label>
                            <textarea
                                name="description"
                                cols={30}
                                rows={5}
                                value={values.description}
                                onChange={handleChange}
                                className="border border-white/30 bg-transparent p-3 rounded active:outline-none focus:outline-none"
                            />
                            {touched.description && errors.description && <p className="text-red-400 text-sm mt-1">{errors.description}</p>}
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 border border-white/30 text-center text-2xl">
                            <FileUploader
                                handleChange={handleFileChange}
                                name="file"
                                types={fileTypes}
                                multiple={true}
                            />
                        </div>
                        <div className="flex items-end justify-end my-5 mx-5 lg:mx-0 lg:mr-5">
                            <button
                                type="submit"
                                className="w-full md:w-auto px-10 py-5 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none transition-colors">
                                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}
