import { useEffect } from "react";
import { Sidebar, EventListCard } from "../components/comevent/components";
import { useLocation } from "react-router-dom";
import { useEventStore } from "../state/eventStore";
import { split } from "lodash";

export default function Events() {
    const { getInitialEvents, events, isLoading } = useEventStore();
    const { pathname } = useLocation();
    const city = split(pathname, "/")[1];
    useEffect(() => {
        if (city) {
            getInitialEvents(city, new Date().getTime());
        }
    }, [city, getInitialEvents]);

    return (
        <div className="w-full bg-[#0A0D16] flex lg:gap-7 gap-0">
            <div className="hidden lg:block w-full lg:w-2/12 bg-[#0A0D16]">
                <Sidebar />
            </div>
            <div className="w-full 2xl:w-10/12 xl:w-10/12 lg:w-10/12">
                <EventListCard
                    loading={isLoading}
                    events={events}
                />
            </div>
        </div>
    );
}
