import { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import LogoNoBgForDesktop from "../../assets/logo-no-bg-ForDesktop.png";
import { Link, useLocation } from "react-router-dom";
import { artistsClient, eventClient, venueClient } from "../../lib/algolia";
import _ from "lodash";
import { IoChevronForward } from "react-icons/io5";
import SearchLoading from "../SearchLoading/SearchLoading";
const NavbarForMobile = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const location = useLocation();
    const city = location.pathname.split("/")[1] || "las-vegas";
    const [searchResultArtists, setSearchResultArtists] = useState<any>([]);
    const [searchResultVanues, setSearchResultVanues] = useState<any>([]);
    const [searchResultEvents, setSearchResultEvents] = useState<any>([]);
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);
    const [searching, setSearching] = useState(false);
    const searchRef = useRef<HTMLInputElement | null>(null);

    const searchPopOpen = () => {
        setIsSearchPopupOpen(!isSearchPopupOpen);
    };

    const delayedSearch = _.debounce(async (term) => {
        let promises = [artistsClient.search(term), eventClient.search(term), venueClient.search(term)];
        try {
            setSearching(true);
            setTimeout(() => {
                setSearching(false);
            }, 10000);
            let results = await Promise.all(promises);
            setSearchResultArtists(results[0].hits as any);
            setSearchResultVanues(results[2].hits as any);
            setSearchResultEvents(results[1].hits as any);
            setSearching(false);
        } catch (error) {
            console.log({ error });
            setSearching(false);
        }
    }, 500);

    useEffect(() => {
        delayedSearch(searchQuery);
        return delayedSearch.cancel;
    }, [searchQuery, delayedSearch]);

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value);
    };
    const handleClose = () => {
        setSearchQuery("");
        setIsSearchPopupOpen(false);
    };
    return (
        <nav className="">
            <div className="flex items-center justify-between">
                <Link to="/">
                    <img
                        src={LogoNoBgForDesktop}
                        alt="logo"
                        className="cursor-pointer w-32 h-auto lg:w-full"
                    />
                </Link>

                {/* Buttons */}
                <div className="flex items-center gap-3">
                    <button className="hidden relative lg:flex items-center gap-1 bg-primary text-white font-medium px-4 py-4 rounded-md group overflow-hidden transition-transform">
                        <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-0"></span>
                        <span className="text-sm z-50">Use App</span>
                    </button>
                    <div
                        onClick={searchPopOpen}
                        className="p-3 border border-white/30 rounded-md cursor-pointer">
                        <FiSearch className="w-5 h-5" />
                    </div>
                </div>
            </div>

            {/* Search Popup */}
            {isSearchPopupOpen && (
                <div className="fixed inset-0 bg-[rgba(26,26,26,0.9)] flex items-center justify-center z-50">
                    <div className="bg-[#1A1A1A] w-full h-full max-w-screen-sm p-6 rounded-md relative">
                        <div className="flex items-center justify-between mb-5">
                            <div className="text-2xl font-semibold text-center w-full">Search</div>
                            <div onClick={() => setIsSearchPopupOpen(false)}>
                                <IoMdClose className="w-8 h-8 cursor-pointer" />
                            </div>
                        </div>
                        <div className="px-3">
                            <div className="relative">
                                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5" />
                                <input
                                    ref={searchRef}
                                    type="text"
                                    value={searchQuery}
                                    onChange={handleChange}
                                    placeholder="Search"
                                    className="w-full bg-[#242A3A] shadow-inner shadow-[#131620] lg:w-[18rem] h-[38px] pl-10 px-4 py-2 rounded-lg focus:outline-none hover:shadow-xl focus:border focus:border-[#131620]"
                                />
                            </div>
                            <div className="text-center text-white/80 text-sm mt-1 bg-slate-800 z-30">
                                {searchQuery === "" ? (
                                    <p>Search here to find your favourite artists, venue, and event</p>
                                ) : (
                                    <div>
                                        {searching ? (
                                            <>
                                                <SearchLoading />
                                            </>
                                        ) : (
                                            <div>
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Artists</span>
                                                    </p>
                                                    <ul className="text-white">
                                                        {searchResultArtists.length === 0 ? (
                                                            <span className="my-2">No artists found</span>
                                                        ) : (
                                                            searchResultArtists.map((artists: any) => (
                                                                <Link
                                                                    onClick={handleClose}
                                                                    to={`/artists/${artists.objectID}`}
                                                                    className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                    <div className="flex items-center gap-3">
                                                                        <img
                                                                            src={artists.photoURL}
                                                                            alt={artists.displayName}
                                                                            className="rounded-full w-10 h-10 object-cover"
                                                                        />
                                                                        <div>{artists.displayName}</div>
                                                                    </div>
                                                                    <div>
                                                                        <IoChevronForward className="text-xl" />
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Venues</span>
                                                    </p>
                                                    <div>
                                                        {searchResultVanues.length === 0 ? (
                                                            <span className="my-2">No Vanue found</span>
                                                        ) : (
                                                            searchResultVanues.map((venues: any) => (
                                                                <Link
                                                                    onClick={handleClose}
                                                                    to={`/${venues.city}/venues/${venues.slug}`}
                                                                    className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                    <div className="flex items-center gap-3">
                                                                        <img
                                                                            src={venues.images[0]}
                                                                            alt={venues.displayName}
                                                                            className="rounded-full w-10 h-10 object-cover"
                                                                        />
                                                                        <div>{venues.displayName}</div>
                                                                    </div>
                                                                    <div>
                                                                        <IoChevronForward className="text-xl" />
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        )}
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Events</span>
                                                    </p>
                                                    <ul>
                                                        {searchResultEvents.length === 0 ? (
                                                            <span className="my-2">No Event Found</span>
                                                        ) : (
                                                            searchResultEvents.map((events: any) => (
                                                                <Link
                                                                    onClick={handleClose}
                                                                    to={`/${city}/${events.path}`}
                                                                    className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                    <div className="flex items-center gap-3">
                                                                        <img
                                                                            src={events.images[0]}
                                                                            alt={events.displayName}
                                                                            className="rounded-full w-10 h-10 object-cover"
                                                                        />
                                                                        <div>{events.displayName}</div>
                                                                    </div>
                                                                    <div>
                                                                        <IoChevronForward className="text-xl" />
                                                                    </div>
                                                                </Link>
                                                            ))
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavbarForMobile;
