import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { BsCalendar2 } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEventStore } from "../../../state/eventStore";

export default function CalendarSlider() {
    const { getInitialEvents } = useEventStore();
    const [showCalendar, setShowCalendar] = useState(false);
    const [calendarValue, setCalendarValue] = useState<Date>(new Date());
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const { pathname } = useLocation();
    const city = pathname.split("/")[1];

    const navigate = useNavigate();
    const [selectCalendarDate, setSelectCalendarDate] = useState<string>("Tonight" || "Today");
    const calendarRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    // if the pathname is /venues, return null

    const handleDivClick = (event: any) => {
        const isNavigationButtonClick =
            (event.target as HTMLElement).classList.contains("react-calendar__navigation__prev-button") || (event.target as HTMLElement).classList.contains("react-calendar__navigation__next-button");

        if (!isNavigationButtonClick) {
            setShowCalendar(!showCalendar);
        }
    };

    const isPastDayInJanuary = (date: Date) => {
        const today = new Date();
        const isJanuary = date.getMonth() === 0; // January is month 0
        const isPastDay = date < today;

        return isJanuary && isPastDay;
    };

    // Update tileDisabled to disable past days in January
    const tileDisabled = ({ date, view }: { date: Date; view: string }) => {
        if (view === "month" && isPastDayInJanuary(date)) {
            return true;
        }
        return false;
    };
    const handleCalendarChange = (value: any) => {
        setCalendarValue(value);
    };

    const handleDateClick = (value: Date) => {
        setSelectedDate(value);
        getInitialEvents(city, value.getTime());
        navigate(`${pathname}`, {
            state: {
                date: value.getTime(),
            },
        });
        const formattedDate = new Intl.DateTimeFormat("en-US", {
            month: "long",
            day: "numeric",
        }).format(value);

        setSelectCalendarDate(formattedDate);
        setShowCalendar(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (calendarRef.current && !calendarRef.current.contains(target) && !target.classList.contains("calendar-trigger")) {
            setShowCalendar(false);
        }
    };

    const handleResetDate = () => {
        setSelectedDate(null);
        setSelectCalendarDate("Today");
        setCalendarValue(new Date());
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        return () => {
            setSelectedDate(null);
        };
    }, []);

    if (location.pathname === "/venues") {
        return null;
    }
    return (
        <>
            <div
                onClick={handleDivClick}
                className={`${
                    location.pathname === "/venues/123" && "cursor-pointer w-full lg:w-[104px] h-auto lg:h-[45px] flex items-center justify-center  calendar-trigger bg-[hsla(0,0%,100%,.1)] transition"
                } border relative border-[#454750] lg:mt-0 mt-6
        hover:border-[#DCDDE1] rounded-lg w-full lg:w-[207px] 
        h-auto lg:h-[40px] text-white bg-[#1C1D22] p-3 flex
        items-center gap-2 calendar-trigger`}>
                <div className="flex items-center gap-2">
                    <span>
                        <BsCalendar2 />
                    </span>
                    <span>{location.pathname === "/venues/123" ? "Today" : selectCalendarDate}</span>
                </div>
                {showCalendar && (
                    <div
                        className="fixed z-50 top-[257px] left-5 bg-black shadow-md border border-white/20 rounded-md w-[320px]"
                        ref={calendarRef}>
                        <Calendar
                            className="react-calendar"
                            calendarType="US"
                            next2Label={null}
                            prev2Label={null}
                            value={calendarValue}
                            onChange={handleCalendarChange}
                            onClickDay={handleDateClick}
                            tileDisabled={tileDisabled}
                        />
                        {selectedDate && (
                            <button
                                onClick={handleResetDate}
                                className="my-3 ml-3 relative flex items-center gap-1 bg-tranparent text-white font-medium px-3 py-3 rounded-md group overflow-hidden">
                                <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-white/10 z-0"></span>
                                <span className="z-50">Clear Date</span>
                            </button>
                        )}
                    </div>
                )}
            </div>
        </>
    );
}
