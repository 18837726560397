export default function Terms() {
    return (
        <div className="bg-[#020308] h-screen">
            <div className="max-w-screen-xl mx-auto px-4 lg:px-50 pb-10 lg:p-7 ">
                <p className="text-3xl text-center lg:text-left">
                    <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Terms of Service</span>
                </p>
                {/* <div className="mt-10">x
          <p className="mb-4 leading-6 text-white/60">
            {`Welcome to Discotech! These Terms of Service (“Terms”) are a contract
          between you and Discotech Inc., a California corporation (“Discotech,”
          “we,” “us” or “our”), governing your access to and use of our mobile
          device application (the “App”), our website located at
          https://www.discotech.me (the “Site”), and any other services provided
          by Discotech. The App, Site, and related services are collectively
          referred to herein as the “Services.” Please read these Terms
          carefully before accessing and using our Services. If you do not agree
          to these Terms, do not access our Services.`}
          </p>

          <p className="mb-4 italic leading-6 text-white/60">
            {`ARBITRATION NOTICE: EXCEPT IF YOU OPT OUT USING THE PROCEDURE DESCRIBED IN THE “ARBITRATION AGREEMENT” SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND DISCOTECH WILL BE
                    RESOLVED BY BINDING, INDIVIDUAL ARBITRATION, AND YOU ARE WAIVING YOUR RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR
                    REPRESENTATIVE PROCEEDING.`}
          </p>

          <SectionHeading>1. SERVICES.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`Discotech is a platform that helps partygoers discover events at various nightlife venues (“Venue” or “Venues”). Discotech is not the owner of any Venue or creator of any of the
                    events listed on our Services. Rather, we focus on enhancing your nightlife experience by providing a platform to help you locate events, make table reservations, purchase event
                    tickets, and sign up for guest lists. We also provide content regarding nightlife culture, venues, and happenings.`}
          </p>

          <SectionHeading>2. USE.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {` By accessing and/or using our Services, you accept and agree to be bound by these Terms, just as if you had agreed to these Terms in writing. If you do not agree to these Terms, do
                    not use our Services. We reserve the right to amend these Terms from time to time. You are responsible for regularly reviewing these Terms. All amendments will be effective upon
                    the posting of such updated Terms. Your continued access to or use of our Services after such posting constitutes your consent to be bound by these Terms, as amended.`}
          </p>

          <SectionHeading>3. MOBILE DEVICE.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`You are responsible for any mobile device charges due to phone service, data access, and/or text messaging arising from your use of the App. You agree to follow all rules
                    established by your mobile device platform or cellular service provider.`}
          </p>

          <SectionHeading>
            4. ELIGIBILITY AND ACCOUNT INFORMATION.
          </SectionHeading>
          <SubSectionHeading>A. Eligibility.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            To ensure that we are providing a safe community for our users, we
            require users to meet certain eligibility requirements. By accessing
            our Site, you represent and warrant: (i) that you are eighteen (18)
            years of age or older and have the legal capacity to enter into
            these Terms; (ii) you have not been suspended or removed from our
            Services; and (iii) that you are in compliance at all times with all
            applicable laws and regulations.
          </p>
          <SubSectionHeading>B. Account Information.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            In order to access portions of our Services, including making table
            reservations or purchasing event tickets, you may need to register
            for an account. When you register for an account, you agree to
            provide us with complete, accurate and up-to-date information in
            your account profile. You further agree not to share or loan your
            account to others and to take all necessary security precautions to
            protect your account from unauthorized use. You assume all
            responsibility and liability stemming from the use of your account
            and assume all risks associated with any unauthorized access to your
            account.
          </p>
          <SectionHeading>5. THIRD PARTY LINKS.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            We may provide links or references to other websites or online
            resources, but we are not responsible for and do not endorse such
            websites or online resources. Please independently evaluate whether
            these resources are good for you - your use of such resources is
            solely at your own risk.
          </p>
          <SectionHeading>6. USER CONDUCT.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Users of our Services are encouraged to be respectful and abide by
            all applicable laws, rules and regulations. Failure to comply with
            the following rules will result in a termination of your access to
            our Services.
            <span className="mt-5">
              By accessing our Services, you agree not to:
            </span>
            <ul className="list-disc pl-8 lg:pl-10 mt-5 leading-7">
              <li className="text-white/80">
                Use the App or Site for unlawful purposes;
              </li>
              <li className="text-white/80">
                Impersonate any person or entity;
              </li>
              <li className="text-white/80">
                Make unsolicited offers, advertisements, proposals, or send junk
                mail or “spam” to users;
              </li>
              <li className="text-white/80">
                Pretend to be another person or access another user’s account;
              </li>
              <li className="text-white/80">
                Infringe upon any patent, trademark, trade secret, copyright or
                other proprietary right of any party while using our Services;
              </li>
              <li className="text-white/80">
                Misrepresent the source, identity, or content of information
                transmitted via the App or Site;
              </li>
              <li className="text-white/80">
                Take any action that would undermine any aspect of the App or
                Site;
              </li>
              <li className="text-white/80">
                Transmit to the App or Site any virus or other program that may
                damage or impair the use of our or our users’ hardware, software
                or equipment;
              </li>
              <li className="text-white/80">
                Take any action that would inhibit any other users’ enjoyment of
                the App or Site; or
              </li>
              <li className="text-white/80">
                Reproduce, duplicate, copy, sell, re-sell or exploit any
                information, materials or content on the App or Site.
              </li>
            </ul>
          </p>
          <SectionHeading>7. VENUE POLICIES.</SectionHeading>
          <SubSectionHeading>A. General.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            The events listed on our Services are owned and operated by our
            Venue partners. You understand that each Venue has different
            admission policies, including, but not limited to, rules regarding
            attire, gender ratio, guest list times and/or capacity limitations.
            For the best chance of admission, please refer to the information we
            send you in our booking confirmation. Ultimately, the Venue reserves
            the right to deny entry for any reason and Discotech has no
            involvement in that decision.
          </p>
          <SubSectionHeading>B. Guest List Access.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Discotech provides guest list reservations for certain nightlife
            events. If you use our Services to sign up for a guest list, you
            agree to provide your full legal name to us so that the Venue can
            verify your identity to access the nightclub. Guest lists are solely
            controlled by the Venue and as such, admission is in their sole
            discretion. Signing up for a guest list does not ensure that you
            will be able to access the Venue.
          </p>
          <SubSectionHeading>C. Table Reservations.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Discotech assists users with securing table reservations for certain
            nightlife events. If you use our Services to make a table
            reservation, you agree to provide your full legal name to us so that
            the Venue can verify your identity to access the nightclub. If you
            are making a table reservation, you represent and warrant that you
            are twenty-one (21) years of age or older. Table reservations are
            not guaranteed until accepted by the Venue, which is in their sole
            and absolute discretion. You agree to follow the Venue’s procedures
            for payment, including, but not limited to, pre-payment of a
            deposit, if necessary.
            <span className="mb-4 leading-6 text-white/60">
              If you booked a table but can no longer attend, please cancel the
              booking as soon as possible. No shows will result in table booking
              functionality being removed from your Discotech account. In the
              event that you had to make a deposit or pre-pay for the table
              booking, you agree to the terms of the Venue’s cancellation
              policy, which will be provided to you on the deposit or pre-pay
              form.
            </span>
          </p>
          <SubSectionHeading>D. Tickets Sales.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Discotech connects users to third party ticketing providers to
            purchase tickets for events. Since ticket sales are always done
            directly with the ticket provider, please refer to such ticket
            provider’s website for their individual cancellation and/or refund
            policy. If you have an issue with your ticket purchase, you agree to
            contact the ticket provider directly to resolve the issue.
          </p>
          <SectionHeading>8. INTELLECTUAL PROPERTY RIGHTS.</SectionHeading>
          <SubSectionHeading>
            A. Ownership of the Intellectual Property.
          </SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            The Site and App are owned and operated by Discotech. The Site and
            App and its entire contents, including visual interfaces, graphics,
            design, compilation, information, computer code, software, services,
            and all other elements of the App and Site provided by us (the
            “Materials”) are protected by United States and international
            copyright, trademark, patent, trade secret and other intellectual
            property or proprietary rights laws. You agree not to sell, license,
            distribute, copy, modify, publicly perform or display, transmit,
            publish, edit, adapt, create derivative works from, or otherwise
            make unauthorized use of the Materials.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            All trademarks, service marks, and trade names are proprietary to us
            and you agree not to use them without our written consent. If you
            create anything using our trademark, you agree that we exclusively
            own all right, title and interest in and to such materials,
            including without limitation any modifications or derivative work
            based off of the use of our trademark. You further agree to assign
            any interest or right you may have in such Materials to us, and to
            provide information and execute any documents to formalize such
            assignment.
          </p>
          <SubSectionHeading>B. Site License.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Subject to your compliance with these Terms, Discotech grants to you
            a limited, non-exclusive, non-transferable, non-sublicensable,
            revocable license to access and use the Site for your personal,
            non-commercial purposes.
          </p>
          <SubSectionHeading>C. App License.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Subject to your compliance with these Terms, Discotech grants you a
            limited, non-exclusive, non-transferable, non-sublicensable,
            revocable license to download and install a copy of the App onto
            your mobile device and to enjoy the copy of the App solely for your
            own personal, non-commercial purposes. Except as permitted by these
            Terms, you may not: (i) distribute, transfer, sublicense, decompile
            or disassemble the App, (ii) copy, modify or create derivative works
            based on the App or (iii) reverse engineer or disassemble the App.
            Discotech reserves all rights in and to the App not expressly
            granted to you under these Terms.
          </p>

          <SectionHeading>9. APP PROVIDER.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            In the event that you accessed or downloaded the App from an app
            store such as the Apple Store or Google Play (each, an “App
            Provider”), then you acknowledge to the following:
            <span className="mt-5">
              By accessing our Services, you agree not to:
            </span>
            <ul className="list-disc pl-8 lg:pl-10 mt-5 leading-7">
              <li className="text-white/80">
                These Terms are between you and us, and not with the App
                Provider;
              </li>
              <li className="text-white/80">
                App Provider has no obligation to furnish any support services
                or maintenance in regard to the App;
              </li>
              <li className="text-white/80">
                In the event of any failure of the App to conform to any
                applicable warranty, you may notify App Provider, and App
                Provider will refund the purchase price for the App to you (if
                applicable) and, to the maximum extent permitted by applicable
                law, App Provider will have no other warranty obligation
                whatsoever with respect to the App. Any other claims, losses,
                liabilities, damages, costs, or expenses attributable to any
                failure of an App to conform to any warranty will be the sole
                responsibility of Discotech;
              </li>
              <li className="text-white/80">
                In the event of any third party claim that the App or your
                possession and use of the App infringes upon that third party’s
                intellectual property rights, Discotech will be solely
                responsible for the investigation, defense, settlement, and
                discharge of any such intellectual property infringement claim
                to the extent required by these Terms;
              </li>
              <li className="text-white/80">
                App Provider is not responsible for any claims you have or any
                claims of any third party relating to the App or your possession
                and use of the App;
              </li>
              <li className="text-white/80">
                App Provider and its subsidiaries are third party beneficiaries
                of these Terms in relation to your licensed use of the App and
                will have the right to enforce these Terms against you as a
                third party beneficiary;
              </li>
              <li className="text-white/80">
                You agree to comply with all third party terms of service when
                using the App; and
              </li>
              <li className="text-white/80">
                You agree to comply with all U.S. and foreign export laws and
                regulations to ensure that neither the App nor any technical
                data related to the App is exported or re-exported directly or
                indirectly in violation of such laws and regulations. By
                accessing the App, you represent and warrant that (i) you are
                not located in a country subject to U.S. Government embargo,
                (ii) that has been designated by the U.S. Government as a
                “terrorist supporting” country, or (iii) you are not listed on
                any U.S. Government list of prohibited or restricted parties.
              </li>
            </ul>
          </p>

          <SectionHeading>10. INDEMNIFICATION.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`You agree to defend, indemnify and hold harmless Discotech, its
          independent contractors, service providers and consultants, and their
          respective directors, officers, employees and agents, from and against
          any suits, actions, proceedings, claims, damages, costs, fines,
          deficiencies, judgments, settlements, liabilities and expenses
          (including, but not limited to, reasonable attorneys' fees) arising
          out of, related to, attributable to or incidental to: (i) your use of
          our Services; (ii) your violation of these Terms; or (iii) your breach
          of any applicable local, state, national or other law, rule or
          regulation.`}
          </p>

          <SectionHeading>11. DISCLAIMER OF WARRANTIES.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`YOUR USE OF OUR SERVICES IS AT YOUR SOLE RISK UNLESS OTHERWISE EXPLICITLY STATED. OUR SERVICES, INCLUDING THE INFORMATION AND CONTENTS, ARE PROVIDED ON AN “AS IS” BASIS. DISCOTECH
                    DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. DISCOTECH MAKES NO WARRANTY OR REPRESENTATIONS THAT THE INFORMATION ON THE SITE OR
                    APP IS ACCURATE, RELIABLE OR ERROR-FREE.`}
          </p>

          <SectionHeading>
            12. LIMITATION OF LIABILITY AND ASSUMPTION OF RISK.
          </SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {` TO THE EXTENT PERMITTED BY APPLICABLE LAW, DISCOTECH WILL NOT BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING
                    PERSONAL INJURY, EMOTIONAL DISTRESS, LOST PROFITS, LOSS OF DATA, OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE, OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE SERVICES, ARISING
                    OUT OF OR IN CONNECTION WITH THESE TERMS, OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY,
                    OR ANY OTHER LEGAL THEORY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE FOREGOING LIMITATION OF
                    LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.`}
          </p>

          <SectionHeading>
            12. LIMITATION OF LIABILITY AND ASSUMPTION OF RISK.
          </SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            TO THE EXTENT PERMITTED BY APPLICABLE LAW, DISCOTECH WILL NOT BE
            LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE,
            EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING PERSONAL INJURY,
            EMOTIONAL DISTRESS, LOST PROFITS, LOSS OF DATA, OR GOODWILL, SERVICE
            INTERRUPTION, COMPUTER DAMAGE, OR SYSTEM FAILURE OR THE COST OF
            SUBSTITUTE SERVICES, ARISING OUT OF OR IN CONNECTION WITH THESE
            TERMS, OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER
            BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT
            LIABILITY, OR ANY OTHER LEGAL THEORY. SOME JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
            INCIDENTAL DAMAGES, SO THE FOREGOING LIMITATION OF LIABILITY SHALL
            APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
            JURISDICTION.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            YOUR SOLE AND EXCLUSIVE REMEDY FOR DISSATISFACTION WITH OUR SERVICES
            IS TO STOP USING THE SITE. OUR AGGREGATE LIABILITY ARISING OUT OF
            THESE TERMS OR THE USE OF OUR SERVICES WILL NOT EXCEED THE GREATER
            OF ONE HUNDRED DOLLARS ($100) OR THE AMOUNT YOU HAVE PAID US IN THE
            PAST TWELVE MONTHS.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            DISCOTECH IS A PLATFORM CONNECTING USERS WHO WANT TO ATTEND
            NIGHTLIFE EVENTS TO VENUES. HOWEVER, DISCOTECH IS NOT A PARTY TO ANY
            TRANSACTION BETWEEN USERS AND VENUES. AS SUCH, DISCOTECH HEREBY
            EXPRESSLY DISCLAIMS, AND YOU HEREBY EXPRESSLY RELEASE DISCOTECH
            FROM, ANY AND ALL LIABILITY WHATSOEVER FOR ANY CONTROVERSIES,
            CLAIMS, SUITS, INJURIES, LOSS, HARM OR DAMAGES ARISING FROM OR
            RELATED TO ANY INTERACTIONS BETWEEN YOU AND A VENUE, INCLUDING, BUT
            NOT LIMITED TO THE VENUE (I) CANCELING THE SCHEDULED EVENT, (II)
            CHANGING THE PERFORMER AND/OR TALENT FEATURED AT THE EVENT, (III)
            DENYING YOU ENTRANCE TO AN EVENT, (IV) REFUSING TO ACCOMMODATE A
            TABLE RESERVATION OR TICKET SALE, OR (V) VIOLATING ANY APPLICABLE
            STATE, FEDERAL OR LOCAL LAW.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            {`YOU ACKNOWLEDGE THAT CERTAIN EVENTS MAY CARRY INHERENT RISK AND BY
          PARTICIPATING IN SUCH AN EVENT, YOU ASSUME THOSE RISKS VOLUNTARILY.
          DISCOTECH IS IN NO WAY LIABLE FOR ACTIONS TAKEN BY USERS OR THIRD
          PARTIES AT AN EVENT, INCLUDING ANY LIABILITY STEMMING FROM THE USER’S
          OR A THIRD PARTY’S CONSUMPTION OF ALCOHOLIC BEVERAGES.`}
          </p>
          <p className="mb-4 leading-6 text-white/60">
            {`You acknowledge and assume all risk and liability with respect to any
          injuries and/or damages arising out of, caused by, or in any way
          connected with your use of Discotech's ticket, reservation, and
          booking services, directly or indirectly, whether those services are
          used alone or in a combination with other services. You further
          acknowledge that use of Discotech's ticket, reservation, and booking
          services will place you in public places, potentially in close
          proximity with groups of people who may not be following public health
          protocols such as social distancing or the wearing of face masks. You
          accordingly acknowledge and assume all risk and liability of exposure
          to and infection with viruses, bacteria, pandemics, and infectious
          illnesses or diseases that may result, directly or indirectly, from
          use of Discotech's ticket, reservation, and booking services. You
          further agree to indemnify, release, and hold Discotech harmless from
          any and all claims arising from, caused by, or in any way connected
          with, directly or indirectly, your use of Discotech's ticket,
          reservation, and booking services, including breach of warranty,
          alleged negligence, breach of contract, or otherwise. No claims of any
          kind whatsoever, whether based on breach of warranty, breach of
          contract, the alleged negligence of Discotech, or otherwise, shall be
          greater in amount than the price paid for use of Discotech's ticket,
          reservation, and booking services hereunder.`}
          </p>

          <SectionHeading>13. TERMINATION.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`If you violate any of these Terms (which include by reference our Privacy Policy), or otherwise violate an agreement between you and us, we may delete your account and prohibit you
                    from using or accessing our Services, in our sole discretion. Certain provisions of these Terms will survive the termination of these Terms, including, without limitation, the
                    sections regarding intellectual property, disclaimer of warranties, limitation of liability, agreements to arbitrate, and indemnification.`}
          </p>

          <SectionHeading>
            14. ACCESS FROM OUTSIDE THE UNITED STATES.
          </SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`Our Services are directed to people residing in the United States. We do not represent that Materials available on or through our Services are appropriate or available in other
                    locations. We may limit the availability of our Services to any person or geographic area at any time. If you choose to access our Services from outside the United States, you do
                    so at your own risk.`}
          </p>

          <SectionHeading>15. ARBITRATION AGREEMENT.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            PLEASE READ THE FOLLOWING CAREFULLY:
          </p>
          <SubSectionHeading>A. Purpose.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`Arbitration facilitates the prompt and efficient resolution of any dispute that may arise between you and Discotech. Arbitration is a type of dispute resolution where the parties
                    engage an arbitrator to weigh in on their dispute instead of having it settled in court by a judge or jury trial. You can opt-out of this Arbitration Agreement (as explained below)
                    to maintain your right to litigate your disputes in court.`}
          </p>
          <p className="mb-4 leading-6 text-white/60">
            {`By entering into this Arbitration Agreement, you agree to waive your right to litigate your claims in front of a judge or jury. You understand that in arbitration, there is
                    typically no court review of an arbitration award and the arbitrator must make a decision based off of what is written in this Arbitration Agreement.`}
          </p>
          <p className="mb-4 leading-6 text-white/60">
            {` For the purpose of this Arbitration Agreement, “Discotech” means Discotech and its parents, subsidiaries, and affiliated companies, and each of their respective officers,
                    directors, employees, and agents. The term “Dispute” means any dispute, claim, or controversy between you and Discotech regarding any aspect of your relationship with Discotech and
                    is to be given the broadest possible meaning that will be enforced.`}
          </p>
          <p className="mb-4 leading-6 text-white/60">
            {` YOU AND DISCOTECH AGREE THAT ANY AND ALL DISPUTES, WHETHER BASED ON ACTS OR OMISSIONS IN THE PAST OR FUTURE, WILL BE RESOLVED EXCLUSIVELY AND FINALLY BY BINDING ARBITRATION IN
                    ACCORDANCE WITH THIS ARBITRATION AGREEMENT.`}
          </p>
          <SubSectionHeading>
            B. Exclusions From Arbitration And Your Right To Opt Out.
          </SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`You have the option to bring a Dispute in court and not by arbitration if (a) the Dispute qualifies, it may be initiated in small claims court in your state of residence; or (b)
                    YOU OPT-OUT OF THESE ARBITRATION PROCEDURES WITHIN 30 DAYS FROM THE DATE THAT YOU FIRST CONSENT TO THIS AGREEMENT. You may opt out of this Arbitration Agreement by mailing written
                    notification to Discotech, 6477 Deep Dell Pl. Los Angeles CA 90068. Your opt-out notice must include your full name and your clear intent to opt-out of binding arbitration.`}
          </p>
          <SubSectionHeading>C. JAMS.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`JAMS will arbitrate all Disputes in front of a single arbitrator. The arbitration will be an individual arbitration and under no circumstances will the arbitration be commenced as
                    a class arbitration. The arbitrator will decide all issues brought by the parties. In the event this Arbitration Agreement conflicts with the arbitration rules, this Arbitration
                    Agreement will govern. You agree that these Terms affect interstate commerce and that the Federal Arbitration Act (“FAA”) should govern the arbitrability of all Disputes.`}
          </p>
          <SubSectionHeading>D. Process.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`You and Discotech agree that any Dispute must be commenced within one (1) year of the date the Dispute arose or else the claim is permanently barred. You and Discotech agree that
                    (a) any arbitration will occur in Los Angeles County, California, (b) arbitration will be conducted confidentially by a single arbitrator and (c) that the state or federal courts
                    of the State of California and the United States, respectively, sitting in Los Angeles County, California, have exclusive jurisdiction over any appeals and the enforcement of an
                    arbitration award. If applicable, you also have the option to litigate a Dispute in the small claims court in the county of your address.`}
          </p>
          <SubSectionHeading>E. Class Action Waiver.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`YOU AND DISCOTECH AGREE THAT THERE WILL BE NO CLASS ARBITRATION OR ARBITRATION IN WHICH AN INDIVIDUAL PERSON ATTEMPTS TO RESOLVE A DISPUTE AS A REPRESENTATIVE OF OTHER INDIVIDUALS.
                    YOU AND DISCOTECH AGREE THAT DISPUTES CANNOT BE RESOLVED AS CLASS ACTIONS, WHETHER BY ARBITRATION, LITIGATION, OR OTHERWISE.`}
          </p>
          <SubSectionHeading>F. Severability.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`If any portion of this Arbitration Agreement (other than the Class Action Waiver clause above) is found to be illegal or unenforceable, that portion will be severed from this
                    Arbitration Agreement, and the remainder of this Arbitration Agreement will be given full force and effect.`}
          </p>
          <SubSectionHeading>G. Survival.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            This Arbitration Agreement shall survive the termination of your
            contract with Discotech and your use of our Services.
          </p>
          <SectionHeading>
            16. GOVERNING LAW AND DISPUTE RESOLUTION.
          </SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`These Terms are governed by the laws of California, without resort to its conflict of law provisions. In the event the arbitration provision above does not apply, you agree that
                    any claim relating to these Terms will be filed only in the state and federal courts located in Los Angeles, California and you irrevocably and unconditionally consent to the
                    jurisdiction of such courts to hear such claim.`}
          </p>
          <SectionHeading>17. PRIVACY.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {` Your privacy is important to us. Our Privacy Policy is hereby incorporated into these Terms by reference. Please read the Privacy Policy carefully for information relating to our
                    collection, use, and disclosure of your personal information.`}
          </p>
          <SectionHeading>18. MISCELLANEOUS.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            {`These Terms constitute the entire agreement between us relating to your access to and use of our Services. These Terms may not be transferred or assigned by you without our prior
                    written consent. No waiver of any provision of these Terms will constitute a waiver of such provision in any prior, concurrent or subsequent circumstance. These Terms are for the
                    benefit of the parties and do not confer third party beneficiary rights upon any other person or entity.`}
          </p>
          <SectionHeading>19. CONTACTING DISCOTECH.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            If you have any questions, comments, or concerns about these Terms
            of Service, please contact us at:
          </p>
          <p className="mb-20 lg:mb-10 text-white/90">
            Discotech, Inc. <br />
            6477 Deep Dell Pl. <br /> br Los Angeles, California 90068 <br />{" "}
            Email: info@discotech.me
          </p>
        </div> */}
            </div>
        </div>
    );
}

// const SectionHeading: React.FC<{ children: ReactNode }> = ({ children }) => {
//   return <h2 className="text-2xl mb-2 text-white font-thin">{children}</h2>;
// };

// const SubSectionHeading: React.FC<{ children: ReactNode }> = ({ children }) => {
//   return <h3 className="text-2xl mb-2 text-white font-semibold">{children}</h3>;
// };
