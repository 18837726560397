import { useNavigate, useParams } from "react-router-dom";
import { FirebaseStorage } from "../lib/firebase/storage";
import { useAuth } from "../providers/Authentication";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import ProfileHeader from "./Profile/header";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../components/TextInput/TextInput";
import Select from "react-select";
import { musicGenres } from "../components/ArtistForm/genre";
import CountryNumber from "../components/CountryNumber";

const intialState = {
    displayName: "",
    phone: "",
    email: "",
    website: "",
    instagram: "",
    facebook: "",
    pinterest: "",
    telegram: "",
    twitter: "",
    discord: "",
    youtube: "",
    tiktok: "",
    linkedin: "",
    description: "",
    genre: [],
};
const initialStateSchema = Yup.object().shape({
    displayName: Yup.string().required("Artists name is required"),
    phone: Yup.string(),
    email: Yup.string().email("Invalid email address"),
    website: Yup.string().url("Invalid website URL").required("Website URL is required"),
    instagram: Yup.string().url("Invalid Instagram URL"),
    facebook: Yup.string().url("Invalid Facebook URL"),
    pinterest: Yup.string().url("Invalid Pinterest URL"),
    telegram: Yup.string().url("Invalid Telegram URL"),
    twitter: Yup.string().url("Invalid Twitter URL"),
    discord: Yup.string().url("Invalid Discord URL"),
    youtube: Yup.string().url("Invalid YouTube URL"),
    tiktok: Yup.string().url("Invalid TikTok URL"),
    linkedin: Yup.string().url("Invalid LinkedIn URL"),
    description: Yup.string().required("Description is required"),
    genre: Yup.array().of(Yup.string()).required("Genre is required"),
});

export const EditArtist = () => {
    const { slug } = useParams();
    const { user } = useAuth();
    const nav = useNavigate();
    const [defaultGenre, setDefaultGenre] = useState<any>([]);
    const [file, setFile] = useState<File | null>(null);
    const artists = useQuery({
        queryKey: "Edit-artists",
        queryFn: () => FirebaseStorage.getArtistById(slug as string),
    });

    const { handleChange, values, errors, handleSubmit, touched, setValues } = useFormik({
        initialValues: intialState,
        validationSchema: initialStateSchema,
        onSubmit: async (values, formikHelpers) => {
            let params = { ...values, file: file, adminId: user.uid, photoURL: artists.data?.photoURL };
            await FirebaseStorage.updateArtistById(slug as string, params).then((r) => {
                nav("/artists");
            });
            setTimeout(() => {
                formikHelpers.resetForm();
                setFile(null);
            }, 1000);
        },
    });

    const { data, isLoading } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    useEffect(() => {
        if (!data?.roles?.includes("SiteAdmin")) {
            nav("/profile");
        }
    }, [data, nav]);

    useEffect(() => {
        if (artists.data) {
            const genres = artists.data.genre.map((item) => {
                return musicGenres.find((i) => i.value === item);
            });
            console.log({ genres });
            setDefaultGenre(genres);
            setValues({
                ...values,
                displayName: artists.data.displayName,
                phone: artists.data?.phone,
                email: artists.data?.email,
                description: artists.data.description,
                website: artists.data?.website || "",
                youtube: artists.data?.youtube || "",
                instagram: artists.data?.instagram || "",
                facebook: artists.data?.facebook || "",
                pinterest: artists.data?.pinterest || "",
                telegram: artists.data?.telegram || "",
                twitter: artists.data?.twitter || "",
                discord: artists.data?.discord || "",
                tiktok: artists.data?.tiktok || "",
                linkedin: artists.data?.linkedin || "",
                genre: artists.data?.genre as any,
            });
        }
    }, [artists.data, values, setValues]);

    console.log({ defaultGenre });

    if (artists.isLoading || isLoading) return <div>loading</div>;
    return (
        <div className=" bg-[rgb(10,13,22)]">
            {" "}
            <div className="max-w-screen-xl mx-auto">
                <ProfileHeader />
                <div className="flex items-center justify-between mb-5 pt-10">
                    <div className="px-4 lg:px-5 text-3xl text-center lg:text-left">
                        <span className="hidden lg:inline-block style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Edit Artist</span>
                    </div>
                    {/* logout */}
                    <button className="hidden md:flex border border-red-500 py-3 px-4 text-red-500">Logout</button>
                </div>
                {/* profile pic */}
                <div className="ml-5 md:ml-5 rounded-md w-48 h-48 md:w-52 md:h-52 bg-white mb-5 overflow-hidden relative">
                    <label
                        htmlFor="image"
                        className="rounded-full cursor-pointer">
                        <img
                            src={file ? URL.createObjectURL(file) : artists?.data?.photoURL}
                            alt={"artists"}
                            className="w-full h-full object-cover"
                        />
                    </label>
                    <input
                        type="file"
                        className="hidden"
                        id="image"
                        onChange={(e) => {
                            if (e.target.files) {
                                setFile(e.target.files[0]);
                            }
                        }}
                        accept="image/*"
                    />
                </div>
            </div>
            {/* <div className="container mx-auto p-4"> */}
            <div className="max-w-screen-xl mx-auto mb-5">
                <form
                    onSubmit={handleSubmit}
                    className="w-full">
                    <div className="w-full flex gap-5 flex-wrap lg:flex-nowrap">
                        <div className="dark:bg-gray-700 shadow rounded-lg p-6 w-full lg:w-8/12 ">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-2 flex-wrap lg:flex-nowrap">
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="name"
                                        className="text-white text-lg">
                                        Artist Name
                                    </label>
                                    <TextInput
                                        id="name"
                                        type="text"
                                        name="displayName"
                                        value={values.displayName}
                                        placeholder="Inter your name"
                                        onChange={handleChange}
                                        className={`border ${touched.displayName && errors.displayName ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            touched.displayName && errors.displayName ? "active:border-red-400" : "active:border-white/40"
                                        } ${touched.displayName && errors.displayName ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {touched.displayName && errors.displayName && <p className="text-red-400 text-sm mt-1">{errors.displayName}</p>}
                                </div>
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Email*
                                    </label>
                                    <div className="mb-4">
                                        <TextInput
                                            id="email"
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder="example@mail.com"
                                            className={`border ${touched.email && errors.email ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                                touched.email && errors.email ? "active:border-red-400" : "active:border-white/40"
                                            } ${touched.email && errors.email ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                        />
                                        {touched.email && errors.email && <p className="text-red-400 text-sm mt-1">{errors.email}</p>}
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label
                                        htmlFor="country"
                                        className="text-white text-lg block mb-2">
                                        Country Code
                                    </label>
                                    <div
                                        className={`border ${
                                            errors.phone ? "border-red-400" : "border-white/30"
                                        } rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none`}>
                                        <CountryNumber
                                            handleChange={(value: any) => setValues({ ...values, phone: value })}
                                            phoneNumber={values.phone}
                                        />
                                    </div>
                                    {touched.phone && errors.phone && <p className="text-red-400 text-sm mt-1">{errors.phone}</p>}
                                </div>
                                <div className="flex flex-col ">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg mb-2">
                                        Genre
                                    </label>
                                    <Select
                                        defaultValue={artists.data?.genre.map((value: any) => {
                                            return musicGenres.find((genre: any) => genre.value === value);
                                        })}
                                        options={musicGenres}
                                        isMulti
                                        onChange={(gen) => {
                                            // @ts-ignore
                                            setValues({ ...values, genre: gen.map((value: any) => value.value) });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 mt-2 flex-wrap lg:flex-nowrap">
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        LinkedIn
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="linkedin"
                                        value={values.linkedin}
                                        onChange={handleChange}
                                        placeholder="add your LinkedIn link"
                                        className={`border ${errors.linkedin ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.linkedin ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.linkedin ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.linkedin && <p className="text-red-400 text-sm mt-1">{errors.linkedin}</p>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Web Site
                                    </label>
                                    <TextInput
                                        id="link"
                                        type="link"
                                        name="website"
                                        value={values.website}
                                        onChange={handleChange}
                                        placeholder="add your web site link"
                                        className={`border ${touched.website && errors.website ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            touched.website && errors.website ? "active:border-red-400" : "active:border-white/40"
                                        } ${touched.website && errors.website ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {touched.website && errors.website && <p className="text-red-400 text-sm mt-1">{errors.website}</p>}
                                </div>

                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Intagram
                                    </label>
                                    <TextInput
                                        id="link"
                                        type="link"
                                        name="instagram"
                                        value={values.instagram}
                                        onChange={handleChange}
                                        placeholder="add your instagram link"
                                        className={`border ${errors.instagram ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.instagram ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.instagram ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.instagram && <p className="text-red-400 text-sm mt-1">{errors.instagram}</p>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        TikTok
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="tiktok"
                                        value={values.tiktok}
                                        onChange={handleChange}
                                        placeholder="add your TikTok link"
                                        className={`border ${errors.tiktok ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.tiktok ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.tiktok ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.tiktok && <p className="text-red-400 text-sm mt-1">{errors.tiktok}</p>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Facebook
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="facebook"
                                        value={values.facebook}
                                        onChange={handleChange}
                                        placeholder="add your facebook link"
                                        className={`border ${errors.facebook ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.facebook ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.facebook ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.facebook && <p className="text-red-400 text-sm mt-1">{errors.facebook}</p>}
                                </div>

                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Pinterest
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="pinterest"
                                        value={values.pinterest}
                                        onChange={handleChange}
                                        placeholder="add your Pinterest link"
                                        className={`border ${errors.pinterest ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.pinterest ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.pinterest ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.pinterest && <p className="text-red-400 text-sm mt-1">{errors.pinterest}</p>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Telegram
                                    </label>
                                    <TextInput
                                        id="link"
                                        type="link"
                                        name="telegram"
                                        value={values.telegram}
                                        onChange={handleChange}
                                        placeholder="add Telegram site link"
                                        className={`border ${errors.telegram ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.telegram ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.telegram ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.telegram && <p className="text-red-400 text-sm mt-1">{errors.telegram}</p>}
                                </div>

                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Twitter
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="twitter"
                                        value={values.twitter}
                                        onChange={handleChange}
                                        placeholder="add your Twitter link"
                                        className={`border ${errors.twitter ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.twitter ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.twitter ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.twitter && <p className="text-red-400 text-sm mt-1">{errors.twitter}</p>}
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        Discord
                                    </label>
                                    <TextInput
                                        id="text"
                                        type="text"
                                        name="discord"
                                        value={values.discord}
                                        onChange={handleChange}
                                        placeholder="add your Discord link"
                                        className={`border ${errors.discord ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.discord ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.discord ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.discord && <p className="text-red-400 text-sm mt-1">{errors.discord}</p>}
                                </div>

                                <div className="flex flex-col gap-1">
                                    <label
                                        htmlFor="email"
                                        className="text-white text-lg">
                                        YouTube
                                    </label>
                                    <TextInput
                                        id="link"
                                        type="link"
                                        name="youtube"
                                        value={values.youtube}
                                        onChange={handleChange}
                                        placeholder="add your YouTube link"
                                        className={`border ${errors.youtube ? "border-red-400" : "border-white/30"} p-2 rounded w-full bg-transparent ${
                                            errors.youtube ? "active:border-red-400" : "active:border-white/40"
                                        } ${errors.youtube ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                    />
                                    {errors.youtube && <p className="text-red-400 text-sm mt-1">{errors.youtube}</p>}
                                </div>
                            </div>
                        </div>

                        <div className="w-full lg:w-4/12 p-6">
                            <div className="flex flex-col gap-1">
                                <label
                                    htmlFor="email"
                                    className="text-white text-lg">
                                    Description
                                </label>
                                <textarea
                                    id="name"
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    rows={10}
                                    placeholder="add your Description link"
                                    className={`border ${touched.description && errors.description ? "border-red-400" : "border-white/30"} p-2  text-white rounded w-full bg-transparent ${
                                        touched.description && errors.description ? "active:border-red-400" : "active:border-white/40"
                                    } ${touched.description && errors.description ? "focus:border-red-400" : "focus:border-white/40"} active:outline-none focus:outline-none`}
                                />
                                {touched.description && errors.description && <p className="text-red-400 text-sm mt-1">{errors.description}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex items-center justify-end p-6 pb-10">
                        <button
                            type="submit"
                            className="px-5 py-3 text-lg rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none transition-colors w-56">
                            Update Artist
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};
