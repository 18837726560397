export default function PrivacyPolicy() {
    return (
        <div className="bg-[#020308] h-screen">
            <div className="max-w-screen-xl mx-auto px-4 lg:px-50 pb-10 lg:p-7 text-gray-500">
                <p className="text-3xl text-center lg:text-left">
                    <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Privacy Policy</span>
                </p>
                {/* <div className="mt-10">
          <p className="mb-4 leading-6 text-white/60">
            {`Discotech Inc., a California corporation (“Discotech,” “we,” or “us”)
          is committed to respecting and protecting your privacy. This Privacy
          Policy explains our practices regarding the collection, use, and
          disclosure of information that we receive through our mobile device
          application (the “App”), website located at https://www.discotech.me
          (the “Site”) and any other services provided by Discotech. The App,
          Site and related services are collectively referred to herein as the
          “Services.”`}
          </p>

          <p className="mb-4 italic leading-6 text-white/60">
            Your privacy is important to us so please do take the time to get to
            know our practices. If you have any questions, contact us at
            info@discotech.me. If you do not agree to this Privacy Policy, do
            not use our Services or give us any of your information.
          </p>

          <SectionHeading>1. COLLECTION OF INFORMATION.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            We collect your information so that we can provide you with the best
            service possible – from curating the hottest events in your city to
            making sure you get that coveted dance floor table. The more we know
            about you, the more we can provide! We know your information is
            sensitive and we make every effort to only gather as much
            information as needed to provide our Services.
          </p>

          <p className="mb-4 leading-6 text-white/60">
            Your privacy is important to us so please do take the time to get to
            know our practices. If you have any questions, contact us at
            info@discotech.me. If you do not agree to this Privacy Policy, do
            not use our Services or give us any of your information.
          </p>

          <p className="mb-4 leading-6 text-white/60">
            We collect information in the following ways:
          </p>

          <SectionHeading>2. USE.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            We collect information in the following ways:
          </p>

          <SectionHeading>3. MOBILE DEVICE.</SectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            Information You Give Us. We may collect information from you, such
            as when you email us, use our social media platforms, or otherwise
            communicate with us.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            <ul className="list-disc pl-8 lg:pl-10 mt-5 leading-7">
              <li className="text-white/80">
                Account Information: To create an account with Discotech, we
                collect your name and email address. In addition, if you want to
                make a table reservation, we will collect your phone number. We
                may also collect your birthdate, Facebook photo and gender for
                your account.
              </li>
              <li className="text-white/80">
                Billing Information: We are committed to making payments as
                secure and seamless as possible. In order to purchase event
                tickets or make a table reservation, we may ask for your billing
                and credit card information. We use certain third party
                processors to collect and store credit card numbers and all
                transactions are processed through them. While we do not have
                direct control over your billing and credit card information, we
                make every effort to keep your information confidential and
                protected by contracting with trusted third parties who promise
                to do the same.
              </li>
              <li className="text-white/80">
                Other Information You May Provide Us: We value your feedback! We
                may collect other information from you, such as when you call
                our customer support line, email customer service, text us, use
                our social media platforms, or otherwise communicate with us.
              </li>
            </ul>
          </p>
          <p className="mb-4 leading-6 text-white/60">
            Information We Get From Your Use of Our Services.
            <ul className="list-disc pl-8 lg:pl-10 mt-5 leading-7">
              <li className="text-white/80">
                Device Information: We receive information from your devices,
                such as your hardware model, operating system version, unique
                device identifiers and mobile network information.
              </li>
              <li className="text-white/80">
                Log Information: To help us understand how you use our Site, we
                automatically collect log information such as the browser you
                use, the number of times you visit, the pages you view and your
                IP address.
              </li>
              <li className="text-white/80">
                Location Information: In order to source the best events in your
                geographic area, our Services automatically collect your
                geolocation. If you wish to stop the automatic collection of
                your geolocation, you can do so by changing the privacy settings
                on your browser and/or mobile device.
              </li>
              <li className="text-white/80">
                {`Cookies: We use cookies to help enhance your user experience. A
              "cookie" is a small file that does not contain personally
              identifiable information and is saved on your computer or mobile
              device. You can elect to delete or disable such cookies by
              changing your browser settings. We do not allow third parties to
              access or use our cookies.`}
              </li>
            </ul>
          </p>

          <p className="mb-4 leading-6 text-white/60">
            Information We Collect From Other Sources.
          </p>
          <p className="mb-4 leading-6 text-white/60">
            We may collect information about you from third parties, such as
            through social media, our third party vendors, other users of our
            Services, or our payment processors. If you create a Discotech
            account through a social media site, we will have access to certain
            information from that site based on their authentication procedures.
          </p>

          <p className="mb-4 leading-6 text-white/60">
            If you would like to request more information on what data we
            collect from you, please call us at (415) 735-6716, or email us at
            info@discotech.me and provide us with the full name and the email
            address associated with your account.
          </p>

          <SectionHeading>
            4. ELIGIBILITY AND ACCOUNT INFORMATION.
          </SectionHeading>
          <SubSectionHeading>A. Eligibility.</SubSectionHeading>
          <p className="mb-4 leading-6 text-white/60">
            To ensure that we are providing a safe community for our users, we
            require users to meet certain eligibility requirements. By accessing
            our Site, you represent and warrant: (i) that you are eighteen (18)
            years of age or older and have the legal capacity to enter into
            these Terms; (ii) you have not been suspended or removed from our
            Services; and (iii) that you are in compliance at all times with all
            applicable laws and regulations.
          </p>
        </div> */}
            </div>
        </div>
    );
}

// const SectionHeading: React.FC<{ children: ReactNode }> = ({ children }) => {
//   return <h2 className="text-2xl mb-2 text-white font-thin">{children}</h2>;
// };

// const SubSectionHeading: React.FC<{ children: ReactNode }> = ({ children }) => {
//   return <h3 className="text-2xl mb-2 text-white font-semibold">{children}</h3>;
// };
