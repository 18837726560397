const SkeletonVenues = ({ count }: any) => {
    const skeletonItems = Array.from({ length: count }, (_, index) => (
        <div className="h-screen">
            <div
                key={index}
                className=" rounded-md">
                <div className="w-full rounded overflow-hidden shadow-lg animate-pulse">
                    <div className="h-48 w-full bg-gray-500"></div>
                    <div className="py-4">
                        <div className="h-6 bg-gray-500 mb-2"></div>
                        <div className="h-4 bg-gray-500 w-[40%]"></div>
                    </div>
                </div>
            </div>
        </div>
    ));

    return (
        <div className="w-full lg:px-12 px-0">
            <div className="contaner mx-auto lg:p-10 p-5">
                <div className="w-full text-3xl text-center lg:text-left mb-5">
                    <span className="hidden lg:inline-block style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Venues</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4 relative">{skeletonItems}</div>
            </div>
        </div>
    );
};

export default SkeletonVenues;
