import { createContext, useMemo, useContext } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
interface UserInterface {
    apiKey: string;
    appName: string;
    createdAt: string; // Assuming this is a string representation of a timestamp
    displayName: string;
    email: string;
    emailVerified: boolean;
    isAnonymous: boolean;
    lastLoginAt: string; // Assuming this is a string representation of a timestamp
    photoURL: string;
    providerData: any[]; // You can define a more specific type for providerData if available
    stsTokenManager: {
        refreshToken: string;
        accessToken: string;
        expirationTime: number; // Assuming this is a UNIX timestamp
    };
    uid: string;
}

type AuthContextType = {
    user: UserInterface;
    setUser: (user: any) => void;
};

type Props = {
    children: React.ReactNode;
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export default function AuthenticationProvider({ children }: Props) {
    const [user, setUser] = useLocalStorage("user", null);

    const value = useMemo(
        () => ({
            user,
            setUser,
        }),
        [user, setUser]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
export const useAuth = () => {
    return useContext(AuthContext);
};
