import { IEvents } from "../../../../lib/firebase/storage";
import { HiOutlineChevronRight } from "react-icons/hi2";
import { Link, useLocation } from "react-router-dom";
import { startCase, truncate } from "lodash";
type Props = {
    event: IEvents;
};
export default function EntryOption({ event }: Props) {
    const location = useLocation();
    const city = location.pathname.split("/")[2];
    return (
        <div className="w-full bg-[#0A0D16] min-h-screen">
            <div className="container mx-auto">
                <div className="flex lg:gap-5 gap-2 lg:p-10 p-3 w-full flex-wrap flex-row-reverse lg:flex-nowrap justify-center">
                    <div className="w-full lg:w-6/12 flex flex-col gap-3 z-20">
                        <p className="lg:text-4xl text-2xl font-normal leading-none hidden lg:block">
                            <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Entry Options </span>
                        </p>

                        <div className="hidden lg:flex flex-col text-white text-lg">
                            <Link
                                to={`/tickets/${event.tickets}`}
                                target="blank">
                                Tickets
                            </Link>
                        </div>
                        <div className="hidden lg:flex flex-col text-white text-lg">
                            <Link
                                to={`/tickets/${event.vipTable}`}
                                target="blank">
                                VIP Tables
                            </Link>
                        </div>
                    </div>
                    <div className="w-full lg:w-6/12 z-20">
                        <div className="flex flex-col lg:gap-3 gap-2">
                            <p className="lg:text-4xl text-2xl font-normal leading-none hidden lg:block">
                                <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC"> Details</span>
                            </p>
                            <Link
                                target="blank"
                                to={`https://www.google.com/maps/dir//${event.vanueData.address}`}>
                                <p className="text-white text-lg">Address: {event.vanueData.address}</p>
                            </Link>
                            {/* {selectedOption === "details" && ( */}
                            <>
                                <p className="lg:text-3xl text-2xl font-normal leading-none">
                                    <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Venue</span>
                                </p>
                                <Link to={`/${city}/venues/${event.vanueData.slug}`}>
                                    <div className="py-3 px-3 flex items-center justify-between bg-[#1C1D25] shadow-eventsDetailPageVip rounded-md">
                                        <div className="flex items-center gap-3">
                                            <div>
                                                <img
                                                    src={event.vanueData.images[0]}
                                                    alt="pic"
                                                    className="rounded-full w-20 h-20 object-cover"
                                                />
                                            </div>
                                            <div>
                                                <p className="text-white font-semibold text-xl mb-1">{event.vanueData.displayName}</p>
                                                <p className="text-white/80 text-sm">{startCase(event.vanueData.city)}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <HiOutlineChevronRight className="text-white text-xl" />
                                        </div>
                                    </div>
                                </Link>
                            </>
                            <div className="lg:text-2xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Artists</span>
                            </div>
                            <div className="flex flex-nowrap gap-4">
                                {event.artists.map((artist) => (
                                    <Link
                                        to={`/artists/${artist.id}`}
                                        className="flex flex-col items-center">
                                        <img
                                            src={artist.photoURL}
                                            alt={artist.displayName}
                                            className="rounded-full w-28 h-28 object-cover shadow-lg"
                                        />
                                        <p className="text-white text-xs">
                                            {truncate(artist.displayName, {
                                                length: 20,
                                                omission: "...",
                                            })}
                                        </p>
                                    </Link>
                                ))}
                            </div>
                            {/* )} */}
                        </div>
                        <div className="lg:hidden flex flex-col gap-4 pt-5">
                            <div className="pl-1 text-white text-lg">
                                <Link
                                    to={`/tickets/${event.tickets}`}
                                    target="_blank">
                                    Tickets
                                </Link>
                            </div>
                            <div className="pl-1 text-white text-lg">
                                <Link
                                    to={`/tickets/${event.vipTable}`}
                                    target="_blank">
                                    VIP Tables
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
