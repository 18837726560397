import { Link } from "react-router-dom";
import { useAuth } from "../../providers/Authentication";
import { useQuery } from "react-query";
import { FirebaseStorage } from "../../lib/firebase/storage";
import { useEffect, useState } from "react";

export default function ProfileHeader() {
    const [isAccess, setIsAccess] = useState([""]);
    const { user } = useAuth();
    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    useEffect(() => {
        if (data?.roles) {
            setIsAccess(data.roles);
        } else {
            setIsAccess(["User"]);
        }
    }, [data]);

    if (isAccess.includes("User")) {
        return null;
    }
    if (isAccess.includes("EventArtistsVenue") && isAccess.includes("UserAdmin")) {
        return (
            <div className="w-full text-white flex gap-4 pt-4 pl-4">
                <Link to="/profile/admin/users">User</Link>
                <Link to={"/profile"}>Profile</Link>
                {data?.isArtist ? <Link to={"/profile/artist"}>Add Artist</Link> : <Link to={"/profile/artist-request"}>Request Artist</Link>}
                <Link to={"/profile/event"}>Add Event</Link>
                {data?.isVanue ? <Link to={"/profile/vanue"}>Add Venue</Link> : <Link to={"/profile/vanue-request"}>Request Vanue</Link>}
            </div>
        );
    }
    if (isAccess.includes("UserAdmin")) {
        return (
            <div className="w-full text-white flex gap-4 pt-4 pl-4">
                <Link to="/profile/admin/users">Users</Link>
            </div>
        );
    }
    if (isAccess.includes("SiteAdmin")) {
        return (
            <div className="w-full text-white flex gap-4 pt-4 pl-4">
                <Link to="/profile/admin/users">User</Link>
                <Link to={"/profile"}>Profile</Link>
                {data?.isArtist ? <Link to={"/profile/artist"}>Add Artist</Link> : <Link to={"/profile/artist-request"}>Request Artist</Link>}
                <Link to={"/profile/event"}>Add Event</Link>
                {data?.isVanue ? <Link to={"/profile/vanue"}>Add Venue</Link> : <Link to={"/profile/vanue-request"}>Request Vanue</Link>}
            </div>
        );
    }
    if (isAccess.includes("EventArtistsVenue")) {
        return (
            <div className="w-full text-white flex gap-4 pt-4 pl-4">
                <Link to={"/profile"}>Profile</Link>
                {data?.isArtist ? <Link to={"/profile/artist"}>Add Artist</Link> : <Link to={"/profile/artist-request"}>Request Artist</Link>}
                <Link to={"/profile/event"}>Add Event</Link>
                {data?.isVanue ? <Link to={"/profile/vanue"}>Add Venue</Link> : <Link to={"/profile/vanue-request"}>Request Vanue</Link>}
            </div>
        );
    }

    return null;
}
