import React, { useState } from "react";
import BackgroundImg from "../../assets/bgImg.jpeg";
import DownloadAppStoreBtn from "../../assets/app-store.svg";
import DownloadGooglePlayBtn from "../../assets/google-play.svg";
import Calender from "../Calendar/Calender";
import { Link, useNavigate } from "react-router-dom";
import SelectInput from "../SelectInput";
import slugify from "slugify";

export default function Herosection() {
    const navigate = useNavigate();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const handleDateClick = (date: any) => {
        setSelectedDate(date);
    };

    const handleRoute = () => {
        const city = localStorage.getItem("city") || "las vegas";

        navigate(`/${slugify(city, { lower: true })}/events`, {
            state: {
                date: selectedDate.getTime(),
            },
        });
    };
    return (
        <div className="relative">
            <div
                style={{ backgroundImage: `url(${BackgroundImg})` }}
                className="bg-cover bg-center bg-no-repeat brightness-50 z-0 h-screen"
            />
            <div className="absolute top-[23%] md:top-[25%] lg:top-[25%] left-0 right-0 text-white flex flex-col items-center justify-center">
                <p className="text-3xl font-normal">Find </p>
                <p className="text-3xl font-semibold">All  <span className="italic">Nightlife</span>
                </p>
                <p className="my-3 text-sm text-white/70 text-center"></p>
                <div className="flex items-center gap-2">
                    <SelectInput screen="HOME" />

                    <Calender handleDate={handleDateClick} />
                </div>
                <button
                    onClick={handleRoute}
                    className="my-5 relative flex items-center gap-1 bg-primary text-white font-medium px-36 md:px-28 py-[8px] rounded-md group overflow-hidden z-0">
                    <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-0"></span>
                    <span className="z-50">Search</span>
                </button>
            </div>

            <div className="bg-black pb-20 md:pb-20">
                <div className="flex items-center justify-center text-3xl text-white font-semibold">
                    <p className="">Get the App</p>
                </div>
                <div className="flex flex-col md:flex-row items-center justify-center gap-3 mt-4">
                    <Link to="#">
                        <img
                            src={DownloadAppStoreBtn}
                            alt="App Store Img"
                            className="w-40"
                        />
                    </Link>
                    <Link to="#">
                        <img
                            src={DownloadGooglePlayBtn}
                            alt="Google Store Img"
                            className="w-40"
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
}
