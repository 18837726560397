import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default function CountryNumber({ handleChange, phoneNumber }: any) {
const [country, setCountry] = useState("us");

const handleCountryChange = (
value: any,
countryData: any,
event: any,
formattedValue: any
) => {
setCountry(countryData.dialCode);
handleChange(formattedValue); // Pass the formatted value to handleChange
};

return (
<div className="">
<PhoneInput
country={country}
placeholder="+1 (XXX) XXX-XXXX"
value={phoneNumber} // Use phoneNumber as the value
onChange={handleCountryChange} // Use handleCountryChange for onChange
enableSearch={false}
inputProps={{ name: "phone", required: true }}
countryCodeEditable={false}
/>
</div>
);
}