import { useFormik } from "formik";
import * as Yup from "yup";
import BackgroundImg from "../../assets/bgImg.jpeg";

export default function Page() {
  const formik = useFormik({
    initialValues: {
      userEmail: "",
    },
    validationSchema: Yup.object({
      userEmail: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    onSubmit: (values) => {
      console.log("Form submitted with values:", values.userEmail);
    },
  });
  return (
    <div className="relative">
      {/* bg img div */}
      <div
        style={{ backgroundImage: `url(${BackgroundImg})` }}
        className="bg-cover bg-center bg-no-repeat brightness-50 z-0 h-screen"
      />
      <div className="absolute top-[20%] md:top-[20%] lg:top-[32%] left-0 right-0 text-white flex flex-col items-center justify-center">
        {/* login form */}
        <form
          onSubmit={formik.handleSubmit}
          className="mt-10 w-full px-5 md:w-auto md:px-0"
        >
          <p className="hidden lg:flex text-3xl text-left">
            <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">
              Reset Password
            </span>
          </p>
          <div className="text-md text-white/70 text-left mt-5">
            Please enter your email:
          </div>
          <div className="w-full md:w-[620px]">
            <input
              type="email"
              placeholder="Enter your email"
              className={`w-full h-14 my-3 px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 bg-transparent ${
                formik.touched.userEmail && formik.errors.userEmail
                  ? "border-[#F2695A]"
                  : "border-[#414449]"
              }`}
              id="userEmail"
              name="userEmail"
              onBlur={formik.handleBlur}
              autoComplete="none"
              onChange={formik.handleChange}
              value={formik.values.userEmail}
            />
          </div>
          {/* Validation Error */}
          {formik.touched.userEmail && formik.errors.userEmail && (
            <div className="text-[#F2695A] pb-1">{formik.errors.userEmail}</div>
          )}

          <button
            type="submit"
            className="relative flex items-center justify-center gap-1 bg-primary text-white font-medium w-full h-[50px] rounded-md group overflow-hidden"
          >
            <span className="container mx-auto absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF]"></span>
            <span className="z-50">Continue</span>
          </button>
        </form>
      </div>
    </div>
  );
}
