export default function ProfileSkeleton() {
  return (
    <div className="bg-[#0A0D16] px-5 pt-10 h-screen">
      <div className="max-w-screen-xl mx-auto animate-pulse flex items-center justify-between">
        <div className="flex items-center justify-center w-[50%] md:w-[23%] lg:w-[17%] mb-6 h-[200px] bg-gray-300 rounded-lg dark:bg-gray-700">
          <svg
            className="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
      </div>

      {/* for upcoming events text */}
      <div className="rounded-lg animate-pulse w-full mt-2 md:max-w-screen-xl mx-auto">
        <div className="flex flex-col md:flex-row items-center md:space-x-4 py-2">
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
        {/* email */}
        <div className="flex-1 py-1">
          <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
          <div className="space-y-2">
            <div className="h-10 bg-gray-300 rounded" />
          </div>
        </div>
        {/* DOB */}
        <div className="flex flex-col md:flex-row items-center md:space-x-4 py-2">
          <div className="w-full lg:flex-1 py-1">
            {/* birth year */}
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          {/* gender */}
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          {/* phone nmbr */}
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-2/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
        {/* insta and fb url */}
        <div className="flex flex-col md:flex-row items-center md:space-x-4 py-2">
          <div className="w-full lg:flex-1 py-1">
            {/* insta */}
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          {/* fb url */}
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
        {/* pinterst and telegram url */}
        <div className="flex flex-col md:flex-row items-center md:space-x-4 py-2">
          <div className="w-full lg:flex-1 py-1">
            {/* pinterst url */}
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          {/* telegram url */}
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
        {/* twitter and discord url */}
        <div className="flex flex-col md:flex-row items-center md:space-x-4 py-2">
          <div className="w-full lg:flex-1 py-1">
            {/* twitter url */}
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
          {/* discord url */}
          <div className="w-full lg:flex-1 py-1">
            <div className="h-4 bg-gray-300 rounded w-1/6 mb-2" />
            <div className="space-y-2">
              <div className="h-10 bg-gray-300 rounded" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
