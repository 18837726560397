import { useEffect, useRef, useState } from "react";
import usCities from "../../data/newCities.json";
import slugify from "slugify";
import { useLocation, useNavigate } from "react-router-dom";
interface Props {
    screen: "HOME" | "EVENT" | "VENUE";
}

export default function SelectInput(props: Props) {
    const [open, setOpen] = useState(false);
    const nav = useNavigate();
    const searchRef = useRef<HTMLDivElement | null>(null);
    const [cities, setCities] = useState<any>();
    const [popular, setPopular] = useState(["New York", "Los Angeles", "Chicago"]);
    const [search, setSearch] = useState(localStorage.getItem("city") || "Las Vegas");
    const location = useLocation();
    const currentPath = location.pathname.split("/")[2];
    useEffect(() => {
        setCities(["New York", "Los Angeles"]);
    }, []);

    useEffect(() => {
        const clickOutSide = (event: any) => {
            if (!searchRef.current?.contains(event.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", clickOutSide);

        return () => {
            document.removeEventListener("mousedown", clickOutSide);
        };
    });
    const handleSearch = () => {
        setCities([]);
        if (search.length > 0) {
            const filter = usCities.filter((city: any) => city.toLowerCase().includes(search.toLowerCase()));
            setCities(filter.slice(0, 5));
        }
    };
    useEffect(() => {
        handleSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);
    const handleSelect = (city: any) => {
        if (props.screen === "HOME") {
            localStorage.setItem("city", city);
            let newPopular = [city, ...popular].slice(0, 3);
            setPopular(newPopular);
            setSearch(city);
            setOpen(false);
        }
        if (props.screen === "EVENT") {
            localStorage.setItem("city", city);
            let newPopular = [city, ...popular].slice(0, 3);
            setPopular(newPopular);
            setSearch(city);
            setOpen(false);
            const newCity = slugify(city, {
                lower: true,
            });
            nav(`/${newCity}/${currentPath}`);
        }
        if (props.screen === "VENUE") {
            localStorage.setItem("city", city);
            let newPopular = [city, ...popular].slice(0, 3);
            setPopular(newPopular);
            setSearch(city);
            setOpen(false);
            const newCity = slugify(city, {
                lower: true,
            });
            nav(`/${newCity}/${currentPath}`);
        }
    };
    return (
        <div className="relative">
            <input
                type="text"
                onClick={() => setOpen(!open)}
                className={` border rounded-md border-white/20  ${props.screen === "EVENT" ? "w-[13rem] p-2 bg-[#242A3A]" : " lg:w-60 bg-[#2B2D3C] p-[10px]"} `}
                value={search}
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
            />
            {open && (
                <div
                    ref={searchRef}
                    className="absolute top-13 left-0 bg-[#2B2D3C] border-white/20 w-full h-72 overflow-y-scroll z-40 px-3 py-2">
                    {search.length === 0 && (
                        <>
                            <span className="bg-white/20 p-2 px-4 rounded-xl">Popular</span>
                            <div className="mt-2">
                                {popular.map((city: any) => (
                                    <span
                                        onClick={() => handleSelect(city)}
                                        className="flex py-1 cursor-pointer p-2">
                                        {city}
                                    </span>
                                ))}
                            </div>
                        </>
                    )}
                    {cities.map((city: any) => (
                        <div
                            key={city}
                            className="p-2 ">
                            {/* <span className="bg-white/20 p-2 px-4 rounded-xl">{city}</span> */}
                            <span
                                onClick={() => handleSelect(city)}
                                className="flex py-1 cursor-pointer">
                                {city}
                            </span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}
