import React, { useState } from "react";
import {
  FiChevronLeft,
  FiChevronRight,
  FiChevronsLeft,
  FiChevronsRight,
} from "react-icons/fi";
import { Link } from "react-router-dom";

export default function Pagination() {
  const [activePage, setActivePage] = useState(1); // State to track active page

  // Function to handle page click
  const handlePageClick = (pageNumber: any) => {
    setActivePage(pageNumber);
  };

  return (
    <div className="flex justify-center my-10 space-x-4">
      <Link
        to=""
        onClick={() => handlePageClick(1)} // Handle click and set active page
        className={`bg-primary/20 px-2 py-1 sm:px-2 sm:py-[6px] sm:h-8 ml-1 mt-2 text-white border border-white/30 rounded-lg focus:outline-none ${
          activePage === 1 && "bg-blue-500" // Apply blue background if active
        }`}
      >
        <FiChevronsLeft />
      </Link>
      <Link
        to=""
        onClick={() => handlePageClick(activePage - 1)} // Handle click and set active page
        className="bg-primary/20 px-2 py-1 sm:px-2 sm:py-[6px] sm:h-8 ml-1 mt-2 text-white border border-white/30 rounded-lg focus:outline-none"
      >
        <FiChevronLeft />
      </Link>
      {[1, 2, 3].map((pageNumber) => (
        <Link
          key={pageNumber}
          to=""
          onClick={() => handlePageClick(pageNumber)} // Handle click and set active page
          className={`bg-primary/20 px-2 py-1 sm:px-3 sm:py-[6px] sm:h-8 ml-1 mt-2 text-white border border-white/30 rounded-lg focus:outline-none ${
            activePage === pageNumber && "bg-blue-500" // Apply blue background if active
          }`}
        >
          {pageNumber}
        </Link>
      ))}
      <span className="px-2 py-1 sm:px-4 sm:py-2 mt-2 text-gray-500 rounded-lg focus:outline-none">
        ...
      </span>

      <Link
        to=""
        onClick={() => handlePageClick(activePage + 1)} // Handle click and set active page
        className="bg-primary/20 px-2 py-1 sm:px-3 sm:py-[6px] sm:h-8 ml-1 mt-2 text-white border border-white/30 rounded-lg focus:outline-none"
      >
        <FiChevronRight />
      </Link>
      <Link
        to=""
        onClick={() => handlePageClick(3)} // Handle click and set active page
        className="bg-primary/20 px-2 py-1 sm:px-3 sm:py-[6px] sm:h-8 ml-1 mt-2 text-white border border-white/30 rounded-lg focus:outline-none"
      >
        <FiChevronsRight />
      </Link>
    </div>
  );
}
