import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { IVanues, FirebaseStorage } from "../lib/firebase/storage";
import VenuesImageDetail from "../components/comevent/venuesComponents/VenuesImageDetail";
import VenueUpcoming from "../components/comevent/venuesComponents/VenueUpcoming";
import isArray from "lodash/isArray";
import VenuesDetailsSkeleton from "../components/VenuesDetailsSkeleton/VenuesDetailsSkeleton";
import { useEffect } from "react";

export default function VenuesDetails() {
    const { slug } = useParams();
    const { data, isLoading, refetch } = useQuery({
        queryKey: "venue",
        queryFn: () => FirebaseStorage.getVanue(slug as string),
    });

    useEffect(() => {
        refetch();
    }, [slug, refetch]);

    if (isLoading)
        return (
            <div>
                <VenuesDetailsSkeleton />
            </div>
        );
    return (
        <>
            <div className="w-full h-screen relative">
                <div
                    className="hidden w-full lg:flex brightness-50 fixed top-0 left-0 z-[1] opacity-25 min-h-[27rem] bg-cover bg-center bg-no-repeat"
                    style={{
                        backgroundImage: `url(${isArray(data?.images) && data?.images[0]})`,
                    }}
                />
                <div
                    className="lg:hidden w-full flex brightness-50 fixed top-0 left-0 z-[1] opacity-25 min-h-[18rem] bg-cover bg-center bg-no-repeat"
                    style={{
                        backgroundImage: `url(${isArray(data?.images) && data?.images[0]})`,
                    }}
                />
                <div className="w-full h-[100%] bg-[#0A0D16] fixed top-0 left-0"></div>
                <div className="w-full container lg:mx-auto mx-auto-none flex flex-col absolute z-10 inset-0">
                    <VenuesImageDetail data={data as IVanues} />
                    <VenueUpcoming data={data as IVanues} />
                </div>
            </div>
        </>
    );
}
