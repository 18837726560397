import { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import NavbarForMobile from "./NavbarForMobile";
import LogoNoBgForDesktop from "../../assets/logo-no-bg-ForDesktop.png";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../providers/Authentication";
import { useQuery } from "react-query";
import { FirebaseStorage } from "../../lib/firebase/storage";
import { IoChevronDown, IoChevronForward } from "react-icons/io5";
import { artistsClient, eventClient, venueClient } from "../../lib/algolia";
import _ from "lodash";
import SearchLoading from "../SearchLoading/SearchLoading";
import slugify from "slugify";
export default function Navbar() {
    const { user, setUser } = useAuth();
    const location = useLocation();
    const city = slugify(localStorage.getItem("city") || "las-vegas", { lower: true });

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResultArtists, setSearchResultArtists] = useState<any>([]);
    const [searchResultVanues, setSearchResultVanues] = useState<any>([]);
    const [searchResultEvents, setSearchResultEvents] = useState<any>([]);
    const [searching, setSearching] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSearchPopupOpen, setIsSearchPopupOpen] = useState(false);
    const [isNavbarBlurred, setIsNavbarBlurred] = useState(false);
    const searchRef = useRef<HTMLDivElement | null>(null);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
    const { data } = useQuery(
        "profile",
        async () => {
            return await FirebaseStorage.getCurrentUser(user.uid);
        },
        { enabled: !!user }
    );
    // for search popup
    useEffect(() => {
        const clickOutSide = (event: any) => {
            if (!searchRef.current?.contains(event.target)) {
                setIsSearchPopupOpen(false);
            }
        };
        document.addEventListener("mousedown", clickOutSide);

        return () => {
            document.removeEventListener("mousedown", clickOutSide);
        };
    });

    const searchPopOpen = () => {
        setIsSearchPopupOpen(!isSearchPopupOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    useEffect(() => {
        const clickOutsideForProfile = (event: MouseEvent) => {
            if (dropdownMenuRef.current && !dropdownMenuRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", clickOutsideForProfile);

        return () => {
            document.removeEventListener("mousedown", clickOutsideForProfile);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setIsNavbarBlurred(true);
            } else {
                setIsNavbarBlurred(false);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const delayedSearch = _.debounce(async (term) => {
        let promises = [artistsClient.search(term), eventClient.search(term), venueClient.search(term)];
        try {
            setSearching(true);
            let results = await Promise.all(promises);
            setSearchResultArtists(results[0].hits as any);
            setSearchResultVanues(results[2].hits as any);
            setSearchResultEvents(results[1].hits as any);
            setSearching(false);
        } catch (error) {
            console.log({ error });
            setSearching(false);
        }
    }, 500);

    useEffect(() => {
        delayedSearch(searchQuery);
        return delayedSearch.cancel;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const handleChange = (e: any) => {
        setSearchQuery(e.target.value);
    };

    return (
        <nav
            className={`py-2 lg:py-0 sticky top-0 border-b border-white/20 bg-[#0A0D16] text-white px-5 lg:flex lg:items-center lg:justify-between gap-5 lg:gap-10 z-50 backdrop-filter backdrop-blur-lg ${
                isNavbarBlurred && "bg-transparent bg-opacity-10"
            }`}>
            <div className="hidden lg:flex items-center lg:space-x-12 xl:space-x-40 2xl:space-x-40">
                <Link to="/">
                    <img
                        src={LogoNoBgForDesktop}
                        alt="Logo"
                        className="w-[200px] h-10"
                    />
                </Link>
                <div className="hidden lg:flex space-x-4 xl:spac-x-6">
                    <Link
                        to={`/${city}/events`}
                        className={`text-md text-white/80 hover:text-white py-6 px-2 hover:bg-white/10 ${
                            location.pathname === "/las-vegas/events" && "border-b-[2px] border-blue-500 cursor-default hover:text-white/80 hover:bg-transparent"
                        } `}>
                        Events
                    </Link>
                    <Link
                        to={`/${city}/venues`}
                        className={`text-md text-white/80 hover:text-white py-6 px-2 hover:bg-white/10 ${
                            location.pathname === "/las-vegas/venues" && "border-b-[2px] border-blue-500 cursor-default hover:text-white/80 hover:bg-transparent"
                        } `}>
                        Venues
                    </Link>
                    <Link
                        to="/artists"
                        className={`text-md text-white/80 hover:text-white py-6 px-2 hover:bg-white/10 ${
                            location.pathname === "/artists" && "border-b-[3px] border-blue-500 cursor-default hover:text-white/80 hover:bg-transparent"
                        }`}>
                        Artists
                    </Link>
                </div>
            </div>
            {/* 1st div for logo with links end */}

            {/* 2nd div for search input with button start */}
            <div className="hidden lg:flex items-center lg:space-x-10 xl:space-x-32 2xl:space-x-60">
                {/* search input */}
                <div
                    className="relative"
                    onClick={searchPopOpen}>
                    <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5" />
                    <input
                        type="text"
                        placeholder="Search"
                        onChange={handleChange}
                        value={searchQuery}
                        className="bg-[#242A3A] shadow-inner shadow-[#131620] w-[12rem] lg:w-[15rem] xl:w-[22rem] 2xl:w-[29rem] h-[38px] pl-10 px-4 py-2 rounded-lg focus:outline-none hover:shadow-xl focus:border focus:border-[#131620]"
                    />
                    {/* search input popup start */}
                    {isSearchPopupOpen && (
                        <div
                            ref={searchRef}
                            className="w-[12rem] lg:w-[15rem] xl:w-[21.8rem] 2xl:w-[28.8rem] text-[16px] text-center absolute top-10 right-0 pl-2 py-2 bg-[#131620] text-white/70 rounded-b-md shadow-md border-b border-l border-r border-white/30 ">
                            {searchQuery === "" ? (
                                <p>Search here to find your favourite artists, venue, and event</p>
                            ) : (
                                <>
                                    {searching ? (
                                        <>
                                            <SearchLoading />
                                        </>
                                    ) : (
                                        <div className={searchResultArtists.length > 0 || searchResultVanues.length > 0 || searchResultEvents.length > 0 ? "h-[30rem] overflow-y-scroll" : ""}>
                                            {/* Display search results for artists */}
                                            {searchResultArtists.length > 0 && (
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Artists</span>
                                                    </p>
                                                    <ul className="text-white">
                                                        {searchResultArtists.map((artists: any) => (
                                                            <Link
                                                                to={`/artists/${artists.objectID}`}
                                                                className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                <div className="flex items-center gap-3">
                                                                    <img
                                                                        src={artists.photoURL}
                                                                        alt={artists.displayName}
                                                                        className="rounded-full w-10 h-10 object-cover"
                                                                    />
                                                                    <div>{artists.displayName}</div>
                                                                </div>
                                                                <div>
                                                                    <IoChevronForward className="text-xl" />
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {/* Display search results for venues */}
                                            {searchResultVanues.length > 0 && (
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Venues</span>
                                                    </p>
                                                    <div>
                                                        {searchResultVanues.map((venues: any) => (
                                                            <Link
                                                                to={`/${venues.city}/venues/${venues.slug}`}
                                                                className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                <div className="flex items-center gap-3">
                                                                    <img
                                                                        src={venues.images[0]}
                                                                        alt={venues.displayName}
                                                                        className="rounded-full w-10 h-10 object-cover"
                                                                    />
                                                                    <div>{venues.displayName}</div>
                                                                </div>
                                                                <div>
                                                                    <IoChevronForward className="text-xl" />
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </div>
                                                </div>
                                            )}

                                            {/* Display search results for events */}
                                            {searchResultEvents.length > 0 && (
                                                <div>
                                                    <p className="text-2xl font-normal leading-none flex items-start justify-start">
                                                        <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Events</span>
                                                    </p>
                                                    <ul>
                                                        {searchResultEvents.map((events: any) => (
                                                            <Link
                                                                to={`/${city}/${events.path}`}
                                                                className="text-white flex items-center justify-between shadow-eventsDetailPageVip my-3 py-2 px-2 rounded-md">
                                                                <div className="flex items-center gap-3">
                                                                    <img
                                                                        src={events.images[0]}
                                                                        alt={events.displayName}
                                                                        className="rounded-full w-10 h-10 object-cover"
                                                                    />
                                                                    <div>{events.displayName}</div>
                                                                </div>
                                                                <div>
                                                                    <IoChevronForward className="text-xl" />
                                                                </div>
                                                            </Link>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}

                                            {/* Display message when no results are found */}
                                            {searchResultArtists.length === 0 && searchResultVanues.length === 0 && searchResultEvents.length === 0 && <p>No results found.</p>}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
                {/* search input popup end */}
                {/* Button */}
                {user ? (
                    <>
                        <button
                            onClick={toggleDropdown}
                            className="relative flex items-center gap-1 bg-primary text-white font-medium px-3 py-2 rounded-md group overflow-hidden">
                            <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-0"></span>
                            <span className="z-50">
                                <CgProfile className="w-5 h-5" />
                            </span>
                            <span className="z-50 text-sm">Profile</span>
                            <IoChevronDown className="z-50 text-sm" />
                        </button>
                        <div
                            ref={dropdownMenuRef}
                            id="dropdown-menu"
                            className={`bg-[#0A0D16] py-2 px-2 w-52 origin-top-right absolute top-[65px] right-6 mt-2 rounded-md shadow-lg border border-white/30 ring-opacity-5 ${
                                isDropdownOpen ? "" : "hidden"
                            }`}>
                            <div className="flex flex-col items-center space-y-2">
                                <img
                                    src={data?.photoURL}
                                    alt={data?.displayName}
                                    className="w-28 h-28 rounded-full border-white/30 border"
                                />
                                <div className="text-center font-semibold pt-3">{data?.displayName}</div>
                                <Link
                                    to="/profile"
                                    onClick={() => setIsDropdownOpen(false)}
                                    className="relative w-full flex items-center justify-center px-4 py-2 text-sm text-white rounded-md bg-primary group overflow-hidden">
                                    <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-10"></span>
                                    <span className="z-50">Profile</span>
                                </Link>
                                <button
                                    onClick={() => {
                                        setUser(null);
                                        setIsDropdownOpen(false);
                                    }}
                                    className="relative flex items-center gap-1 w-full justify-center px-4 py-2 text-sm text-white rounded-md bg-primary group overflow-hidden">
                                    <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-10"></span>
                                    <span className="z-50">Log Out</span>
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <Link
                        to="/login"
                        className="relative flex items-center gap-1 bg-primary text-white font-medium px-3 py-2 rounded-md group overflow-hidden">
                        <span className="absolute left-0 w-0 group-hover:w-full transition-all ease-out duration-300 h-full bg-[#3673FF] z-0"></span>
                        <span className="z-50">
                            <CgProfile className="w-5 h-5" />
                        </span>
                        <span className="z-50 text-sm">Log In / Sign Up</span>
                    </Link>
                )}
            </div>
            {/* 2nd div for search input with button end */}
            <div className="lg:hidden">
                <NavbarForMobile />
            </div>
        </nav>
    );
}
