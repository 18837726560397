import { FirebaseStorage, IVanues } from "../../../../lib/firebase/storage";
import VenueCardData from "../VenueCardData";
import { VenuesDetailSlider } from "../../components";
import { useQuery } from "react-query";

type Props = {
    data?: IVanues;
};
export default function VenueUpcoming({ data }: Props) {
    const events = useQuery({
        queryKey: ["venuesbyeventid"],
        queryFn: () => FirebaseStorage.getEventByVanueId(data?.id as string),
        enabled: !!data?.id,
    });

    return (
        <div className="w-full">
            <div className="container">
                <div className="w-full flex gap-5 lg:p-10 p-3 justify-center">
                    <div className="w-full lg:w-8/12 flex flex-col gap-8">
                        <div className="hidden lg:flex justify-between ">
                            <p className="lg:text-4xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Upcoming Events</span>
                            </p>
                            <div className="hidden lg:block">{/* <VenueDetailCalendar /> */}</div>
                        </div>
                        <div className="lg:hidden flex justify-between rounded-lg text-white">{data?.description}</div>
                        {!events.isLoading && <VenueCardData events={events.data} />}
                    </div>
                    <div className="hidden lg:block w-full lg:w-4/12">
                        <div className="flex flex-col gap-3">
                            <p className="lg:text-4xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Details</span>
                            </p>
                            <p className="text-white font-bold">Timing</p>
                            <div className="text-white">
                                {data?.mondayOpeningHours && (
                                    <>
                                        <p>Monday</p>
                                        <span>
                                            {data?.mondayOpeningHours}-{data?.mondayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.tuesdayOpeningHours && (
                                    <>
                                        <p>Tuesday</p>
                                        <span>
                                            {data?.tuesdayOpeningHours}-{data?.tuesdayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.wednesdayOpeningHours && (
                                    <>
                                        <p>Wednesday</p>
                                        <span>
                                            {data?.wednesdayOpeningHours}-{data?.wednesdayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.thursdayOpeningHours && (
                                    <>
                                        <p>Thursday</p>
                                        <span>
                                            {data?.thursdayOpeningHours}-{data?.thursdayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.fridayOpeningHours && (
                                    <>
                                        <p>Friday</p>
                                        <span>
                                            {data?.fridayOpeningHours}-{data?.fridayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.saturdayOpeningHours && (
                                    <>
                                        <p>Saturday</p>
                                        <span>
                                            {data?.saturdayOpeningHours}-{data?.saturdayClosingHours}
                                        </span>
                                    </>
                                )}
                                {data?.sundayOpeningHours && (
                                    <>
                                        <p>Sunday</p>
                                        <span>
                                            {data?.sundayOpeningHours}-{data?.sundayClosingHours}
                                        </span>
                                    </>
                                )}
                            </div>

                            <h1 className="lg:text-3xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Description</span>
                            </h1>

                            <div className="flex flex-col gap-3">
                                <p className="font-normal text-[#B0B0B6] text-small">{data?.description}</p>
                            </div>
                            <VenuesDetailSlider data={data} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
