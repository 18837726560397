import { useQuery } from "react-query";
import TextInput from "../../components/TextInput/TextInput";
import CountryNumber from "../../components/CountryNumber";
import * as yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../../providers/Authentication";
import { FirebaseStorage } from "../../lib/firebase/storage";
import { useEffect } from "react";
import ProfileHeader from "./header";
import Select from "react-select";
import { years } from "./Years";
import _has from "lodash/has";
import ProfileSkeleton from "../../components/ProfileSkeleton/ProfileSkeleton";

const validationSchema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  DOB: yup.string().required("Birth Year Required"),
  gender: yup.string().required("Gender is required"),
  phoneNumber: yup.string(),
  instagram: yup
    .string()
    .test("is-instagram-url", "Invalid Instagram URL", (value) => {
      if (!value) return true;
      return /^https:\/\/instagram\.com\//.test(value);
    }),
  facebook: yup
    .string()
    .test("is-facebook-url", "Invalid Facebook URL", (value) => {
      if (!value) return true; // Return true if the field is empty
      return /^https:\/\/facebook\.com\//.test(value);
    }),
  pinterest: yup
    .string()
    .test("is-pinterest-url", "Invalid Pinterest URL", (value) => {
      if (!value) return true;
      return /^https:\/\/pinterest\.com\//.test(value);
    }),
  telegram: yup
    .string()
    .test("is-telegram-url", "Invalid Telegram URL", (value) => {
      if (!value) return true;
      return /^https?:\/\/t\.me\/[a-zA-Z0-9_]{5,}/.test(value);
    }),
  twitter: yup
    .string()
    .test("is-twitter-url", "Invalid Twitter URL", (value) => {
      if (!value) return true;
      return /^https?:\/\/twitter\.com\//.test(value);
    }),
  discord: yup
    .string()
    .test("is-discord-url", "Invalid Discord URL", (value) => {
      if (!value) return true;
      return /^https:\/\/discord\.gg\/[a-zA-Z0-9]+/.test(value);
    }),
});

export default function Profile() {
  const { user, setUser } = useAuth();

  const { data, isLoading, refetch } = useQuery("profile", async () => {
    return await FirebaseStorage.getCurrentUser(user.uid);
  });

  let initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    DOB: "",
    gender: "",
    phoneNumber: "",
    instagram: "",
    facebook: "",
    pinterest: "",
    telegram: "",
    twitter: "",
    discord: "",
  };
  const { setValues, handleChange, values, handleSubmit, errors, touched } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit(values, firmikHelper) {
        FirebaseStorage.updateProfile(user.uid, values, refetch);
      },
    });
  const handleImage = async (e: any) => {
    const file = e.target.files[0];
    await FirebaseStorage.updateProfileImage(user.uid, file, refetch);
  };
  const genders = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];
  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        firstName: data?.firstName || "",
        lastName: data.lastName || "",
        email: data.email,
        DOB: data.DOB || "",
        gender: data.gender || "Other",
        phoneNumber: data.phoneNumber || "",
        instagram: data.instagram || "",
        facebook: data.facebook || "",
        pinterest: data.pinterest || "",
        telegram: data.telegram || "",
        twitter: data.twitter || "",
        discord: data.discord || "",
      });
    }
  }, [isLoading, data, setValues]);

  if (isLoading) {
    return (
      <div>
        <ProfileSkeleton />
      </div>
    );
  }
  return (
    <div className="bg-black">
      <div className="max-w-screen-xl mx-auto">
        <ProfileHeader />
        <div className="flex items-center justify-between mb-5 pt-10">
          <div className="px-4 lg:px-5 text-3xl text-center lg:text-left">
            <span className="hidden lg:inline-block style_mainTitle__y3_rr style_gradientToBlue__bfJnC">
              Profile
            </span>
          </div>
          <button
            onClick={() => setUser(null)}
            className="hidden md:mr-5 md:flex rounded-md border border-red-400 py-3 px-6 text-red-400"
          >
            Log Out
          </button>
        </div>
        <div className="ml-5 md:ml-5 rounded-md w-48 h-48 md:w-52 md:h-52 bg-white mb-5 overflow-hidden relative">
          <label htmlFor="image" className="rounded-full cursor-pointer">
            <img
              src={data?.photoURL}
              alt={data?.displayName}
              className="w-full h-full object-cover"
            />
            <input
              type="file"
              className="hidden"
              id="image"
              onChange={handleImage}
            />
          </label>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto pb-20">
        <div className=" dark:bg-gray-700 shadow rounded-lg p-6">
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="">
                <label
                  htmlFor="firstName"
                  className="text-white text-lg block mb-2"
                >
                  First Name
                </label>
                <TextInput
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  className={`border ${
                    touched.firstName && errors.firstName
                      ? "border-red-400"
                      : "border-white/30"
                  } p-2 rounded w-full bg-transparent ${
                    touched.firstName && errors.firstName
                      ? "active:border-red-400"
                      : "active:border-white/40"
                  } ${
                    touched.firstName && errors.firstName
                      ? "focus:border-red-400"
                      : "focus:border-white/40"
                  } active:outline-none focus:outline-none`}
                  value={values.firstName}
                  name="firstName"
                  onChange={handleChange}
                />
                {touched.firstName && errors.firstName && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="text-white text-lg block mb-2"
                >
                  Last Name
                </label>
                <TextInput
                  id="lastName"
                  type="text"
                  placeholder="Akcora"
                  className={`border ${
                    touched.lastName && errors.lastName
                      ? "border-red-400"
                      : "border-white/30"
                  } p-2 rounded w-full bg-transparent ${
                    touched.lastName && errors.lastName
                      ? "active:border-red-400"
                      : "active:border-white/40"
                  } ${
                    touched.lastName && errors.lastName
                      ? "focus:border-red-400"
                      : "focus:border-white/40"
                  } active:outline-none focus:outline-none`}
                  value={values.lastName}
                  name="lastName"
                  onChange={handleChange}
                />
                {touched.lastName && errors.lastName && (
                  <p className="text-red-400 text-sm mt-1">{errors.lastName}</p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="text-white text-lg block mb-2">
                Email*
              </label>
              <TextInput
                id="email"
                type="email"
                placeholder="example@gmail.com"
                className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                value={values.email}
                name="email"
                onChange={handleChange}
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="month"
                  className="text-white text-lg block mb-2"
                >
                  Birth Year
                </label>
                <Select
                  options={years}
                  defaultValue={
                    !isLoading && data && _has(data, "DOB")
                      ? years.find((y) => y.value === data.DOB)
                      : years[0]
                  }
                  onChange={({ value }: any) =>
                    setValues({ ...values, DOB: value })
                  }
                  name="Years"
                />

                {touched.DOB && errors.DOB && (
                  <p className="text-red-400 text-sm mt-1">{errors.DOB}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="gender"
                  className="text-white text-lg block mb-2"
                >
                  Gender
                </label>
                <Select
                  options={genders}
                  defaultValue={
                    !isLoading && data && _has(data, "gender")
                      ? genders.find((y) => y.value === data.gender)
                      : genders[0]
                  }
                  onChange={({ value }: any) =>
                    setValues({ ...values, gender: value })
                  }
                  name="Gender"
                />
                {touched.gender && errors.gender && (
                  <p className="text-red-400 text-sm mt-1">{errors.gender}</p>
                )}
              </div>
              {/* country code input */}
              <div className="mb-4">
                <label
                  htmlFor="country"
                  className="text-white text-lg block mb-2"
                >
                  Phone Number
                </label>
                <div
                  className={`border ${
                    touched.phoneNumber && errors.phoneNumber
                      ? "border-red-400"
                      : "border-white/30"
                  } rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none`}
                >
                  <CountryNumber
                    handleChange={(value: any) =>
                      setValues({ ...values, phoneNumber: value })
                    }
                    phoneNumber={values.phoneNumber}
                  />
                </div>
                {touched.phoneNumber && errors.phoneNumber && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="instagramURL"
                  className="text-white text-lg block mb-2"
                >
                  Instagram URL
                  <span className="text-xs text-gray-400 pl-4">
                    (https://instagram.com/username)
                  </span>
                </label>
                <TextInput
                  id="InstagramURL"
                  type="text"
                  placeholder="Instagram"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="instagram"
                  onChange={handleChange}
                  value={values.instagram}
                />
                {touched.instagram && errors.instagram && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.instagram}
                  </p>
                )}
              </div>
              {/* facebook url */}
              <div className="mb-4">
                <label
                  htmlFor="fbURL"
                  className="text-white text-lg block mb-2"
                >
                  Facebook URL{" "}
                  <span className="text-xs text-gray-400 pl-4">
                    (https://facebook.com/username)
                  </span>
                </label>
                <TextInput
                  id="fbURL"
                  type="text"
                  placeholder="facebook"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="facebook"
                  onChange={handleChange}
                  value={values.facebook}
                />
                {touched.facebook && errors.facebook && (
                  <p className="text-red-400 text-sm mt-1">{errors.facebook}</p>
                )}
              </div>
            </div>
            {/* pinsterst url */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="pinstersturl"
                  className="text-white text-lg block mb-2"
                >
                  Pinterst URL
                  <span className="text-xs text-gray-400 pl-4">
                    (https://pinterest.com/username)
                  </span>
                </label>
                <TextInput
                  id="pinstersturl"
                  type="text"
                  placeholder="Pinsterst"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="pinterest"
                  onChange={handleChange}
                  value={values.pinterest}
                />
                {touched.pinterest && errors.pinterest && (
                  <p className="text-red-400 text-sm mt-1">
                    {errors.pinterest}
                  </p>
                )}
              </div>
              {/* telegram url */}
              <div className="mb-4">
                <label
                  htmlFor="telegramurl"
                  className="text-white text-lg block mb-2"
                >
                  Telegram URL
                  <span className="text-xs text-gray-400 pl-4">
                    (https://telegram.com/username)
                  </span>
                </label>
                <TextInput
                  id="telegramurl"
                  type="text"
                  placeholder="Telegram"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="telegram"
                  onChange={handleChange}
                  value={values.telegram}
                />
                {touched.telegram && errors.telegram && (
                  <p className="text-red-400 text-sm mt-1">{errors.telegram}</p>
                )}
              </div>
            </div>
            {/* twitter url */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
              <div className="mb-4">
                <label
                  htmlFor="twitterurl"
                  className="text-white text-lg block mb-2"
                >
                  Twitter URL
                  <span className="text-xs text-gray-400 pl-4">
                    (https://twitter.com/username)
                  </span>
                </label>
                <TextInput
                  id="twitterurl"
                  type="text"
                  placeholder="Twitter"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="twitter"
                  onChange={handleChange}
                  value={values.twitter}
                />
                {touched.twitter && errors.twitter && (
                  <p className="text-red-400 text-sm mt-1">{errors.twitter}</p>
                )}
              </div>
              {/* discord url */}
              <div className="mb-4">
                <label
                  htmlFor="discordurl"
                  className="text-white text-lg block mb-2"
                >
                  Discord URL
                  <span className="text-xs text-gray-400 pl-4">
                    (https://discord.com/username)
                  </span>
                </label>
                <TextInput
                  id="discordurl"
                  type="text"
                  placeholder="Discord"
                  className="border border-white/30 p-2 rounded w-full bg-transparent active:border-white/40 focus:border-white/40 active:outline-none focus:outline-none"
                  name="discord"
                  onChange={handleChange}
                  value={values.discord}
                />
                {touched.discord && errors.discord && (
                  <p className="text-red-400 text-sm mt-1">{errors.discord}</p>
                )}
              </div>
            </div>
            {/* upadate button */}
            <div className="flex items-end justify-end">
              <button
                type="submit"
                className="w-full md:w-auto px-5 py-5 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none transition-colors"
              >
                Update Profile
              </button>
            </div>
            {/* logout btn for mobile */}
            <div className="md:hidden mt-2 flex items-end justify-end">
              <button
                type="button"
                id="theme-toggle"
                onClick={() => setUser(null)}
                className="w-full md:w-auto px-5 py-5 rounded-lg border border-red-500 text-red-500"
              >
                Log Out
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
