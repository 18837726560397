export const EventsIcon = ({ fill }: { fill: string }) => (
    <svg
        width="33"
        height="32"
        viewBox="0 0 33 32"
        fill="#BDCAE8"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M24.2282 4H8.54193C6.37611 4 4.62036 5.91536 4.62036 8.27807V23.2513C4.62036 25.6141 6.37611 27.5294 8.54193 27.5294H24.2282C26.394 27.5294 28.1498 25.6141 28.1498 23.2513V8.27807C28.1498 5.91536 26.394 4 24.2282 4Z"
            fill={fill === "#1363FF" ? "#1363FF" : "#BDCAE8"}
        />
        <path
            d="M13.013 8.98047H10.5032C9.81013 8.98047 9.24829 9.54231 9.24829 10.2354V12.7452C9.24829 13.4382 9.81013 14.0001 10.5032 14.0001H13.013C13.7061 14.0001 14.2679 13.4382 14.2679 12.7452V10.2354C14.2679 9.54231 13.7061 8.98047 13.013 8.98047Z"
            fill={fill === "#1363FF" ? "#fff" : "#5D7098"}
        />
    </svg>
);

export const VenuesIcon = ({ fill }: { fill: string }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="#5D7098"
            xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1259_155972)">
                <path
                    d="M16.3671 6.6665H8.36711C6.89377 6.6665 5.70044 7.85984 5.70044 9.33317V22.6665C5.70044 24.1398 6.89377 25.3332 8.36711 25.3332H16.3671"
                    fill={fill === "#1363FF" ? "#fff" : "#5D7098"}
                />
                <path
                    d="M18.2748 27.936L24.9414 26.464C26.1641 26.1946 27.0334 25.1106 27.0334 23.86V8.13995C27.0334 6.88929 26.1641 5.80529 24.9414 5.53595L18.2748 4.06395C16.6108 3.69595 15.0334 4.96262 15.0334 6.66795V25.3333C15.0334 27.0373 16.6108 28.304 18.2748 27.936Z"
                    fill={fill === "#1363FF" ? "#1363FF" : "#BDCAE8"}
                />
                <path
                    d="M19.0334 14.6665V17.3332"
                    stroke={fill === "#1363FF" ? "#fff" : "#151515"}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_1259_155972">
                    <rect
                        width="32"
                        height="32"
                        fill="white"
                        transform="translate(0.367432)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export const BookingsIcon = ({ fill }: { fill: string }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 8.33325H7.66667C6.19333 8.33325 5 9.52659 5 10.9999V26.9999C5 28.4733 6.19333 29.6666 7.66667 29.6666H19.6667C21.14 29.6666 22.3333 28.4733 22.3333 26.9999V25.6666"
                fill="#5D7098"
            />
            <path
                d="M23.6676 25.6667H11.6676C10.1943 25.6667 9.00098 24.4733 9.00098 23V5.66667C9.00098 4.19333 10.1943 3 11.6676 3H23.6676C25.141 3 26.3343 4.19333 26.3343 5.66667V23C26.3343 24.4733 25.141 25.6667 23.6676 25.6667Z"
                fill={fill === "#1363FF" ? "#1363FF" : "#BDCAE8"}
            />
            <circle
                cx="14.2553"
                cy="9.27456"
                r="1.41176"
                fill={fill === "#1363FF" ? "#fff" : "#151515"}
            />
            <circle
                cx="18.0192"
                cy="9.27456"
                r="1.41176"
                fill={fill === "#1363FF" ? "#fff" : "#151515"}
            />
            <circle
                cx="21.7846"
                cy="9.27456"
                r="1.41176"
                fill={fill === "#1363FF" ? "#fff" : "#151515"}
            />
        </svg>
    );
};

export const UserIcon = ({ fill }: { fill: string }) => {
    return (
        <svg
            width="27"
            height="26"
            viewBox="0 0 27 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect
                x="0.617188"
                width="25.5"
                height="25.5"
                rx="12.75"
                fill={fill === "#1363FF" ? "#1363FF" : "#BfCAE8"}
            />
            <path
                d="M16.3873 10.0409C16.3968 10.3083 16.3968 10.5772 16.3873 10.8447C16.3582 11.6575 16.015 12.4273 15.4298 12.9921C14.8447 13.557 14.0632 13.8728 13.2499 13.8731V13.8731C12.4367 13.8728 11.6552 13.557 11.0701 12.9921C10.4849 12.4273 10.1417 11.6575 10.1126 10.8447C10.1031 10.5772 10.1031 10.3083 10.1126 10.0408C10.1417 9.22808 10.4849 8.45828 11.0701 7.89344C11.6552 7.32861 12.4367 7.01279 13.2499 7.01245V7.01245C14.0632 7.01279 14.8447 7.32862 15.4298 7.89346C16.015 8.45831 16.3582 9.22812 16.3873 10.0409V10.0409Z"
                fill={fill === "#1363FF" ? "#fff" : "#5D7098"}
            />
            <path
                d="M8.58594 17.3719C10.0453 16.6896 11.6367 16.3359 13.2477 16.3359C14.8587 16.3359 16.45 16.6896 17.9094 17.3719"
                stroke={fill === "#1363FF" ? "#fff" : "#5D7098"}
                strokeWidth="1.55391"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
