import React from "react";

export default function SkeletonEvent() {
  const cardData = Array.from({ length: 6 }, (_, index) => index);

  return (
    <div className="w-full">
      <div className="flex flex-col gap-5">
        {cardData.map((item) => (
          <div className="hidden xl:block bg-white lg:h-[215px] h-auto rounded-lg shadow-md animate-pulse">
            <div className="flex flex-col gap-5" key={item}>
              <div className="flex gap-3">
                <div className="w-full lg:w-[150px] h-[150px] bg-gray-300 rounded mb-2"></div>
                <div className="flex flex-col gap-3 pt-5">
                  <div className="w-full lg:w-[200px] h-4 bg-gray-300 rounded mb-2"></div>
                  <div className="w-full lg:w-[150px] h-4 bg-gray-300 rounded mb-2"></div>
                </div>
              </div>
              <div className="flex gap-1 ml-2">
                <div className="w-full lg:w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                <div className=" w-full lg:w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                <div className=" w-full lg:w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                <div className=" w-full lg:w-[200px] h-5 bg-gray-300 rounded mb-2"></div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* /// mobile screen /// */}
      <div className="flex flex-col gap-5">
        {cardData.map((item) => (
          <div className="xl:hidden bg-white lg:h-[150px] h-auto rounded-lg shadow-md animate-pulse">
            <div className="flex gap-3" key={item}>
              <div className=" w-[130px] lg:h-[150px] h-auto bg-gray-300 rounded"></div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1 lg:pt-4 pt-3">
                  <div className="w-full lg:w-[200px] h-4 bg-gray-300 rounded mb-2"></div>
                  <div className="w-full lg:w-[150px] h-4 bg-gray-300 rounded mb-2"></div>
                </div>
                <div className="w-full lg:w-[170px] h-5 bg-gray-300 rounded mb-2"></div>
                <div className="flex gap-1 ">
                  <div className="w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                  <div className=" w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                  <div className=" w-[70px] h-5 bg-gray-300 rounded mb-2"></div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
