const loadingColorHex = "#161f3b";
export default function ArtistsLoading() {
    return (
        <div className="bg-[#0A0D16] h">
            <div className="max-w-screen-xl mx-auto py-10">
                <div className="px-4 lg:px-0 flex items-center justify-between text-3xl text-center lg:text-left lg:ml-10">
                    <span className={`animate-pulse w-32 h-10 bg-[${loadingColorHex}] rounded-full`}></span>
                    <span className={`animate-pulse w-48 h-10 bg-[${loadingColorHex}] rounded-full mr-14`}></span>
                </div>
                <div className="flex flex-wrap justify-center gap-3 mt-10">
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23].map((item) => (
                        <div
                            className={`bg-[${loadingColorHex}] w-40 h-40 lg:w-52 lg:h-52 xl:w-48 xl:h-48 rounded-full animate-pulse`}
                            key={item}></div>
                    ))}
                </div>
            </div>
        </div>
    );
}
