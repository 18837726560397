import React from "react";

export default function EventDetailsSkeleton() {
    return (
        <div className="bg-[#0A0D16] h-screen px-5 lg:px-10 lg:pt-10">
            <div className="max-w-screen-2xl mx-auto space-y-8 animate-pulse md:space-y-0 md:space-x-8 rtl:space-x-reverse md:flex flex-col lg:flex-row">
                <div className="flex items-center justify-center lg:!w-[100%] h-[320px] bg-gray-300 rounded-lg dark:bg-gray-700">
                    <svg
                        className="w-10 h-10 text-gray-200 dark:text-gray-600"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 18">
                        <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
                    </svg>
                </div>
                <div className="w-full pt-6">
                    <div className="h-10 bg-gray-200 dark:bg-gray-700 max-w-[550px] mb-2 mt-5" />
                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[150px] mb-2" />
                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[450px] mb-2" />
                    <div className="h-6 bg-gray-200 dark:bg-gray-700 max-w-[460px] mb-2" />
                    <div className="flex items-center gap-x-10 lg:justify-between">
                        <div className="h-8 bg-gray-200 dark:bg-gray-700 w-full lg:w-full" />
                        <div className="flex items-center gap-2">
                            <div className="h-8 bg-gray-200 dark:bg-gray-700 w-12" />
                            <div className="h-8 bg-gray-200 dark:bg-gray-700 w-12" />
                        </div>
                    </div>
                </div>
                <span className="sr-only">Loading...</span>
            </div>

            <div className="hidden lg:flex items-center gap-x-[28rem] max-w-screen-2xl mx-auto mt-10 animate-pulse">
                <div className="h-8 bg-gray-200 dark:bg-gray-700 w-full lg:w-72" />
                <div className="flex items-center gap-2">
                    <div className="h-8 bg-gray-200 dark:bg-gray-700 w-48 rounded-sm" />
                </div>
            </div>
            {/* for upcoming events text */}
            <div class="rounded-lg animate-pulse w-full mt-2 max-w-screen-2xl mx-auto">
                <div className="flex flex-col lg:flex-row items-center space-x-4 py-2">
                    <div className="rounded-md bg-gray-300 h-14 w-full lg:w-6/12 lg:h-32" />
                    <div className="flex-1 space-y-4 py-1">
                        <div className="h-4 bg-gray-300 rounded w-1/6" />
                        <div className="space-y-2">
                            <div className="h-28 bg-gray-300 rounded" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
