import { Artist, IEvents } from "../../../../lib/firebase/storage";
import EventDetailCard from "../VenuesDetailCard";
type Props = {
    data?: Artist;
};

export default function UpcomingEventsArtists({ data }: Props) {
    return (
        <div className="w-full">
            <div className="container">
                <div className="w-full flex gap-5 lg:p-10 p-3 justify-center flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-8/12 flex flex-col gap-8">
                        <EventDetailCard events={data?.events as IEvents[]} />
                    </div>
                    <div className="block w-full lg:w-4/12 mb-24 lg:mb-0">
                        <div className="flex flex-col gap-3">
                            <p className="lg:text-4xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Details</span>
                            </p>
                            <h1 className="lg:text-3xl text-2xl font-normal leading-none">
                                <span className="style_mainTitle__y3_rr style_gradientToOrange__4cSBG">Description</span>
                            </h1>

                            <div className="flex flex-col gap-3">
                                <p className="font-normal text-[#B0B0B6] text-small">{data?.description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
