import { Link, useLocation } from "react-router-dom";
import { IVanues } from "../../../../lib/firebase/storage";
import _isArray from "lodash/isArray";

function convertFullBarToFullBar(inputString: string) {
    let words = inputString.split(" ");
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
    }
    let result = words.join(" ");
    return result;
}
export default function VenuesCard({ data }: { data: IVanues[] }) {
    const location = useLocation();
    function getFirstTwoTrueProperties(obj: any) {
        const trueProperties = Object.entries(obj)
            .filter(([key, value]) => value === true)
            .map(([key]) => key);

        return trueProperties.slice(0, 2);
    }
    return (
        <div className="w-full lg:px-12 px-0">
            <div className="contaner mx-auto lg:p-10 p-5">
                <div className="w-full text-3xl text-center lg:text-left mb-5">
                    <span className="hidden lg:inline-block style_mainTitle__y3_rr style_gradientToBlue__bfJnC">Venues</span>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 relative">
                    {_isArray(data) &&
                        data.map((item) => (
                            <Link
                                key={item.id}
                                to={`${location.pathname}/${item.slug}`}
                                className="col-span-1 relative rounded-lg flex-col shadow-eventsDetailPageVip bg-[#1C1E2B] w-full cursor-pointer">
                                <div className="">
                                    <img
                                        src={item.images[0]}
                                        alt="event"
                                        className="w-full h-[191px] object-cover rounded-t-lg"
                                    />
                                </div>
                                <button className=" w-full rounded-b-lg px-3 text-white bg-[#1C1E2B] p-3 flex justify-between overflow-hidden">
                                    <div className="flex items-center gap-3">
                                        <img
                                            src={item.images[0]}
                                            alt="event"
                                            className="rounded-full object-cover w-[40px] h-[40px]"
                                        />
                                        <div className="flex flex-col items-start">
                                            <span className="text-md font-semibold">{item.displayName}</span>
                                            <p className="flex gap-1 items-center text-[#B0B0B6]">
                                                <span className="text-sm font-bold">{convertFullBarToFullBar(getFirstTwoTrueProperties(item)[0])}</span>
                                                <span className="border-r h-5 border-[#7A7F8C]"></span>
                                                <span className="text-sm"> {convertFullBarToFullBar(getFirstTwoTrueProperties(item)[1])}</span>
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </Link>
                        ))}
                </div>
            </div>
        </div>
    );
}
