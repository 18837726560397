import { toast } from "react-toastify";
import { generateSixDigitId } from "../generateID";
import { fetchLocation } from "../location";
import { db, getDoc, collection, setDoc, doc, updateDoc, ref, storage, uploadBytesResumable, getDownloadURL } from "./firebaseConfig";
import _has from "lodash/has";
import slugify from "slugify";
import _pickBy from "lodash/pickBy";
import moment from "moment";
import _ from "lodash";
import { getDocs, query, where, deleteDoc, limit } from "firebase/firestore/lite";
export interface IEvents {
    id: string;
    eventFB: string;
    eventInsta: string;
    guestList: string;
    images: string[];
    price: number;
    slugify: string;
    startingDates: Date[] | Date;
    tickets: string;
    updatedAt: Date;
    vanue: string;
    vanueData: IVanues;
    vipTable: string;
    displayName: string;
    artists: Artist[];
    artistdj: string[];
    date: string;
    description: string;
    timestamp: number;
}
export interface IVanues {
    address: string;
    adminId: string;
    capacity: string;
    city: string;
    concertVenue: boolean;
    country: string;
    createdAt: string;
    danceFloor: boolean;
    dayClub: boolean;
    description: string;
    discord: string;
    displayName: string;
    dressCode: string;
    email: string;
    facebook: string;
    food: boolean;
    fullBar: boolean;
    fullRestaurant: boolean;
    id: string;
    images: string[];
    instagram: string;
    limitation: string;
    linkedIn: string;
    liveDJ: boolean;
    liveMusic: boolean;
    nightClub: boolean;
    outdoor: boolean;
    phoneNumber: string;
    pinterest: string;
    pool: boolean;
    price: string;
    rooftop: boolean;
    smokingArea: boolean;
    state: string;
    telegram: string;
    tiktok: string;
    twitter: string;
    updatedAt: string;
    website: string;
    youtube: string;
    slug: string;
    mondayOpeningHours?: string;
    mondayClosingHours?: string;
    tuesdayOpeningHours?: string;
    tuesdayClosingHours?: string;
    wednesdayOpeningHours?: string;
    wednesdayClosingHours?: string;
    thursdayOpeningHours?: string;
    thursdayClosingHours?: string;
    fridayOpeningHours?: string;
    fridayClosingHours?: string;
    saturdayOpeningHours?: string;
    saturdayClosingHours?: string;
    sundayOpeningHours?: string;
    sundayClosingHours?: string;
}
export interface Artist {
    adminId: string;
    description: string;
    discord: string;
    displayName: string;
    email: string;
    facebook: string;
    genre: string[]; // Assuming genre is an array of strings
    id: string;
    instagram: string;
    linkedin: string;
    phone: string;
    photoURL: string;
    pinterest: string;
    telegram: string;
    tiktok: string;
    twitter: string;
    website: string;
    youtube: string;
    events?: IEvents[];
}

type DBUser = {
    firstName: string | null;
    lastName: string | null;
    instagram: string | null;
    facebook: string | null;
    twitter: string | null;
    discord: string | null;
    pinterest: string | null;
    telegram: string | null;
    gender: string | null;
    DOB: string | null;
    city: string;
    country: string;
    displayName: string;
    email: string;
    emailVerified: boolean;
    id: string;
    ip: string;
    lastCity: string;
    lastCountry: string;
    lastIp: string;
    lastState: string;
    latitude: number;
    longitude: number;
    phoneNumber: string | null;
    photoURL: string;
    state: string;
    uid: string;
    isArtist?: boolean;
    isVanue?: boolean;
    roles: string[];
};
type IUser = {
    uid: string;
    email: string;
    displayName: string;
    photoURL: string;
    emailVerified: boolean;
    phoneNumber: string | null;
    isInstagram?: boolean;
};
export class FirebaseStorage {
    static async createUser(data: IUser) {
        try {
            const userCollection = collection(db, "Users");
            const checkUser = await this.ifUserExits(data.uid);
            const location = await fetchLocation();
            let locationParams = {
                latitude: location?.location?.latitude,
                longitude: location?.location?.longitude,
                country: location?.location?.country?.name,
                state: location.location?.region?.name,
                city: location?.location?.city,
                ip: location?.ip,
                lastCountry: location?.location?.country?.name,
                lastState: location?.location?.region?.name,
                lastCity: location?.location?.city,
                lastIp: location?.ip,
            };
            if (checkUser) {
                const currentUser = await this.getCurrentUser(data.uid);
                const isHas = _has(currentUser, "country");
                if (isHas) {
                    //@ts-ignore
                    locationParams.lastCity = currentUser.city;
                    //@ts-ignore
                    locationParams.lastCountry = currentUser.country;
                    //@ts-ignore
                    locationParams.lastIp = currentUser.ip;
                    //@ts-ignore
                    locationParams.lastState = currentUser.state;
                    await updateDoc(doc(userCollection, data.uid), { ...locationParams });
                } else {
                    await updateDoc(doc(userCollection, data.uid), { ...locationParams });
                }
                console.log("User already exists");
                return;
            }
            let newData = { ...data, instagram: "" };
            if (data.isInstagram) {
                newData.instagram = `https://instagram.com/${data.displayName}`;
            }
            delete newData.isInstagram;
            await setDoc(doc(userCollection, data.uid), { ...newData, ...locationParams, id: data.uid });
            return true;
        } catch (error) {
            return false;
        }
    }

    static async ifUserExits(id: string) {
        const docRef = doc(db, "Users", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return true;
        } else {
            return false;
        }
    }
    static async getCurrentUser(userId: string): Promise<DBUser> {
        const docRef = doc(db, "Users", userId);
        const docSnap = await getDoc(docRef);
        return docSnap.data() as DBUser;
    }
    static async updateProfileImage(userId: string, file: File, refetch: any) {
        if (!file) return;
        const profileRef = ref(storage, `${userId}/${file.name}`);
        const response = uploadBytesResumable(profileRef, file);
        response.on(
            "state_changed",
            (snapshot) => {
                console.log("snapshot", snapshot);
            },
            (error) => {
                console.log("error", error);
            },
            () => {
                console.log("complete");
                getDownloadURL(response.snapshot.ref).then((downloadURL) => {
                    updateDoc(doc(db, "Users", userId), {
                        photoURL: downloadURL,
                    }).then(() => {
                        refetch();
                    });
                });
            }
        );
        return response;
    }
    static async updateProfile(userId: string, data: any, refetch: any) {
        await updateDoc(doc(db, "Users", userId), {
            ...data,
        }).then(() => {
            refetch();
            toast.success("Profile updated successfully");
        });
    }
    static async createArtist(data: any) {
        toast.loading("Creating artist...");
        const artistCollection = collection(db, "artists");
        const id = generateSixDigitId();
        const profileRef = ref(storage, `artists/${id}/${data.file.name}`);
        const response = uploadBytesResumable(profileRef, data.file);
        response.on(
            "state_changed",
            (snapshot) => {
                console.log("snapshot", snapshot);
            },
            (error) => {
                toast.dismiss();
                toast.error("Error creating artist");
                console.log("error", error);
            },
            () => {
                getDownloadURL(response.snapshot.ref).then((downloadURL) => {
                    let newData = { ...data };
                    delete newData.file;
                    let params = {
                        ...newData,
                        photoURL: downloadURL,
                        id: id,
                        createdAt: new Date().toDateString(),
                        updatedAt: new Date().toDateString(),
                    };
                    setDoc(doc(artistCollection, id), { ...params });
                    toast.dismiss();

                    toast("Artist created successfully");
                });
            }
        );
    }
    static async getArtists(pageNumner = 1): Promise<Artist[]> {
        const artistCollection = collection(db, "artists");
        const snapshot = await getDocs(artistCollection);
        const artists = snapshot.docs.map((doc) => doc.data());
        return artists as Artist[];
    }
    static async getArtist(id: string): Promise<Artist> {
        const docRef = doc(db, "artists", id);
        const docSnap = await getDoc(docRef);
        //get event of artist
        const eventCollection = collection(db, "events");
        const eventQuery = query(eventCollection, where("artistdj", "array-contains", id));
        const eventSnap = await getDocs(eventQuery);

        return { ...(docSnap.data() as Artist), events: eventSnap.docs.map((i) => ({ ...i.data(), id: i.id } as IEvents)) };
    }
    static async getArtistById(id: string) {
        const docRef = doc(db, "artists", id);
        const docSnap = await getDoc(docRef);
        return docSnap.data() as Artist;
    }
    static async artistRequest(data: any) {
        const artistRequests = collection(db, "artistRequests");
        const id = generateSixDigitId();
        try {
            await setDoc(doc(artistRequests, id), { ...data, id });
            toast.success("Request send successfully.");
        } catch (error) {
            toast.error("Opps something went wrong please try again");
        }
    }
    static async getArtistRequest(id: any) {
        const artistRequests = collection(db, "artistRequests");
        const result = query(artistRequests, where("uid", "==", id));
        const docs = await getDocs(result);
        return docs.docs.map((i) => i.data());
    }

    static async vanueRequest(data: any) {
        const vanueRequests = collection(db, "vanueRequests");
        const id = generateSixDigitId();
        try {
            await setDoc(doc(vanueRequests, id), { ...data, id });
            toast.success("Request send successfully.");
        } catch (error) {
            toast.error("Opps something went wrong please try again");
        }
    }
    static async getVanueRequest(id: any) {
        const vanueRequests = collection(db, "vanueRequests");
        const result = query(vanueRequests, where("uid", "==", id));
        const docs = await getDocs(result);
        return docs.docs.map((i) => i.data());
    }

    static async getVanueRequests() {
        const vanueRequests = collection(db, "vanueRequests");
        const docs = await getDocs(vanueRequests);
        return docs.docs.map((i) => i.data());
    }
    static async getArtistRequests() {
        const artistRequests = collection(db, "artistRequests");
        const docs = await getDocs(artistRequests);
        return docs.docs.map((i) => i.data());
    }

    static async updateArtistRequest(data: any) {
        await deleteDoc(doc(db, "artistRequests", data.id));
        await updateDoc(doc(db, "Users", data.uid), {
            isArtist: data.isArtist,
        });
        toast.success("Artist request updated successfully");
    }
    static async updateVanueRequest(data: any) {
        await deleteDoc(doc(db, "vanueRequests", data.id));
        await updateDoc(doc(db, "Users", data.uid), {
            isVanue: data.isVanue,
        });
        toast.success("Vanue request updated successfully");
    }
    static async createVanue(data: any, reset: any) {
        try {
            toast.loading("Creating vanue...");
            const vanues = collection(db, "vanues");
            const id = generateSixDigitId();

            let files = [];
            for (let index = 0; index < data.files.length; index++) {
                let file = data.files[index];
                let filesPromises = await this.uploadFile(file, "vanues");
                files.push(filesPromises);
            }
            let newData = { ...data };
            delete newData.files;
            let params = { ...newData, slug: slugify(data.displayName, { lower: true }), images: files, id, createAt: new Date().toDateString(), updatedAt: new Date().toDateString() };
            await setDoc(doc(vanues, id), { ...params, id: id });
            toast.dismiss();
            toast.success("Vanue created successfully");
            reset();
        } catch (error) {
            toast.dismiss();
            toast.error("Opps something went wrong please try again");
        }
    }
    static async uploadFile(file: File, type: string) {
        const profileRef = ref(storage, `${type}/${file.name}`);
        const response = uploadBytesResumable(profileRef, file);

        return new Promise((resolve, reject) => {
            response.on(
                "state_changed",
                (snapshot) => {
                    console.log("snapshot", snapshot);
                },
                (error) => {
                    reject(error);
                },
                () => {
                    getDownloadURL(response.snapshot.ref)
                        .then((downloadURL) => {
                            resolve(downloadURL);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                }
            );
        });
    }
    static async getVanues(city: string, filters: any): Promise<IVanues[]> {
        let conditions: any = [];
        const vanues = collection(db, "vanues");
        conditions.push(where("city", "==", city));
        let newFilter = _pickBy(filters, (i) => i === true);
        Object.keys(newFilter).forEach((i: any) => {
            conditions.push(where(i, "==", true));
        });
        const result = query(vanues, ...conditions);
        const snapshot = await getDocs(result);
        return snapshot.docs.map((doc) => doc.data()) as IVanues[];
    }
    static async getVanue(slug: string): Promise<IVanues> {
        const vanues = collection(db, "vanues");
        const result = query(vanues, where("slug", "==", slug));
        const doc = await getDocs(result);
        return doc.docs.map((doc) => doc.data())[0] as IVanues;
    }

    static async getVanuesByAdminId(id: string) {
        const vanues = collection(db, "vanues");
        const result = query(vanues, where("adminId", "==", id));
        const doc = await getDocs(result);
        return doc.docs.map((doc) => doc.data()) as IVanues[];
    }
    static async getArtistsByAdminId(id: string) {
        const vanues = collection(db, "artists");
        const result = query(vanues, where("adminId", "==", id));
        const doc = await getDocs(result);
        return doc.docs.map((doc) => doc.data()) as IVanues[];
    }

    static async createEvent(data: any, reset: any) {
        try {
            toast.loading("Creating event...");
            const events = collection(db, "events");

            //upload image
            let files = [];
            for (let index = 0; index < data.files.length; index++) {
                let file = data.files[index];
                let filesPromises = await this.uploadFile(file, "events");
                files.push(filesPromises);
            }

            let newData = { ...data, images: files, slugify: slugify(data.displayName, { lower: true }), createdAt: new Date(), updatedAt: new Date() };
            delete newData.files;

            let promises: any = [];
            newData.startingDates?.forEach((date: any) => {
                promises.push(
                    setDoc(doc(events), {
                        ...newData,
                        startingDates: new Date(date),
                        date: moment(date).format("YYYY-MM-DD hh:mm:ss"),
                        timestamp: new Date(date).getTime(),
                    })
                );
            });
            await Promise.all(promises);
            reset();
            toast.dismiss();
            toast.success("Event created successfully");
        } catch (error) {
            console.log({ error });
            toast.dismiss();
            toast.error("Opps something went wrong please try again");
        }
    }
    static async updateEvent(data: any, reset: any) {
        try {
            toast.loading("Updating event...");
            const events = collection(db, "events");
            let newData = { ...data, images: data.images, slugify: slugify(data.displayName, { lower: true }), updatedAt: new Date() };
            //upload image
            if (data.files) {
                let files = [];
                for (let index = 0; index < data.files.length; index++) {
                    let file = data.files[index];
                    let filesPromises = await this.uploadFile(file, "events");
                    files.push(filesPromises);
                }
                newData.images = [...data.images, ...files];
            }

            delete newData.files;

            await updateDoc(doc(events, data.id), {
                ...newData,
                startingDates: new Date(newData.startingDates[0]),
                timestamp: new Date(newData.startingDates[0]).getTime(),
                date: moment(newData.startingDates[0]).format("YYYY-MM-DD hh:mm:ss"),
            });
            reset();
            toast.dismiss();
            toast.success("Event updated successfully...");
        } catch (error) {
            toast.dismiss();
            toast.error("Opps something went wrong please try again");
        }
    }

    static getDatesBetween(startDate: Date, endDate: Date) {
        const dates = [];
        let currentDate = new Date(startDate);

        // Iterate through each date from start to end, adding them to the dates array
        while (currentDate <= endDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dates;
    }
    static async getVanueById(id: string) {
        const vanue = collection(db, "vanues");
        const docs = doc(vanue, id);
        const snapshot = await getDoc(docs);
        return snapshot.data() as IVanues;
    }
    static async getEventByVanueIdByDate(id: string, timestamp: number) {
        const events = collection(db, "events");
        const docs = query(events, where("timestamp", ">=", timestamp), where("vanue", "==", id));
        const snapshot = await getDocs(docs);
        const response = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as IEvents[];
        return response;
    }
    static async getEvents(city: string, timestamp: number, filters: any): Promise<IEvents[]> {
        console.log("getEvents", { filters });
        let conditions: any = [];
        conditions.push(where("city", "==", city));
        let newFilter = _pickBy(filters, (i) => i === true);
        Object.keys(newFilter).forEach((i: any) => {
            conditions.push(where(i, "==", true));
        });
        console.log("conditions", conditions);

        const vanues = collection(db, "vanues");
        const vanueQuery = query(vanues, ...conditions, limit(10));
        const vanuesData = await getDocs(vanueQuery);
        let promises: any = [];
        vanuesData.docs.forEach((doc) => {
            promises.push(this.getEventByVanueIdByDate(doc.data().id, timestamp));
        });
        const responseEvents = await Promise.all(promises);
        const eventArray = responseEvents.flat();
        let promises2: any = [];
        eventArray.forEach((event: any) => {
            let artists = event.artistdj;
            const djs = artists.map(async (artist: any) => {
                let dj = await this.getArtistById(artist);
                return dj;
            });
            promises2.push(Promise.all(djs));
        });
        const artists = await Promise.all(promises2);
        const newData = eventArray.map((item) => {
            let vanue = vanuesData.docs.find((doc) => doc.data().id === item.vanue)?.data();
            const djs = item.artistdj.map((artist: any) => {
                return artists.find((i) => {
                    return i.find((artistData: any) => artistData.id === artist);
                });
            });
            const djsData = _.compact(djs.flat());
            return {
                ...item,
                vanueData: vanue,
                artists: djsData,
            };
        });
        return newData as IEvents[];
    }
    static async getEventById(id: string): Promise<IEvents | null> {
        const events = collection(db, "events");
        const docs = doc(events, id);
        const snapshot = await getDoc(docs);
        if (snapshot.data() === undefined) {
            return null;
        }
        //@ts-ignore
        let artists = snapshot.data().artistdj;
        //@ts-ignore
        let vanue = await this.getVanueById(snapshot.data().vanue);
        const djs = artists.map(async (artist: any) => {
            let dj = await this.getArtist(artist);
            return dj;
        });
        const djsData = await Promise.all(djs);

        return { ...snapshot.data(), id: snapshot.id, artists: djsData, vanueData: vanue } as IEvents;
    }

    static async getEventByVanueId(id: string) {
        const events = collection(db, "events");
        const docs = query(events, where("vanue", "==", id));
        const snapshot = await getDocs(docs);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as IEvents[];
    }
    static async getEventArtists(arr: string[]) {
        let artists = [];
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            let artist = await this.getArtist(element);
            artists.push(artist);
        }
        return artists;
    }
    static async getAllUsers() {
        const users = collection(db, "Users");
        const snapshot = await getDocs(users);
        return snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    }
    static async assignRoles(id: string, roles: string[]) {
        const user = doc(db, "Users", id);
        await updateDoc(user, {
            roles: roles,
        });
    }
    static async deleteEventById(id: string) {
        const events = collection(db, "events");
        const docs = doc(events, id);
        await deleteDoc(docs);
    }
    static async deleteVanueById(id: string) {
        const vanues = collection(db, "vanues");
        const docs = doc(vanues, id);
        await deleteDoc(docs);
    }
    static async deleteArtistById(id: string) {
        const artists = collection(db, "artists");
        const docs = doc(artists, id);
        await deleteDoc(docs);
    }
    static async updateArtistById(id: string, data: any) {
        console.log({ id, data });
        let photoURL = data?.photoURL;
        let newData = { ...data };
        try {
            if (data?.file) {
                let response = await this.uploadFile(data?.file, "artists");
                photoURL = response;
            }
            delete newData?.file;
            const artistsCollection = collection(db, "artists");
            const artistDoc = doc(artistsCollection, id);
            await updateDoc(artistDoc, {
                ...newData,
                photoURL: photoURL,
            });
            toast.success("Artist updated successfully");
        } catch (error) {
            console.log(error);
            toast.error("Something went wrong");
        }
    }
    static async updateVenue(id: string, data: any) {
        try {
            toast.loading("Processing");
            const vanueCollection = collection(db, "vanues");
            let newData = { ...data, images: data.images, slugify: slugify(data.displayName, { lower: true }), updatedAt: new Date() };
            //upload image
            if (data.files) {
                let files = [];
                for (let index = 0; index < data.files.length; index++) {
                    let file = data.files[index];
                    let filesPromises = await this.uploadFile(file, "vanues");
                    files.push(filesPromises);
                }
                newData.images = [...data.images, ...files];
            }

            delete newData.files;
            await updateDoc(doc(vanueCollection, id), {
                ...newData,
            });
            toast.dismiss();
            toast.success("Venue updated successfully");
        } catch (error) {
            toast.dismiss();
            toast.error("Opps! something went wrong");
        }
    }
}
