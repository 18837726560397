import { Navigate } from "react-router-dom";
import { useAuth } from "./Authentication";

type ProtectedRouteProps = {
    children: JSX.Element;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
    const { user } = useAuth();
    if (!user) {
        return <Navigate to="/login" />;
    }
    return children;
};
