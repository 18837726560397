import { IoIosArrowForward } from "react-icons/io";
import MobileCard from "./MobileCard";
import { Link } from "react-router-dom";
import { IEvents } from "../../../../lib/firebase/storage";
import moment from "moment";
import { truncate } from "lodash";

export default function EventDetailCard({ events }: { events: IEvents[] }) {
    const city = localStorage.getItem("city");
    return (
        <div className="w-full lg:pb-5 pb-20">
            <div className="w-full flex flex-col gap-6 cursor-pointer">
                <div className="w-full mx-auto px-0">
                    <div className="w-full flex flex-col "></div>
                    {events && events.length > 0 ? (
                        events.map((event: any, index: any) => (
                            <div
                                key={index}
                                className={`group flex-col  flex  gap-4`}>
                                <div className="flex flex-col gap-2">
                                    <p className="text-sm font-bold text-[#8D919B]">{moment(event.date).format("dddd, MMMM Do YYYY")}</p>
                                    <div className="hidden xl:block">
                                        <div
                                            style={{
                                                backgroundImage: `url(${event.images[0]})`,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat",
                                            }}
                                            className="lg:h-[150px] h-auto relative overflow-hidden rounded-lg shadow-eventsDetailPageVip">
                                            <Link
                                                to={`/${city}/events/${event.id}`}
                                                className="flex items-center justify-between">
                                                <div className="flex gap-3 items-center">
                                                    <div className="">
                                                        <img
                                                            src={event.images[0]}
                                                            alt="venue"
                                                            className="rounded-l-lg relative object-cover w-[150px] 2xl:w-[150px] h-[150px]  2xl:h-[150px]"
                                                        />
                                                    </div>
                                                    <div className="flex flex-col gap-2 text-white max-w-[283px]">
                                                        <p className="text-lg font-bold">{event.displayName}</p>
                                                        <p className="text-sm font-light">
                                                            {truncate(event.description, {
                                                                length: 25,
                                                                omission: "...",
                                                            })}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="flex">
                                                    <p className="text-sm font-medium lg:font-bold text-[#8D919B]">{moment(event.date).format("MMM DD YYYY hh:mm a")}</p>
                                                </div>
                                                <div className="flex gap-2 items-center px-4">
                                                    <div className="pl-2 flex gap-1 flex-wrap lg:flex-nowrap items-center min-w-[14.1rem]">
                                                        <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                                            Guest List
                                                        </button>
                                                        <button
                                                            className={`$ w-[56.38px] bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center`}>
                                                            <span>Tickets</span>
                                                        </button>
                                                        <button className="bg-[#B5C3E5] rounded text-sm text-center leading-tight text-black py-1 px-1.5 flex justify-center items-center">
                                                            <span>VIP Tables</span>
                                                        </button>
                                                    </div>
                                                    <p className="hidden lg:flex text-sm text-white">
                                                        <IoIosArrowForward className="w-[24px] h-[24px]" />
                                                    </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="xl:hidden">
                                        <MobileCard event={event} />
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-white text-2xl text-center">Upcoming Events Not Found</p>
                    )}
                </div>
            </div>
        </div>
    );
}
